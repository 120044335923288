import * as R from 'ramda';

export const anonIban = R.compose(
  R.join(' '),
  R.splitEvery(4),
  (str) => {
    const strLength = str.length;

    if (str < 8) { return str; }

    const prefixe = str.substr(0, 8);
    const suffixe = str.substr(strLength - 3, strLength);

    return R.compose(
      R.join(''),
      R.flatten
    )([
      prefixe,
      R.repeat('*', strLength - prefixe.length - suffixe.length),
      suffixe
    ]);
  }
);
