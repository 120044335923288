import React from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { ErrorMessage } from '@@components';
import ForgotPasswordTunnelMutation from '@@graphql/mutations/ForgotPasswordTunnelMutation';
import { useRelayEnvironment } from 'react-relay';
import BackButton from '@@components/BackButton';

const R = require('ramda');
const RA = require('ramda-adjunct');

const Step2 = ({
  id,
  onDone,
  subscriberId,
  setData,
  setForgotPasswordTunnel,
  handlePreviousStep,
  setStepIfForgotPassword
}) => {
  const environment = useRelayEnvironment();
  const [errorMessage, setErrorMessage] = React.useState(null);

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('oldAdherentPassword');

  React.useEffect(() => {
    if (fetching.isDone()) {
      onDone({
        id,
        payload: fetching.getPayload()
      });
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  React.useEffect(() => {
    setErrorMessage(fetching.getError());
  }, [fetching.getError()]);

  const onForgotPasswordSubmit = () => {
    setForgotPasswordTunnel(true);
    ForgotPasswordTunnelMutation(environment, { subscriberId, forgotPassword: true }, (ok, error, subscriberId, forgotPassword, email) => {
      if (ok) {
        setData(R.over(R.lensProp('email'), R.always(email)));
        setData(R.over(R.lensProp('forgotPassword'), R.always(forgotPassword)));
        setStepIfForgotPassword();
      } else {
        setErrorMessage('Une erreur est survenue');
      }
    });
  };

  const formsBlock = (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{ subscriberId }}
      onChange={() => fetching.reset()}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <>
          <div className="f-connect-bottom-buttons">
            <BackButton onClick={handlePreviousStep}/>
            <button
              className="f-button f-button-coral"
              disabled={fetching.isFetching()}
              type="submit"
            >
              {'Valider'}
            </button>
            <button
              className="f-button f-button-coral-reverse"
              type="button"
              onClick={onForgotPasswordSubmit}
            >
              {'Passer cette étape'}
            </button>
          </div>
          {RA.isNotNil(errorMessage) && <ErrorMessage value={errorMessage} />}
        </>
      )}
    />
  );

  return (
    <div className='f-connect-container'>
      <div className='f-connect-forms-block'>
        <div className='f-connect-title'>
          {'Mot de passe'}
        </div>
        <div className='f-connect-intro'>
          {'Saisissez votre mot de passe de l\'ancien espace adhérent APPA.'}
        </div>
        { formsBlock }
      </div>
    </div>
  );
};

Step2.propTypes = {
  id: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired,
  subscriberId: PropTypes.string,
  setData: PropTypes.func.isRequired,
  setForgotPasswordTunnel: PropTypes.func.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  setStepIfForgotPassword: PropTypes.func.isRequired
};

export default Step2;
