/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ForgotAdherentNumberInput = {|
  birthDate: string,
  email: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
|};
export type ForgotAdherentNumberMutationVariables = {|
  input: ForgotAdherentNumberInput
|};
export type ForgotAdherentNumberMutationResponse = {|
  +forgotAdherentNumber: ?{|
    +ok: boolean,
    +error: ?string,
    +anonEmail: ?string,
    +phoneNumberErrors: ?$ReadOnlyArray<?string>,
  |}
|};
export type ForgotAdherentNumberMutation = {|
  variables: ForgotAdherentNumberMutationVariables,
  response: ForgotAdherentNumberMutationResponse,
|};
*/


/*
mutation ForgotAdherentNumberMutation(
  $input: ForgotAdherentNumberInput!
) {
  forgotAdherentNumber(input: $input) {
    ok
    error
    anonEmail
    phoneNumberErrors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ForgotAdherentNumberMutationResponse",
    "kind": "LinkedField",
    "name": "forgotAdherentNumber",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "anonEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumberErrors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotAdherentNumberMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotAdherentNumberMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7980314fbad920c7bca1295415950ba2",
    "id": null,
    "metadata": {},
    "name": "ForgotAdherentNumberMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotAdherentNumberMutation(\n  $input: ForgotAdherentNumberInput!\n) {\n  forgotAdherentNumber(input: $input) {\n    ok\n    error\n    anonEmail\n    phoneNumberErrors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b1e96a0950f8bcf4c4d1b13300a51634';

module.exports = node;
