import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ItemBlock = ({
  children,
  icon,
  onEdit,
  isUpcoming
}) => {
  return (
    <div onClick={onEdit} className={classNames('f-item-block', {
      'is-upcoming': isUpcoming
    })}>
      <div className={'f-item-block-img'}>
        <img src={icon} alt={'item block logo'}/>
      </div>
      {children}
    </div>
  );
};

ItemBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  isUpcoming: PropTypes.bool.isRequired
};

ItemBlock.defaultProps = {
  icon: '',
  onEdit: () => {},
  isUpcoming: false
};

export default ItemBlock;
