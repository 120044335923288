import React from 'react';
import Home from './Home';
import { Redirect, Route, Switch } from 'react-router-dom';

const Index = ({ match }) => {
  const path = v => `${match.url}${v}`;
  return (
    <Switch>
      <Route exact path={path('')} render={() => <Home />}/>
      <Redirect to={path('')}/>
    </Switch>
  );
};

export default Index;
