import React from 'react';
import { QAdherentFirstConnectionStatusQuery } from '@@queries/QAdherentFirstConnectionStatus';

const R = require('ramda');

const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useAdherentStatus = (subscriberId) => {
  const environment = useRelayEnvironment();
  const [adherentStatus, setAdherentStatus] = React.useState();
  const subscriberIdRef = React.useRef();

  React.useEffect(() => {
    if (R.equals(subscriberIdRef.current, subscriberId)) return;
    subscriberIdRef.current = subscriberId;
    getFirstConnectionStatus(subscriberId);
  }, [subscriberId]);

  const getFirstConnectionStatus = async (subscriberId) => {
    const response = await fetchQuery(environment, QAdherentFirstConnectionStatusQuery, { subscriberId }).toPromise();
    setAdherentStatus(R.propOr('', 'getAdherentFirstConnectionStatus', response));
  };

  return {
    adherentStatus
  };
};

export default useAdherentStatus;
