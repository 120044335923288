import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Calls onRouteChanged() when the current route changes
 */
export const useRouteChanged = (onRouteChanged) => {
  const history = useHistory();

  useEffect(() => {
    // To start listening for location changes...
    const unlisten = history.listen(() => {
      onRouteChanged();
    });

    return () => {
      // Later, when you are done listening for changes...
      unlisten();
    };
  }, [onRouteChanged]);
};
