export const mobileBgWhite = '/images/illu-mobile-bg-white.svg';
export const adherentLogo = '/images/illu-adherent.svg';
export const mobileBgTransp = '/images/illu-mobile-bg-transp.svg';
export const messagingLogo = '/images/illu-messagerie.svg';
export const confirmMessagingLogo = '/images/illu-confirm-message.svg';
export const logoAppa = '/images/logo-appa.svg';
export const logoBesse = '/images/logo-besse.svg';
export const powerLogo = '/icons/power.svg';
export const iconEdit = '/icons/edit.svg';
export const homeBg = '/images/fond-accueil.svg';
export const homeBackgroundMobile = '/images/fond-accueil-mobile.svg';
export const contactLogo = '/images/contact.svg';
export const welcomeLogo = '/images/bienvenue.svg';
export const messageLogo = '/images/illu-messagerie.svg';
export const documentLogo = '/images/card.webp';
export const cotisationsLogo = '/images/cotisations.svg';
export const staffofAsclepiusLogo = '/images/pictos-26.png';
export const servicesBackgroundDesktop = '/images/fond-services.svg';
export const servicesBackgroundMobile = '/images/fond-services-mobile.svg';
export const mobileBgTranspMed = '/images/illu-mobile@2x.webp';
export const firstConnectionBg = '/images/fond-tunnel.png';
export const paperclipIcon = '/icons/icon-paperclip.svg';
export const binIcon = '/icon/bin.svg';
export const documentCardLogo = '/images/pictos-19.png';
export const editIcon = '/icons/edit.svg';
export const logoMessaging = '/images/messaging.svg';
export const contractsLogo = '/images/contractsLogo.svg';
export const documentsLogo = '/images/documentsLogo.svg';
export const accountLogo = './images/vosinformationspersonnelles.svg';
export const cguLogo = './images/cguLogo.svg';
export const cotisationHospiLogo = '/images/cotisation-hospi.png';
export const cotisationRevenusAnnexesLogo = '/images/cotisation-renvenu-annexe.png';
export const cotisationLiberalLogo = '/images/cotisation-liberal.png';
export const cotisationRetraiteLogo = '/images/cotisation-retraite.png';
export const downloadIcon = '/icons/downloadIcon.svg';
export const showIcon = '/icons/icon-show.svg';
export const eyeIconCoral = '/icons/icon-eye-coral.svg';
export const europAssistLogo = '/images/europ-assist.png';
export const openDropdownIcon = '/icons/menu.svg';
export const closeDropdownIcon = '/icons/bt-fermer.svg';
export const cornerUpLeftYellow = '/images/corner-up-left-yellow.svg';
export const cornerDownRightTurquoise = '/images/corner-down-right-turquoise.svg';
export const logoMercer = '/images/logo-mercer.svg';
export const logoSanteClair = 'images/logo-sante-clair.svg';
export const logoEurop = '/images/logo-europ.svg';
export const bigCornerTurquoise = '/images/big-corner-turquoise.svg';
export const bigCornerYellow = '/images/big-corner-yellow.svg';
export const imageCotisationsCorner = '/images/cotisations-turquoise-corner.svg';
export const house = '/images/house.svg';
export const miniDocumentsLogo = '/images/miniDocumentsLogo.svg';
export const imageTiersPayant = '/images/image-tiers-payant.png';
export const iconClose = '/icons/cross.svg';
export const iconCheck = '/icons/check.svg';
export const iconCalendar = '/icons/calendar-2.svg';
export const iconPdf = '/icons/pdf.svg';
export const iconLightBulb = '/icons/light-bulb.svg';
