import React from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { ErrorMessage } from '@@components';
import BackButton from '@@components/BackButton';
import OtpNotReceiveModal from './OtpNotReceiveModal';

const RA = require('ramda-adjunct');

const Step2bis = ({
  id,
  onDone,
  subscriberId,
  email,
  handlePreviousStep
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('forgotOldPassword');

  React.useEffect(() => {
    if (fetching.isDone()) {
      onDone({
        id,
        payload: fetching.getPayload()
      });
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  React.useEffect(() => {
    setErrorMessage(fetching.getError());
  }, [fetching.getError()]);

  const formsBlock = (
    <Forms
      formsData={formsData}
      columns={1}
      onChange={() => fetching.reset()}
      onSubmit={onFormsSubmit}
      defaultValues={{
        subscriberId,
        email
      }}
      submitSection={() => (
        <>
          <div className="f-connect-bottom-buttons">
            <BackButton onClick={handlePreviousStep}/>
            {
              RA.isNotNil(email) && <button
                className="f-button f-button-coral"
                disabled={fetching.isFetching()}
                type="submit"
                hide="true"
              >
                {'Continuer'}
              </button>
            }
          </div>
          {RA.isNotNil(errorMessage) && <ErrorMessage value={errorMessage} />}
        </>
      )}
    />
  );

  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => setOpenModal(true);

  return (
    <div className='f-connect-container'>
      <div className='f-connect-forms-block'>
        <div className='f-connect-title'>
          {'Validez votre email'}
        </div>
        <div className='f-connect-intro'>
          {
            RA.isNotNil(email) ? `Nous allons vous envoyer un code d'authentification par email
            à l'adresse ${email}.`
              : <div>
                {'Nous n\'avons pas d\'adresse email de renseignée vous concernant.'} <br/>
                <button
                  className="text-center f-link-turquoise"
                  onClick={handleOpenModal}
                  type="button"
                >
                  {'Cliquez sur le formulaire'}
                </button>
                {' pour être recontacté(e) par l\'un de nos conseillers.'}
              </div>
          }
        </div>
        { formsBlock }
      </div>
      <OtpNotReceiveModal
        title={'Je n\'ai pas d\'adresse email de renseignée'}
        show={openModal}
        onDisable={handleCloseModal}
        formName={'sendRequestIfHaveNoEmail'}
        onDone={onDone}
      />
    </div>
  );
};

Step2bis.propTypes = {
  id: PropTypes.string,
  onDone: PropTypes.func,
  subscriberId: PropTypes.string,
  email: PropTypes.string,
  handlePreviousStep: PropTypes.func.isRequired
};

export default Step2bis;
