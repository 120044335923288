import React from 'react';
import PropTypes from 'prop-types';
import { messagingLogo } from '@@images';
import Modal from '@@components/modale';

const MessagingRedirectionModal = ({
  show,
  setDisabled,
  onClick,
  title,
  text
}) => {
  return (
    <Modal show={show} onDisable={setDisabled} title={title}
      className={'f-modal-window-messaging-redirect'}
    >
      <div className='f-messaging-modal-container'>
        <img className='f-messaging-modal-img' src={messagingLogo} alt='img'/>
        <div className='f-messaging-modal-text'>
          {text}
        </div>
        <div className='f-messaging-modal-buttons'>
          <button
            className='f-button f-button-grey f-edit-family-status-btn'
            onClick={setDisabled}
          >
            {'Annuler'}
          </button>
          <button
            className='f-button f-button-coral f-edit-family-status-btn'
            onClick={onClick}
          >
            {'Accéder à la messagerie'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

MessagingRedirectionModal.propTypes = {
  setDisabled: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default MessagingRedirectionModal;
