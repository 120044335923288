import { graphql } from 'react-relay';

const QGenericDocumentsQuery = graphql`
  query QGenericDocumentsQuery($id: ID!) {
    adherent(id: $id) {
      id
      genericDocuments {
        _id
        fileId
        fileSource
        subscriberId
        title
        author
        type
        subType
        description
        status
        createdAt
        updatedAt
        fileName
      }
    }
  }
`;

export {
  QGenericDocumentsQuery
};
