import { keycloakSessionSignIn, keycloakSessionSignOut } from '@@root/reduxActions/keycloakSession';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { isNotNilOrEmpty } from 'ramda-adjunct';

const R = require('ramda');

const buildUserSession = (user, token) => {
  // Retrieve the email from the token if not empty and if the email is verified
  const decodedToken = R.when(isNotNilOrEmpty, jwtDecode)(token);
  const tokenIsNotEmptyAndEmailIsVerified = R.allPass([
    isNotNilOrEmpty,
    R.propEq('email_verified', true)
  ]);
  const email = R.ifElse(
    tokenIsNotEmptyAndEmailIsVerified,
    R.propOr('', 'email'),
    R.always('')
  )(decodedToken);

  return R.applySpec({
    id: 'user',
    email: R.always(email),
    username: R.propOr('', 'username'),
    firstname: R.propOr('', 'firstName'),
    lastname: R.propOr('', 'lastName'),
    customerId: R.propOr('', 'username'),
    token: R.always(token)
  })(user);
};

const useKeycloakSession = () => {
  const dispatch = useDispatch();
  const { keycloak, user } = useSelector(R.prop('keycloakSession'));

  const onSignIn = () => {
    dispatch(keycloakSessionSignIn());
  };

  const onSignOut = () => {
    dispatch(keycloakSessionSignOut());
  };

  return {
    authenticated: R.propOr(false, 'authenticated', keycloak),
    user: buildUserSession(user),
    onSignIn,
    onSignOut
  };
};

export default useKeycloakSession;

export {
  buildUserSession
};
