/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGenericDocumentsQueryVariables = {|
  id: string
|};
export type QGenericDocumentsQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +genericDocuments: ?$ReadOnlyArray<?{|
      +_id: ?string,
      +fileId: string,
      +fileSource: ?string,
      +subscriberId: string,
      +title: ?string,
      +author: ?string,
      +type: ?string,
      +subType: ?string,
      +description: ?string,
      +status: ?string,
      +createdAt: ?string,
      +updatedAt: ?string,
      +fileName: ?string,
    |}>,
  |}
|};
export type QGenericDocumentsQuery = {|
  variables: QGenericDocumentsQueryVariables,
  response: QGenericDocumentsQueryResponse,
|};
*/


/*
query QGenericDocumentsQuery(
  $id: ID!
) {
  adherent(id: $id) {
    id
    genericDocuments {
      _id
      fileId
      fileSource
      subscriberId
      title
      author
      type
      subType
      description
      status
      createdAt
      updatedAt
      fileName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Adherent",
    "kind": "LinkedField",
    "name": "adherent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "genericDocuments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileSource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriberId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "author",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QGenericDocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QGenericDocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f36dad0b2eb7471391cde3eb8724001c",
    "id": null,
    "metadata": {},
    "name": "QGenericDocumentsQuery",
    "operationKind": "query",
    "text": "query QGenericDocumentsQuery(\n  $id: ID!\n) {\n  adherent(id: $id) {\n    id\n    genericDocuments {\n      _id\n      fileId\n      fileSource\n      subscriberId\n      title\n      author\n      type\n      subType\n      description\n      status\n      createdAt\n      updatedAt\n      fileName\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '741152da4e0916065cf00aba6a420b22';

module.exports = node;
