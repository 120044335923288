import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

// In Markdown we need two spaces to break to a new line
export const formatTextProp = R.compose(
  R.replace('\n', '  \n'), // actual new line
  R.replace(/[\n,\s]+$/, '') // remove trailing spaces and br
);

// Extract the title and description from a pension (not health) guarantee
export const getPensionTitleAndDescription = guarantee => {
  const propLabel = R.propOr('', 'label')(guarantee);
  const splitIndex = R.indexOf('\n', propLabel);
  if (R.gt(splitIndex, -1)) {
    const title = R.slice(0, splitIndex, propLabel);
    const description = R.slice(splitIndex + 1, Infinity, propLabel);
    const formattedDescription = RA.isNilOrEmpty(description) ? ''
      : `  \n${formatTextProp(description)}`;
    return ({
      title: title,
      description: formattedDescription
    });
  } else {
    return ({
      title: propLabel,
      description: ''
    });
  }
};

// Extract the title from a health (not pension) guarantee
export const getHealthTitle = guarantee => {
  const propLabel = R.propOr('', 'label')(guarantee);
  return formatTextProp(propLabel);
};

export const getValue = guarantee => {
  const propValue = R.propOr('', 'value')(guarantee);
  return RA.isNilOrEmpty(propValue) ? '' : ` : ${propValue}`;
};
