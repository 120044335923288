import { format, parseISO } from 'date-fns';
import * as R from 'ramda';

const INFINITE_DATE = parseISO('2099-12-31');

export const dateToHumanFull = (date, separator = '') => {
  const fullDate = format(parseISO(date), 'dd/MM/yyyy');
  const hours = format(parseISO(date), 'HH:mm');
  return R.join(` ${separator} `, [fullDate, hours]);
};

export const dateToHuman = (date) => format(parseISO(date), 'dd/MM/yyyy');

// returns the formatted date as dd/mm/yyyy, or null when >= 12/31/2099
export const limitedDateToHuman = (strDate) => {
  const objDate = parseISO(strDate);
  if (R.lt(objDate, INFINITE_DATE)) {
    return dateToHuman(strDate);
  } else {
    return null;
  }
};
