import { graphql } from 'react-relay';

export const QDataSpecialtiesQuery = graphql`
  query QDataSpecialtiesQuery {
    data {
      specialties {
        id
        name
      }
    }
  }
`;
