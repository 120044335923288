/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FillDocumentMutationVariables = {|
  subscriberId: string
|};
export type FillDocumentMutationResponse = {|
  +fillTemplate: ?{|
    +ok: boolean,
    +error: ?string,
    +documentData: ?{|
      +_id: ?string,
      +fileId: string,
      +fileSource: ?string,
      +fileName: ?string,
      +subscriberId: string,
      +title: ?string,
      +type: ?string,
      +subType: ?string,
      +description: ?string,
      +author: ?string,
      +status: ?string,
    |},
  |}
|};
export type FillDocumentMutation = {|
  variables: FillDocumentMutationVariables,
  response: FillDocumentMutationResponse,
|};
*/


/*
mutation FillDocumentMutation(
  $subscriberId: String!
) {
  fillTemplate(subscriberId: $subscriberId) {
    ok
    error
    documentData {
      _id
      fileId
      fileSource
      fileName
      subscriberId
      title
      type
      subType
      description
      author
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriberId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "subscriberId",
        "variableName": "subscriberId"
      }
    ],
    "concreteType": "FillTemplateMutationResponse",
    "kind": "LinkedField",
    "name": "fillTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "documentData",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileSource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriberId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "author",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FillDocumentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FillDocumentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fe6a307fd9951c5bf820ee5a49217434",
    "id": null,
    "metadata": {},
    "name": "FillDocumentMutation",
    "operationKind": "mutation",
    "text": "mutation FillDocumentMutation(\n  $subscriberId: String!\n) {\n  fillTemplate(subscriberId: $subscriberId) {\n    ok\n    error\n    documentData {\n      _id\n      fileId\n      fileSource\n      fileName\n      subscriberId\n      title\n      type\n      subType\n      description\n      author\n      status\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54c0fcc17e8eb2681deefb0dcc628978';

module.exports = node;
