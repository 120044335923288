import React from 'react';

const RangeInput = ({ options, name, onChange, value }) => {
  return (
    <label>
      <input min="0" max="100" className="w-full" type="range" name={name} onChange={(e) => onChange(e.target.value)}
        value={value} />
      <ul className="flex justify-between">
        <li>0%</li>
        <li>50%</li>
        <li>100%</li>
      </ul>
    </label>
  );
};

export default RangeInput;
