import React from 'react';
import PropTypes from 'prop-types';
import { logoMercer } from '@@images';

const TEXT = 'Pour effectuer votre demande, vous pouvez être redirigé' +
  '\nvers votre espace santé en utilisant le lien ci-dessous. Vous' +
  '\npouvez également contacter les équipes Mercer au';

const MercerInfo = ({ mercerPhoneNumber, href }) => (
  <>
    <img className="f-messaging-ext-services-modal-img-mercer" src={logoMercer} alt="img"/>
    <div className={'f-messaging-ext-services-modal-text'}>
      {TEXT}
    </div>
    <div className={'f-messaging-ext-services-modal-text'}>
      <div className={'f-messaging-ext-services-modal-phone-number'}>
        {mercerPhoneNumber}
      </div>
      <div className={'f-contact-block-caption'}>
        {'(appel non surtaxé)'}
      </div>
    </div>
    <div className='f-messaging-modal-buttons'>
      <a className={'f-button f-button-coral f-edit-family-status-btn'} href={href}
        target={'_blank'} rel={'noreferrer'}>
        {'Accéder à mon espace santé'}
      </a>
    </div>
  </>
);

MercerInfo.propTypes = {
  mercerPhoneNumber: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

export default MercerInfo;
