/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'code',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Code reçu par SMS",
    placeholder: "ex : 123456",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'otpId',
    required: true,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'subscriberId',
    required: true,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'phoneNumbers',
    required: false,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;