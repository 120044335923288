/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MaritalStatusEnum = "celibataire" | "concubin" | "divorce" | "marie" | "pacse" | "veuf" | "%future added value";
export type AdherentFamilyStatusInput = {|
  maritalStatus: MaritalStatusEnum,
  subscriberId: string,
  swornStatement: boolean,
|};
export type AdherentFamilyStatusMutationVariables = {|
  input: AdherentFamilyStatusInput
|};
export type AdherentFamilyStatusMutationResponse = {|
  +adherentFamilyStatus: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AdherentFamilyStatusMutation = {|
  variables: AdherentFamilyStatusMutationVariables,
  response: AdherentFamilyStatusMutationResponse,
|};
*/


/*
mutation AdherentFamilyStatusMutation(
  $input: AdherentFamilyStatusInput!
) {
  adherentFamilyStatus(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdherentFamilyStatusMutationResponse",
    "kind": "LinkedField",
    "name": "adherentFamilyStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdherentFamilyStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdherentFamilyStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "71a2fe73a1300f078c44a3e8afa736ea",
    "id": null,
    "metadata": {},
    "name": "AdherentFamilyStatusMutation",
    "operationKind": "mutation",
    "text": "mutation AdherentFamilyStatusMutation(\n  $input: AdherentFamilyStatusInput!\n) {\n  adherentFamilyStatus(input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e0916d899bcc2e650a4fd3ea27f96314';

module.exports = node;
