import React from 'react';
import PropTypes from 'prop-types';

const Page = ({
  title, // TODO probable redundancy with pageTitle ?
  children,
  pageTitle
}) => {
  // TODO - Looks like this can never happen, all Pages have children.
  if (React.Children.count(children) === 0) {
    return (
      <div className={'f-page-placeholder'}>
        {title}
      </div>
    );
  }

  const mainPageTitle = (
    <div className={'f-page-main-title'}>
      {pageTitle}
    </div>
  );

  return (
    <div className='f-base-page-container f-page-with-banner-bg'>
      <div className={'f-page'}>
        <div className={'f-page-body'}>
          { mainPageTitle }
          { children }
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string.isRequired
};

Page.defaultProps = {
  pageTitle: ''
};

export default Page;
