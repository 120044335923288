import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Modal from '@@components/modale';
import * as R from "ramda";
import {STATIC_DOC_TYPE_FICHE_CONDITIONS_RESILIATION} from '@@pages/documents/utils';
import {QCmsStaticDocumentsQuery} from "../../../_graphql/queries/QCmsStaticDocuments";
import {useFetchQuery} from "../../../hooks/useDocument";
import {getGenericDocumentUrl} from "../../../lib/contracts";
import {isNotNilOrEmpty} from "ramda-adjunct";

const RequestTerminationConfirmModal = ({
  show,
  setDisabled,
  setConfirm,
  terminationInfos
}) => {
    const [conditionsUrl, setConditionsUrl] = useState('');
    const {
        isLoaded: isLoadedListDocuments,
        data: dataListDocuments
    } = useFetchQuery(QCmsStaticDocumentsQuery,
        { documentsFilter: { type: STATIC_DOC_TYPE_FICHE_CONDITIONS_RESILIATION } });
    useEffect(() => {
        if (isLoadedListDocuments) {
            const doc = R.compose(
                R.head,
                R.pathOr('', ['cms', 'staticDocuments'])
            )(dataListDocuments);
            const url = getGenericDocumentUrl({ document: doc, download: false });
            console.log(url);
            setConditionsUrl(url);
        }
    }, [isLoadedListDocuments, dataListDocuments]);

  return (
    <Modal show={show} onDisable={setDisabled} title={'Récapitulatif de votre résiliation'}
      className={'f-window-messaging-confirm'}
    >
      <div className={'f-messaging-confirm-modal-container'}>

        <div className={'f-messaging-modal-text'}>
            {isNotNilOrEmpty(terminationInfos) && (<ul className={'f-messaging-modal-list'}>
                <li><span className={'f-messaging-modal-list-item-label'}>N° d'adhérent(e) : </span>{terminationInfos.customerId}</li>
                <li><span className={'f-messaging-modal-list-item-label'}>Prénom de l'assuré(e) : </span>{terminationInfos.adherent.civilStatus.firstName}</li>
                <li><span className={'f-messaging-modal-list-item-label'}>Nom de l'assuré(e) : </span>{terminationInfos.adherent.civilStatus.lastName}</li>
                <li><span className={'f-messaging-modal-list-item-label'}>Contrat n° : </span>{terminationInfos.contractId}</li>
                <li><span className={'f-messaging-modal-list-item-label'}>Motif de résiliation : </span>{terminationInfos.reason}</li>
                <li><span className={'f-messaging-modal-list-item-label'}>Date souhaitée : </span>{terminationInfos.date}</li>
            </ul>)}
            <p className={'f-messaging-modal-text-conditions'}>Je consulte les conditions de résiliation des contrats en cliquant sur
                <a href={conditionsUrl} target="_blank" rel="noreferrer" className={'f-messaging-modal-text-conditions-link'}> ce lien</a></p>
        </div>
        <div className={'f-messaging-modal-buttons'}>
          <button
            className={'f-button f-button-coral f-edit-family-status-btn'}
            onClick={setDisabled}
          >
            {'Modifier ma résiliation'}
          </button>
          <button
            className={'f-button f-button-coral f-edit-family-status-btn'}
            onClick={setConfirm}
          >
            {'Confirmer la résiliation'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

RequestTerminationConfirmModal.propTypes = {
  show: PropTypes.bool,
  setDisabled: PropTypes.func,
  setConfirm: PropTypes.func,
  terminationInfos: PropTypes.object
};

export default RequestTerminationConfirmModal;
