import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QCmsPageQuery = graphql`
  query QCmsPageQuery($pageId: ID) {
    cms {
      page(pageId: $pageId) {
        title
        content
      }
    }
  }
`;

export {
  QCmsPageQuery
};
