import React from 'react';
import PropTypes from 'prop-types';
import { map, propOr } from 'ramda';
import { sendGAEventIfDefined } from '@@lib/ga';

// eslint-disable-next-line react/display-name
const phoneNumberToJSX = contact => ({ num, caption, ga }) => {
  const onClickPhoneNumber = () => sendGAEventIfDefined(ga);
  return (
    <div key={num}>
      <div className={'f-contact-block-phone-number'} onClick={onClickPhoneNumber}>
        {propOr('', num, contact)}
      </div>
      {caption && <div className={'f-contact-block-caption'}>{caption}</div>}
    </div>
  );
};

const ContactBlock = ({ title, img, phoneNumbers, cmsContacts }) => (
  <div className="f-contact-block">
    <div className="f-contact-block-title">{title}</div>
    <div className="f-contact-block-img">
      <img src={img} alt="contact-logo"/>
    </div>
    <div>
      {map(phoneNumberToJSX(cmsContacts), phoneNumbers)}
    </div>
  </div>
);

ContactBlock.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
    num: PropTypes.string.isRequired,
    caption: PropTypes.string
  })).isRequired
};

export default ContactBlock;
