import { graphql } from 'react-relay';

const QGetMessageNotifiedQuery = graphql`
  query QGetMessageNotifiedQuery {
    getMessageNotified {
        _id
        discussionId
        messageId
        title
        createdAt
        type
        text
    }
  }
`;

export { QGetMessageNotifiedQuery };
