import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
  mutation FillDocumentMutation($subscriberId: String!) {
    fillTemplate(subscriberId: $subscriberId) {
      ok
      error
      documentData {
        _id
        fileId
        fileSource
        fileName
        subscriberId
        title
        type
        subType
        description
        author
        status
      }
    }
  }
`;

export default async (environment, { subscriberId }, done) => {
  const variables = {
    subscriberId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ fillTemplate }) => {
        const { ok, error, documentData } = fillTemplate;
        done(ok, error, documentData);
      },
      onError: err => console.error(err)
    }
  );
};
