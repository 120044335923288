import React from 'react';
import DatePicker from '../../input/DatePicker';
import { Controller } from 'react-hook-form';
import ErrorMessage from './helpers/ErrorMessage';
import { format, parse } from 'date-fns';
const R = require('ramda');

const Input = ({ control, register, field, errors }) => {
  return (
    <Controller
      control={control}
      name={field.name}
      rules={{ required: field.required }}
      render={({ onChange, value }) => {
        const $value = parse(value, 'yyyyy-mm-dd', new Date());
        const $onChange = (v) => {
          onChange(format(v, 'yyyy-mm-dd'));
        };
        return (
          <>
            <DatePicker
              value={$value}
              format={R.when(R.isNil, R.always('dd/MM/yyyy'), field.format)}
              onChange={$onChange}
              hasError={R.keys(errors).includes(field.name)}
            />
          </>
        );
      }}
    />
  );
};

export default Input;
