import React from 'react';
import { useCms } from '@@hooks';
import { Page } from '@@components';
import ReactMarkdown from 'react-markdown';

const R = require('ramda');

const MentionsLegales = () => {
  const { page } = useCms(['page'], { pageId: 'mentions_legales' });

  if (R.isNil(page)) return null;

  return (
    <Page pageTitle="Mentions Légales">
      <div className={'f-card w-full'}>
        <ReactMarkdown className={'f-react-markdown'}>{page.content}</ReactMarkdown>
      </div>
    </Page>
  );
};

export default MentionsLegales;
