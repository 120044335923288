import React from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { ErrorMessage } from '@@components';
import BackButton from '@@components/BackButton';

const RA = require('ramda-adjunct');

const Step3 = ({
  id,
  onDone,
  handlePreviousStep,
  subscriberId,
  email
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('emailToVerify');

  React.useEffect(() => {
    if (fetching.isDone()) {
      onDone({
        id,
        payload: fetching.getPayload()
      });
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  React.useEffect(() => {
    setErrorMessage(fetching.getError());
  }, [fetching.getError()]);

  const formsBlock = (
    <Forms
      formsData={formsData}
      columns={1}
      onChange={() => fetching.reset()}
      onSubmit={onFormsSubmit}
      defaultValues={{
        subscriberId,
        email
      }}
      submitSection={() => (
        <>
          <div className="f-connect-bottom-buttons">
            <BackButton onClick={handlePreviousStep}/>
            <button
              className="f-button f-button-coral"
              disabled={fetching.isFetching()}
              type="submit"
            >
              {'Continuer'}
            </button>
          </div>
          {RA.isNotNil(errorMessage) && <ErrorMessage value={errorMessage} />}
        </>
      )}
    />
  );

  return (
    <div className='f-connect-container'>
      <div className='f-connect-forms-block'>
        <div className='f-connect-title'>
          {'Validez votre email'}
        </div>
        <div className='f-connect-intro'>
          {'Entrez l’adresse mail sur laquelle vous souhaitez recevoir le' +
          ' code de sécurité.'}
        </div>
        { formsBlock }
      </div>
    </div>
  );
};

Step3.propTypes = {
  id: PropTypes.string,
  onDone: PropTypes.func,
  subscriberId: PropTypes.string,
  handlePreviousStep: PropTypes.func.isRequired,
  email: PropTypes.string
};

export default Step3;
