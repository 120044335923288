import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Block } from '@@components';
import { iconEdit } from '@@images';

const DoubleBlock = ({
  child1,
  child2,
  onEdit,
  title1,
  title2,
  fitToGrid
}) => {
  const isNilChild2 = !React.isValidElement(child2);
  const blockChild1 = (
    <Block title={title1} displayShadow={isNilChild2}>
      {child1}
    </Block>
  );
  const blockChild2 = !isNilChild2 && (
    <Block title={title2} displayShadow={false}>
      {child2}
    </Block>
  );

  const editButton = (
    <div className={'f-block-edit-btn f-double-block-edit-btn'} onClick={onEdit}>
      <img src={iconEdit} alt={'Edit icon'}/>
    </div>
  );

  return isNilChild2
    ? (
      <div className={classNames({
        'f-double-block-partial': fitToGrid,
        'f-double-block-no-grid-partial': !fitToGrid
      })}>
        <div className={classNames({
          'f-double-block-partial-content': fitToGrid,
          'f-double-block-no-grid-partial-content': !fitToGrid
        })}>
          { editButton }
          { blockChild1 }
        </div>
      </div>
    )
    : (
      <div className={classNames({
        'f-double-block-complete': fitToGrid,
        'f-double-block-no-grid-complete': !fitToGrid
      })}>
        <div className={classNames({
          'f-card-no-p': true,
          'f-double-block-complete-content': fitToGrid,
          'f-double-block-no-grid-complete-content': !fitToGrid
        })}>
          { editButton }
          { blockChild1 }
          { blockChild2 }
        </div>
      </div>
    );
};

DoubleBlock.propTypes = {
  child1: PropTypes.node.isRequired,
  child2: PropTypes.node,
  onEdit: PropTypes.func.isRequired,
  title1: PropTypes.string,
  title2: PropTypes.string,
  fitToGrid: PropTypes.bool.isRequired
};

export default DoubleBlock;
