import React from 'react';
import PropTypes from 'prop-types';

const InformationBlock = ({
  title,
  img,
  children,
  className
}) => (
  <div className={`f-help-block ${className}`}>
    {img && (
      <div className='f-help-block-image'>
        <img src={img} alt=""/>
      </div>
    )}
    <div>
      <div className='f-help-block-title'>{title}</div>
      <div className='f-help-block-text'>{children}</div>
    </div>
  </div>

);

InformationBlock.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

InformationBlock.defaultProps = {
  className: ''
};

export default InformationBlock;
