import {
  commitMutation,
  graphql
} from 'react-relay';

const mutation = graphql`
    mutation UpdateAdherentFirstConnectionStatusMutation($status: Boolean!, $subscriberId: String!) {
      updateFirstConnectionStatus(status: $status, subscriberId: $subscriberId) {
            ok
            error
        }
    }
`;

export default async (environment, { status, subscriberId }, done) => {
  const variables = {
    status,
    subscriberId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ updateFirstConnectionStatus }) => {
        const { ok, error } = updateFirstConnectionStatus;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
