/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SendDiscussionInput = {|
  actionId?: ?string,
  attachments?: ?$ReadOnlyArray<?AttachmentInput>,
  contract: ContractInput,
  discussionId?: ?string,
  text: string,
|};
export type AttachmentInput = {|
  documentType?: ?string,
  fileName: string,
  uploadId: string,
|};
export type ContractInput = {|
  categoryId: string,
  contractId: string,
|};
export type SendDiscussionMutationVariables = {|
  input: SendDiscussionInput
|};
export type SendDiscussionMutationResponse = {|
  +sendDiscussion: ?{|
    +ok: boolean,
    +error: ?string,
    +discussionId: ?string,
    +messageId: ?string,
    +attachments: ?$ReadOnlyArray<?{|
      +id: ?string,
      +uploadId: ?string,
      +fileName: ?string,
      +documentType: ?string,
    |}>,
    +message: ?{|
      +recipients: ?$ReadOnlyArray<?string>,
      +title: ?string,
      +text: ?string,
      +sender: ?string,
      +order: ?number,
    |},
    +dates: ?{|
      +creation: ?string,
      +lastUpdate: ?string,
    |},
  |}
|};
export type SendDiscussionMutation = {|
  variables: SendDiscussionMutationVariables,
  response: SendDiscussionMutationResponse,
|};
*/


/*
mutation SendDiscussionMutation(
  $input: SendDiscussionInput!
) {
  sendDiscussion(input: $input) {
    ok
    error
    discussionId
    messageId
    attachments {
      id
      uploadId
      fileName
      documentType
    }
    message {
      recipients
      title
      text
      sender
      order
      id
    }
    dates {
      creation
      lastUpdate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ok",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "error",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discussionId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "messageId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uploadId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fileName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipients",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sender",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendDiscussionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendDiscussionMutationResponse",
        "kind": "LinkedField",
        "name": "sendDiscussion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendDiscussionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SendDiscussionMutationResponse",
        "kind": "LinkedField",
        "name": "sendDiscussion",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "message",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1d3054a7c8e252d45dc31cb4162fe80",
    "id": null,
    "metadata": {},
    "name": "SendDiscussionMutation",
    "operationKind": "mutation",
    "text": "mutation SendDiscussionMutation(\n  $input: SendDiscussionInput!\n) {\n  sendDiscussion(input: $input) {\n    ok\n    error\n    discussionId\n    messageId\n    attachments {\n      id\n      uploadId\n      fileName\n      documentType\n    }\n    message {\n      recipients\n      title\n      text\n      sender\n      order\n      id\n    }\n    dates {\n      creation\n      lastUpdate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0868749cfdaabbd07918d5a89e34da16';

module.exports = node;
