import {
  commitMutation,
  graphql
} from 'react-relay';

const mutation = graphql`
    mutation ForgotPasswordTunnelMutation($forgotPassword: Boolean!, $subscriberId: String!) {
      forgotPasswordTunnel(forgotPassword: $forgotPassword, subscriberId: $subscriberId) {
            ok
            error
            subscriberId,
            forgotPassword,
            email
        }
    }
`;

export default async (environment, { forgotPassword, subscriberId }, done) => {
  const variables = {
    forgotPassword,
    subscriberId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ forgotPasswordTunnel }) => {
        const { ok, error, subscriberId, forgotPassword, email } = forgotPasswordTunnel;
        done(ok, error, subscriberId, forgotPassword, email);
      },
      onError: err => console.error(err)
    }
  );
};
