import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import BackButton from '@@components/BackButton';

const R = require('ramda');

const Step5 = ({
  id,
  onDone,
  subscriberId,
  forgotPasswordTunnel,
  handlePreviousStep
}) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [passwordValid, setPasswordValid] = React.useState(false);
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('createNewPassword');

  React.useEffect(() => {
    if (fetching.isDone()) {
      if (fetching.getPayload().forgotPasswordTunnel) {
        setPasswordValid(true);
      } else {
        onDone({
          id,
          payload: fetching.getPayload()
        });
      }
    }
    if (fetching.getError()) {
      setErrorMessage(fetching.getError());
    }
  }, [fetching.isDone(), fetching.getPayload(), fetching.getError()]);

  const handleSubmit = (input) => {
    onFormsSubmit(R.assoc('forgotPasswordTunnel', forgotPasswordTunnel, input));
  };

  const formsBlock = (
    <Forms
      formsData={formsData}
      defaultValues={{
        subscriberId,
        forgotPasswordTunnel
      }}
      columns={1}
      onChange={() => fetching.reset()}
      onSubmit={handleSubmit}
      submitSection={() => (
        <>
          { !R.isEmpty(errorMessage) && (
            <div className="text-danger">{errorMessage}</div>)
          }
          <div className="f-connect-bottom-buttons">
            <BackButton onClick={handlePreviousStep}/>
            {
              forgotPasswordTunnel
                ? (
                  <button
                    className="f-button f-button-coral"
                    type="submit"
                  >
                    {'Se connecter'}
                  </button>)
                : (
                  <button
                    className="f-button f-button-coral"
                    type="submit"
                    disabled={fetching.isFetching()}
                  >
                    {'Continuer'}
                  </button>)
            }
          </div>
        </>
      )}
    />
  );

  return (
    <div className='f-connect-container'>
      {passwordValid && <Redirect to="/" />}
      <div className='f-connect-forms-block'>
        <div className='f-connect-title'>
          {'Créez votre mot de passe'}
        </div>
        <div className='f-connect-intro'>
          {'On y est presque ! Afin de sécuriser votre compte, veuillez' +
          ' choisir un mot de passe. Il vous servira à chaque connexion' +
          ' à votre espace Espace Adhérent APPA.'}
        </div>
        { formsBlock }
      </div>
    </div>
  );
};

Step5.propTypes = {
  id: PropTypes.string,
  onDone: PropTypes.func,
  subscriberId: PropTypes.string,
  handlePreviousStep: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  forgotPasswordTunnel: PropTypes.bool.isRequired
};

export default Step5;
