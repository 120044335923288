/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'email',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Email",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'firstname',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Prénom",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'lastname',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Nom",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;