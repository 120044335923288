import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { iconClose } from '@@images';

const Modale = ({
  show,
  onDisable,
  title,
  footer,
  children,
  className,
  overhead
}) => {
  const containerClasses = classNames('f-modal-container', {
    'f-modal-hidden': !show
  });

  // This row always takes up space, even when it is invisible
  const closeIconRowClass = classNames('f-modal-close-icon-container', {
    invisible: R.isNil(onDisable)
  });
  const closeIconRow = (
    <div className={closeIconRowClass}>
      <div className='f-modal-close-icon' onClick={onDisable} >
        <img src={iconClose} alt='close-icon' />
      </div>
    </div>
  );

  const blockTitle = RA.isNotNil(title) && (
    <div className='f-modal-title'>
      {title}
    </div>
  );

  const blockBody = (
    <div className='f-modal-body'>
      {children}
    </div>
  );

  const blockFooter = RA.isNotNil(footer) && (
    <div className='f-modal-footer'>
      {footer}
    </div>
  );

  const windowClass = RA.isNilOrEmpty(className)
    ? classNames('f-modal-window', 'f-modal-window-classic')
    : classNames('f-modal-window', className);
  return (
    <div className={containerClasses} >
      <div className={windowClass}>
        { overhead && closeIconRow }
        { blockTitle }
        { blockBody }
        { blockFooter }
      </div>
    </div>
  );
};

Modale.propTypes = {
  show: PropTypes.bool,
  onDisable: PropTypes.func,
  title: PropTypes.string,
  footer: PropTypes.element,
  className: PropTypes.string,
  overhead: PropTypes.bool
};

Modale.defaultProps = {
  show: false,
  className: 'f-modal-window-classic',
  overhead: true
};

export default Modale;
