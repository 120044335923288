import { graphql } from 'react-relay';

const QAdherentContactQuery = graphql`
  query QAdherentContactQuery($id: ID!) {
    adherent(id: $id) {
      id
      contact {
        emails {
          type
          value
        }
        phoneNumbers {
          type
          value
        }
        address {
          address1
          address2
          address3
          zipCode
          city
          country
          pnd
        }
      }
    }
  }
`;

export {
  QAdherentContactQuery
};
