import React from 'react';
import PropTypes from 'prop-types';
import { findActivity, findEchelon, findFunction, getActivityRate, getField, typologyEq } from './utils';
import { DoubleBlock, LoadingBlock } from '@@components';
import { typologies } from '@@enum';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const ProfessionalInformation = ({
  professionalInformation,
  contracts,
  onEdit,
  fitToGrid
}) => {
  if (!contracts || !professionalInformation) return <LoadingBlock/>;

  const getProfessionalInformationField = getField(professionalInformation);

  const isPHHUTypology = typologyEq(typologies.PHHU, contracts);
  const isLiberalTypology = typologyEq(typologies.LIBERAL, contracts);
  const isInternTypology = typologyEq(typologies.INTERNE, contracts);

  const subscriberFunction = findFunction(contracts);
  const echelon = findEchelon(contracts);
  const hospitalName = getProfessionalInformationField(['hospital', 'details', 'name']);
  const displayHospital = isPHHUTypology && RA.isNotNilOrEmpty(hospitalName);
  const specialty = getProfessionalInformationField(['specialty']);
  const activity = findActivity(contracts);
  const activityRate = getActivityRate(contracts);

  const fonctionBlockContent = (
    <ul>
      <li>{subscriberFunction}</li>
      <li>{isPHHUTypology && echelon}</li>
      <li>{displayHospital && `${hospitalName}`}</li>
      <li>{(isPHHUTypology || isLiberalTypology) && `Spécialité : ${specialty}`}</li>
    </ul>
  );

  const exerciseModeContent = R.or(isPHHUTypology, isInternTypology) && (
    <ul>
      <li>{isPHHUTypology && activity}</li>
      <li>{`Taux d'activité : ${activityRate} %`}</li>
      <li>{isPHHUTypology && `Activité libérale : ${isLiberalTypology ? 'Oui' : 'Non'}`}</li>
    </ul>
  );

  return <DoubleBlock
    title1="Fonction"
    title2="Mode d'exercice"
    onEdit={onEdit}
    child1={fonctionBlockContent}
    child2={exerciseModeContent}
    fitToGrid={fitToGrid}
  />;
};

ProfessionalInformation.defaultProps = {
  fitToGrid: true
};

ProfessionalInformation.propTypes = {
  professionalInformation: PropTypes.object,
  contracts: PropTypes.array,
  getField: PropTypes.func,
  onEdit: PropTypes.func,
  fitToGrid: PropTypes.bool.isRequired
};

export default ProfessionalInformation;
