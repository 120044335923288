/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CloseDiscussionInput = {|
  discussionId?: ?string
|};
export type CloseDiscussionMutationVariables = {|
  input: CloseDiscussionInput
|};
export type CloseDiscussionMutationResponse = {|
  +closeDiscussion: ?{|
    +ok: boolean,
    +error: ?string,
    +discussion: ?{|
      +id: string,
      +status: ?string,
    |},
  |}
|};
export type CloseDiscussionMutation = {|
  variables: CloseDiscussionMutationVariables,
  response: CloseDiscussionMutationResponse,
|};
*/


/*
mutation CloseDiscussionMutation(
  $input: CloseDiscussionInput!
) {
  closeDiscussion(input: $input) {
    ok
    error
    discussion {
      id
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CloseDiscussionMutationResponse",
    "kind": "LinkedField",
    "name": "closeDiscussion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Discussion",
        "kind": "LinkedField",
        "name": "discussion",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CloseDiscussionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CloseDiscussionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b30724439c89a37d72e8d0e677989149",
    "id": null,
    "metadata": {},
    "name": "CloseDiscussionMutation",
    "operationKind": "mutation",
    "text": "mutation CloseDiscussionMutation(\n  $input: CloseDiscussionInput!\n) {\n  closeDiscussion(input: $input) {\n    ok\n    error\n    discussion {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd685b09c9efe29f3de26a1c881fa126b';

module.exports = node;
