import { compose, pick, values } from 'ramda';

const phoneTypes = {
  MOBILE_PRO: 'mobile_pro',
  MOBILE_PERSO: 'mobile_perso',
  MOBILE_CERTIFIED: 'mobile_certified',
  FIXE_PRO: 'fix_pro',
  FIXE_PERSO: 'fix_perso'
};

const mobilePhoneTypes = [
  'MOBILE_PRO',
  'MOBILE_PERSO',
  'MOBILE_CERTIFIED'
];

const getMobilePhoneTypes = compose(
  values,
  pick(mobilePhoneTypes)
)(phoneTypes);

export {
  phoneTypes,
  getMobilePhoneTypes
};
