/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QBackOfficeMessageThemesQueryVariables = {||};
export type QBackOfficeMessageThemesQueryResponse = {|
  +references: ?{|
    +messageThemesBackOffice: ?$ReadOnlyArray<?{|
      +id: ?string,
      +title: ?string,
      +actionId: ?string,
      +subThemes: ?$ReadOnlyArray<?{|
        +id: ?string,
        +actionId: ?string,
        +title: ?string,
      |}>,
    |}>
  |}
|};
export type QBackOfficeMessageThemesQuery = {|
  variables: QBackOfficeMessageThemesQueryVariables,
  response: QBackOfficeMessageThemesQueryResponse,
|};
*/


/*
query QBackOfficeMessageThemesQuery {
  references {
    messageThemesBackOffice {
      id
      title
      actionId
      subThemes {
        id
        actionId
        title
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionId",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "References",
    "kind": "LinkedField",
    "name": "references",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageThemeReferences",
        "kind": "LinkedField",
        "name": "messageThemesBackOffice",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageSubThemeReferences",
            "kind": "LinkedField",
            "name": "subThemes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QBackOfficeMessageThemesQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QBackOfficeMessageThemesQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "804fa94e03d666196513d859c1ee4033",
    "id": null,
    "metadata": {},
    "name": "QBackOfficeMessageThemesQuery",
    "operationKind": "query",
    "text": "query QBackOfficeMessageThemesQuery {\n  references {\n    messageThemesBackOffice {\n      id\n      title\n      actionId\n      subThemes {\n        id\n        actionId\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'de9164e04a92867415f82dc0fe2ce4e1';

module.exports = node;
