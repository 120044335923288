/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCmsContactQueryVariables = {||};
export type QCmsContactQueryResponse = {|
  +cms: ?{|
    +contact: ?{|
      +appaPhoneNumber: ?string,
      +appaAddress: ?string,
      +mercerPhoneNumber: ?string,
      +santeclairPhoneNumber: ?string,
      +europAssistanceMetropolePhoneNumber: ?string,
      +europAssistanceDROMPhoneNumber: ?string,
      +europAssistanceURL: ?string,
    |}
  |}
|};
export type QCmsContactQuery = {|
  variables: QCmsContactQueryVariables,
  response: QCmsContactQueryResponse,
|};
*/


/*
query QCmsContactQuery {
  cms {
    contact {
      appaPhoneNumber
      appaAddress
      mercerPhoneNumber
      santeclairPhoneNumber
      europAssistanceMetropolePhoneNumber
      europAssistanceDROMPhoneNumber
      europAssistanceURL
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appaPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "appaAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mercerPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "santeclairPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "europAssistanceMetropolePhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "europAssistanceDROMPhoneNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "europAssistanceURL",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsContactQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QCmsContactQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7d0455d2efd901af35ad1e2a50b5b4a1",
    "id": null,
    "metadata": {},
    "name": "QCmsContactQuery",
    "operationKind": "query",
    "text": "query QCmsContactQuery {\n  cms {\n    contact {\n      appaPhoneNumber\n      appaAddress\n      mercerPhoneNumber\n      santeclairPhoneNumber\n      europAssistanceMetropolePhoneNumber\n      europAssistanceDROMPhoneNumber\n      europAssistanceURL\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '80e3cb98c1ce6958720960abd96ccca2';

module.exports = node;
