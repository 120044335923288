import React from 'react';
import PropTypes from 'prop-types';
import { documentCardLogo, downloadIcon, eyeIconCoral } from '@@images';

const getDocumentUrl = ({ fileId, source, fileName, documentId, download }) => (
  `/documents/get/${encodeURIComponent(fileId)}?` +
  `source=${encodeURIComponent(source)}` +
  `&fileName=${encodeURIComponent(fileName)}` +
  `&documentId=${encodeURIComponent(documentId)}` +
  `&download=${encodeURIComponent(download)}`
);

const DocumentBlock = ({
  fileId,
  title,
  description,
  fileName,
  source,
  documentId
}) => {
  const getUrl = (download = false) => getDocumentUrl({ fileId, source, fileName, documentId, download });

  return (
    <div className="f-document-block">

      <div className="f-document-block-header">
        <div className="f-document-block-img">
          <img src={documentCardLogo} alt='Logo-document'/>
        </div>
        <div className='f-document-block-content'>
          <div className='f-document-block-title'>
            {title}
          </div>
          <div className='f-document-block-description'>
            {description}
          </div>
        </div>
      </div>

      <div className="f-document-block-footer">
        <a className="f-button-coral-reverse-rounded"
          href={getUrl(true)}
          target={'_blank'} rel={'noreferrer'}>
          <div className="f-document-block-btn">
            <img src={downloadIcon} alt="download"/>
            <span>{'Télécharger'}</span>
          </div>
        </a>
        <a className="f-button-coral-reverse-rounded"
          href={getUrl()} target={'_blank'} rel={'noreferrer'}>
          <div className="f-document-block-btn">
            <img src={eyeIconCoral} alt="display"/>
            <span>{'Afficher'}</span>
          </div>
        </a>
      </div>

    </div>
  );
};

DocumentBlock.propTypes = {
  fileId: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fileName: PropTypes.string,
  source: PropTypes.string,
  documentId: PropTypes.string
};

export default DocumentBlock;
