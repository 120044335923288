/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'email',
    required: true,
    input: 'email',
    source: '__UNDEFINED__',
    label: "Email",
    placeholder: "Votre adresse email",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'subscriberId',
    required: true,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;