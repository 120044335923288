import React from 'react';

const RgpdFooter = () => (
  <div className="footer-container">
    <div className="footer-rgpd">
        Bienvenue sur le site de démonstration Bessé Mutuelle.
    </div>
  </div>
);

export default RgpdFooter;
