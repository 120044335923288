import React from 'react';
import { QDiscussionSignRequestQuery } from '@@queries/QDiscussionSignRequest';
import useForms from '@@formsHook';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useDiscussion = (id, data) => {
  const environment = useRelayEnvironment();
  const [discussion, setDiscussion] = React.useState({});
  const [signatureInfo, setSignatureInfo] = React.useState();

  const closeDiscussion = useForms('closeDiscussion');
  const idRef = React.useRef();
  const dataRef = React.useRef();

  React.useEffect(() => {
    if (R.equals(idRef.current, id)) return;
    idRef.current = id;
    if (R.equals(dataRef.current, data)) return;
    dataRef.current = data;
    if (R.includes('signRequest', data)) getDiscussion({ id });
  }, [id, data]);

  const getDiscussion = async () => {
    const { discussionSignRequest } = await fetchQuery(environment, QDiscussionSignRequestQuery, { discussionId: id }).toPromise();
    setDiscussion({ discussionSignRequest });
  };

  const onCloseDiscussion = () => {
    closeDiscussion.onFormsSubmit({ discussionId: id });
  };

  // const onSignDocumentsRequest = () => {
  //   console.log('=======> onSignDocumentsRequest');
  //   SignDocumentsRequestMutation(environment, { discussionId: discussion.id }, (ok, err, data) => {
  //     console.log(JSON.stringify(data));
  //     setSignatureInfo({
  //       status: data.status,
  //       memberId: data.memberId,
  //       signatureUi: data.signatureUi
  //     });
  //   }, { updater: undefined });
  // };

  return {
    discussion,
    signatureInfo,
    onCloseDiscussion,
    // onSignDocumentsRequest
  };
};

export default useDiscussion;
