import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { iconLightBulb } from '@@images';
import { MAX_SIZE_BYTES } from '@@pages/documents/utils';

const INTRO_TEXT = 'Vous avez changé d’adresse. Merci d\'ajouter votre' +
  ' justificatif de domicile.';
const BTN_TEXT = 'Ajouter un justificatif';

const UploadFile = ({
  uploadUrl,
  onError,
  onChange
}) => {
  const onDrop = useCallback(async (acceptedFiles) => {
    if (RA.isNilOrEmpty(acceptedFiles)) return;

    const file = R.head(acceptedFiles);

    // check file size
    // todo - add some kind of error message with the form validation rules
    if (R.gt(file.size, MAX_SIZE_BYTES)) return;

    // Upload the file to /uploads dir
    const formData = new FormData();
    formData.append('file', file, file.name);
    const response = await fetch(uploadUrl, {
      method: 'POST',
      body: formData
    });
    if (response.ok) {
      const { uploadId, fileName } = await response.json();
      onChange([{ uploadId, fileName }]);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noDrag: false,
    preventDropOnDocument: true,
    accept: 'image/jpeg, image/png, application/pdf'
  });

  return (
    <div {...getRootProps()} className="f-upload-proof-block">
      <input {...getInputProps()}/>
      <div className={'f-upload-proof-intro-container'}>
        <div className={'f-upload-proof-intro-img'}>
          <img src={iconLightBulb} alt={'icon'}/>
        </div>
        <div className={'f-upload-proof-intro-text'}>
          {INTRO_TEXT}
        </div>
      </div>
      <div className={'f-upload-proof-btn-container'}>
        <div className={'f-upload-proof-btn'}>
          {BTN_TEXT}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
