import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

// extract the list of discussionId from an array of MessageNotified
export const getUnreadDiscussionIds = R.ifElse(
  RA.isNotNilOrEmpty,
  R.compose(
    R.uniq,
    R.map(R.prop('discussionId')),
    R.filter(R.has('discussionId'))),
  R.always([])
);
