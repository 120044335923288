import React from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { Link } from 'react-router-dom';
import { emailTypes, phoneTypes } from '@@enum';
import { BreadCrumb } from '@@components';

const R = require('ramda');

const TEXT_TITLE = 'Modification de vos identifiants de connexion';
const TEXT_TITLE_1 = 'Vos identifiants de connexion';
const TEXT_TITLE_2 = 'Modification de votre mot de passe';
const TEXT_TITLE_3 = 'Succès';

const getField = (object) => (path) => R.pathOr('', path, object);

const EditPassword = ({ pathConfig }) => {
  const [messages, setMessages] = React.useState({});

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('updateAdherentPassword');

  React.useEffect(() => {
    if (fetching.isDone()) {
      setMessages(R.over(R.lensProp('succesMessage'), R.always(R.prop('succesMessage', fetching.getPayload()))));
    }
    if (fetching.getError()) {
      setMessages(R.over(R.lensProp('errorMessage'), R.always(fetching.getError())));
    }
  }, [fetching.isDone(), fetching.getPayload(), fetching.getError()]);

  const isDisabled = fetching.isFetching();

  const { user } = useKeycloakSession();

  const { adherent } = useAdherent(user.customerId, ['contact']);

  if (R.isNil(adherent.contact)) return null;

  const getContactField = getField(adherent.contact);

  const getCertifiedContactByType = (type, certifType) => {
    return R.compose(
      R.prop('value'),
      R.find(R.propEq('type', certifType))
    )(getContactField([type]));
  };

  const { succesMessage, errorMessage } = messages;

  const breadCrumb = <BreadCrumb
    rootPath={'mesinformations'}
    rootName={'Mes informations personnelles'}
    location={TEXT_TITLE}/>;

  const titleEditIdentifiers = (
    <div className='subsection-title'>
      {TEXT_TITLE_1}
    </div>
  );
  const titleEditPassword = (
    <div className='subsection-title'>
      {TEXT_TITLE_2}
    </div>
  );
  const titleSuccess = (
    <div className='subsection-title'>
      {TEXT_TITLE_3}
    </div>
  );

  const doShowSuccess = !!succesMessage;

  // TODO feels like this should use <Forms> or <Field> component instead
  const editIdentifiersForms = !doShowSuccess && (
    <div className='f-form-layout'>
      <div className='f-block-field'>
        <div className='f-block-field-row-header'>
          <div className='f-block-field-row-header-label'>
            {'Email'}
          </div>
        </div>
        <div className='f-block-field-row-input'>
          <input
            className='f-input'
            type='email'
            disabled={true}
            value={getCertifiedContactByType('emails', emailTypes.CERTIFIED)}
          />
        </div>
      </div>
      <div className='f-block-field'>
        <div className='f-block-field-row-header'>
          <div className='f-block-field-row-header-label'>
            {'Numéro de téléphone mobile'}
          </div>
        </div>
        <div className='f-block-field-row-input'>
          <input
            className='f-input'
            type='text'
            disabled={true}
            value={getCertifiedContactByType('phoneNumbers', phoneTypes.MOBILE_CERTIFIED)}
          />
        </div>
      </div>
    </div>
  );

  const editPasswordForms = !doShowSuccess && (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{ subscriberId: user.customerId }}
      onChange={fetching.reset}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <div>
          {errorMessage && (
            <div className="f-phone-type-error-container">
              {errorMessage}
            </div>)
          }
          <div className="f-edit-family-status-buttons mt-8">
            <Link
              className="f-button f-button-grey f-responsive-btn"
              to={pathConfig.cancelPath}
            >
              {'Annuler'}
            </Link>
            <button
              className="f-button f-button-coral f-responsive-btn"
              disabled={isDisabled}
              type="submit"
            >
              {'Enregistrer'}
            </button>
          </div>
        </div>
      )}
    />
  );

  const blockForms = !doShowSuccess && (
    <div className='f-edit-password-forms-container'>

      <div>
        <div className='f-page-content-header'>
          {titleEditIdentifiers}
        </div>
        <div className='f-edit-password-forms-block'>
          {editIdentifiersForms}
        </div>
      </div>

      <div>
        <div className='f-page-content-header'>
          {titleEditPassword}
        </div>
        <div className='f-edit-password-forms-block'>
          {editPasswordForms}
        </div>
      </div>

    </div>
  );

  const blockSuccess = doShowSuccess && (
    <div className='f-edit-password-success-container'>
      <div className='f-page-content-header'>
        {titleSuccess}
      </div>
      <div className='f-edit-password-success-text'>
        {succesMessage}
      </div>
      <div className="f-edit-family-status-buttons mt-4">
        <Link
          className='f-button f-button-coral f-responsive-btn'
          to={'/mesinformations'}
        >
          {'Retour'}
        </Link>
      </div>
    </div>
  );

  return (
    <div className='f-base-page-container'>
      <div className='f-page'>
        <div className='f-page-body'>
          { breadCrumb }
          { blockForms }
          { blockSuccess }
        </div>
      </div>
    </div>
  );
};

EditPassword.propTypes = {
  pathConfig: PropTypes.shape({
    editPath: PropTypes.string,
    cancelPath: PropTypes.string
  })
};

export default EditPassword;
