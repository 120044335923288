/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAdherentFirstConnectionStatusMutationVariables = {|
  status: boolean,
  subscriberId: string,
|};
export type UpdateAdherentFirstConnectionStatusMutationResponse = {|
  +updateFirstConnectionStatus: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateAdherentFirstConnectionStatusMutation = {|
  variables: UpdateAdherentFirstConnectionStatusMutationVariables,
  response: UpdateAdherentFirstConnectionStatusMutationResponse,
|};
*/


/*
mutation UpdateAdherentFirstConnectionStatusMutation(
  $status: Boolean!
  $subscriberId: String!
) {
  updateFirstConnectionStatus(status: $status, subscriberId: $subscriberId) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriberId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "subscriberId",
        "variableName": "subscriberId"
      }
    ],
    "concreteType": "updateFirstConnectionStatusMutationResponse",
    "kind": "LinkedField",
    "name": "updateFirstConnectionStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdherentFirstConnectionStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdherentFirstConnectionStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ddd62b9531b6ef19e43b4272ab485076",
    "id": null,
    "metadata": {},
    "name": "UpdateAdherentFirstConnectionStatusMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdherentFirstConnectionStatusMutation(\n  $status: Boolean!\n  $subscriberId: String!\n) {\n  updateFirstConnectionStatus(status: $status, subscriberId: $subscriberId) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a78903cc179287110dd074f3961ccfa1';

module.exports = node;
