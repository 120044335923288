import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { iconCalendar } from '@@images';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr);

const DatePickerCustomInput = forwardRef(({
  value,
  onClick,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  hasError,
  customPlaceholder
}, ref) => {
  // const classes = hasError ? 'f-input-red' : 'f-input-gray';
  const handleOnChange = (e) => {
    // * Get access to new value
    const v = R.path(['target', 'value'], e);

    // * Handle Empty
    if (R.isEmpty(v) || R.isNil(v)) {
      return null;
    }

    onChange(e);
    return v;
  };

  return (
    <label className={'flex flex-row w-full'} ref={ref}>
      <input
        className={classNames({
          'f-input-red': hasError,
          'f-input-gray': !hasError,
          'f-datepicker-input': true
        })}
        placeholder={customPlaceholder}
        type="text"
        onClick={onClick}
        onChange={handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        value={value}
      />
      <span className={classNames('f-datepicker-icon-calendar-box', {
        invisible: RA.isNotNilOrEmpty(value)
      })}>
        <img src={iconCalendar} alt={'date'}/>
      </span>
    </label>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';
DatePickerCustomInput.propTypes = {
  customPlaceholder: PropTypes.string
};

const DatePicker = ({
  value,
  format,
  onChange,
  hasError,
  placeholder
}) => {
  return (
    <ReactDatePicker
      locale={'fr'}
      dateFormat={format}
      selected={value}
      isClearable
      onChange={onChange}
      customInput={
        <DatePickerCustomInput
          hasError={hasError}
          customPlaceholder={placeholder}
        />
      }
    />
  );
};

DatePicker.propTypes = {
  placeholder: PropTypes.string
};

DatePicker.defaultProps = {
  placeholder: 'JJ/MM/AAAA'
};

export default DatePicker;
