import { graphql } from 'react-relay';

const QAdherentDocumentsQuery = graphql`
  query QAdherentDocumentsQuery($id: ID! $filter: DocumentFilterInput) {
    adherent(id: $id) {
      id
      documents(filter: $filter) {
        _id
        fileId
        fileSource
        subscriberId
        title
        author
        type
        subType
        description
        status
        createdAt
        updatedAt
        fileName
      }
    }
  }
`;

export {
  QAdherentDocumentsQuery
};
