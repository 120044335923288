/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/

const R = require('ramda');
import {
commitMutation,
graphql
} from 'react-relay';

const mutation = graphql`
mutation SampleFormMutation($input: SampleFormInput!) {
  sampleForm(input: $input) {
    ok
    error
    
    email 
    
    firstname 
    
    lastname 
    
  }
}
`;

export default async (environment, input, done, { updater }) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, response) => {
        if (R.is(Function, updater)) updater(input, proxyStore, response);
      },
      onCompleted: ({ sampleForm }) => {
        const { ok, error } = sampleForm;
        done(ok, error, R.omit(['ok', 'error'], sampleForm));
      },
      onError: err => console.error(err)
    }
  );
};