/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmailTypeEnum = "certified" | "perso" | "pro" | "%future added value";
export type PhoneNumberTypeEnum = "fix_perso" | "fix_pro" | "mobile_certified" | "mobile_perso" | "mobile_pro" | "%future added value";
export type QAdherentContactQueryVariables = {|
  id: string
|};
export type QAdherentContactQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +contact: ?{|
      +emails: ?$ReadOnlyArray<?{|
        +type: ?EmailTypeEnum,
        +value: ?string,
      |}>,
      +phoneNumbers: ?$ReadOnlyArray<?{|
        +type: ?PhoneNumberTypeEnum,
        +value: ?string,
      |}>,
      +address: ?{|
        +address1: ?string,
        +address2: ?string,
        +address3: ?string,
        +zipCode: ?string,
        +city: ?string,
        +country: ?string,
        +pnd: ?boolean,
      |},
    |},
  |}
|};
export type QAdherentContactQuery = {|
  variables: QAdherentContactQueryVariables,
  response: QAdherentContactQueryResponse,
|};
*/


/*
query QAdherentContactQuery(
  $id: ID!
) {
  adherent(id: $id) {
    id
    contact {
      emails {
        type
        value
      }
      phoneNumbers {
        type
        value
      }
      address {
        address1
        address2
        address3
        zipCode
        city
        country
        pnd
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Adherent",
    "kind": "LinkedField",
    "name": "adherent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdherentContact",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AdherentEmail",
            "kind": "LinkedField",
            "name": "emails",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PhoneNumber",
            "kind": "LinkedField",
            "name": "phoneNumbers",
            "plural": true,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdherentAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address1",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address2",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address3",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zipCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pnd",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentContactQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAdherentContactQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7143bab0d452c7a6132a7f72d6306df8",
    "id": null,
    "metadata": {},
    "name": "QAdherentContactQuery",
    "operationKind": "query",
    "text": "query QAdherentContactQuery(\n  $id: ID!\n) {\n  adherent(id: $id) {\n    id\n    contact {\n      emails {\n        type\n        value\n      }\n      phoneNumbers {\n        type\n        value\n      }\n      address {\n        address1\n        address2\n        address3\n        zipCode\n        city\n        country\n        pnd\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0b83573ebf2a7b4e23a7ab6593b012a6';

module.exports = node;
