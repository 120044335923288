import { handleActions } from 'redux-actions';
import Keycloak from 'keycloak-js';

const R = require('ramda');
const initialState = {
  keycloak: null,
  user: null,
  synchStatus: 'PENDING'
};

export const keycloakSession = handleActions({
  KEYCLOAK_SESSION_UPDATE_STATE: (state, { payload }) => {
    return ({
      ...state,
      ...payload
    });
  }
}, initialState);
