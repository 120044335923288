import { graphql } from 'react-relay';

const QAdherentCivilStatusQuery = graphql`
  query QAdherentCivilStatusQuery($id: ID!) {
    adherent(id: $id) {
      id
      civilStatus {
        civility
        lastName
        maidenName
        firstName
        maritalStatus
        birthDate
        nic
        nbChild
        managementStartDate
      }
    }
  }
`;

export {
  QAdherentCivilStatusQuery
};
