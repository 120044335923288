import { graphql } from 'react-relay';

const QGAnalyticsConfigQuery = graphql`
  query QGAnalyticsConfigQuery {
    configGa {
      key
    }
  }
`;

export {
  QGAnalyticsConfigQuery
};
