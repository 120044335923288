import React from 'react';
import { Link } from 'react-router-dom';
import { logoBesse } from '@@images';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const Footer = () => {
  const onClickContacts = () => sendGAEventIfDefined(configGA.FOOTER_CONTACTS);
  return (
    <div className={'f-footer-container'}>
      <div className={'f-footer-body'}>
        <img className="mb-5" src={logoBesse} alt={'logoBesse'}/>
        <div className="text-center text-navy-blue font-bold">
          <Link to="/mentionslegales">
            {'Mentions légales '}
          </Link>
          {'|'}
          <Link to="/donneespersonnelles">
            {' Données personnelles '}
          </Link>
          {'|'}
          <Link to="/cgu">
            {' CGU '}
          </Link>
          {'|'}
          <Link to="/contacts" onClick={onClickContacts}>
            {' Contact'}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
