import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { Link } from 'react-router-dom';
import EditEmailsOtpModal from './EditEmailsOtpModal';
import { BreadCrumb } from '@@components';
import * as RA from 'ramda-adjunct';
import * as R from 'ramda';

const TEXT_TITLE = 'Modification de votre email';
const TEXT_1 = 'Votre email vous servira en tant qu\'identifiant à chaque' +
  ' connexion. Il vous permettra aussi de recevoir des notifications et les' +
  ' communications APPA et de débloquer votre compte si nécessaire.';

const EditEmails = ({ pathConfig }) => {
  const { user } = useKeycloakSession();

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('updateAdherentEmail');

  const [data, setData] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [emailErrors, setEmailErrors] = useState([]);

  React.useEffect(() => {
    if (fetching.isDone()) {
      const payload = fetching.getPayload();
      const newEmailErrors = R.propOr([], 'emailErrors', payload);
      setEmailErrors(newEmailErrors);
      if (RA.isNilOrEmpty(newEmailErrors)) {
        setData(R.over(R.lensProp('email'), R.always(R.prop('email', payload))));
        setData(R.over(R.lensProp('uuid'), R.always(R.prop('otp', payload))));
        setOpenModal(true);
      }
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  const { adherent } = useAdherent(user.customerId, ['contact']);

  if (R.isNil(adherent.contact)) return null;

  const isDisabled = fetching.isFetching();
  const email = R.compose(
    R.pathOr('', ['value']),
    R.find(R.propEq('type', 'certified')),
    R.pathOr([], ['contact', 'emails'])
  )(adherent);

  const breadCrumb = <BreadCrumb
    rootPath={'mesinformations'}
    rootName={'Mes informations personnelles'}
    location={TEXT_TITLE}/>;

  const title = (
    <div className='subsection-title'>
      {TEXT_TITLE}
    </div>
  );

  const modal = <EditEmailsOtpModal
    show={openModal}
    setDisabled={setOpenModal}
    uuid={data.uuid}
    email={data.email}
    subscriberId={user.customerId}/>;

  const blockForms = (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        email,
        subscriberId: user.customerId
      }}
      options={{ emailErrors }}
      onChange={fetching.reset}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <div className='f-edit-family-status-buttons mt-8'>
          <Link
            className='f-button f-button-grey f-responsive-btn'
            to={pathConfig.cancelPath}
          >
            {'Annuler'}
          </Link>
          <button
            className='f-button f-button-coral f-responsive-btn'
            disabled={isDisabled}
            type='submit'
          >
            {'Enregistrer'}
          </button>
        </div>
      )}
    />
  );

  return (
    <div className='f-base-page-container'>
      <div className='f-page'>
        <div className='f-page-body'>
          { breadCrumb }
          <div className='f-page-content-header'>
            { title }
          </div>
          <div className='f-edit-phone-numbers-intro'>
            {TEXT_1}
          </div>
          <div className='f-edit-email-forms-block'>
            { blockForms }
          </div>
          { modal }
        </div>
      </div>
    </div>
  );
};

EditEmails.propTypes = {
  pathConfig: PropTypes.shape({
    editPath: PropTypes.string,
    cancelPath: PropTypes.string
  })
};

export default EditEmails;
