import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const DocumentGrid = ({ documentBlocks, defaultSize }) => {
  const [showAll, setShowAll] = useState(false);
  const seeMore = () => setShowAll(true);

  // total number of items that can be displayed
  const nbTotal = R.length(documentBlocks);
  const nbItemsDisplayed = showAll ? nbTotal : defaultSize;
  const fromIndex = 0;
  const toIndex = Math.max(defaultSize, nbItemsDisplayed);
  const displayedBlocks = R.slice(fromIndex, toIndex, documentBlocks);
  // show button only when nbTotal is at least 1 more than nbItemsDisplayed
  const doShowButton = R.gt(nbTotal, nbItemsDisplayed);

  const seeMoreButton = doShowButton && (
    <div className='f-document-section-see-more'>
      <div
        className='f-button-turquoise-reverse f-document-see-more-btn'
        onClick={seeMore}
      >
        {'Voir Plus'}
      </div>
    </div>
  );

  return (
    <div className='f-document-section-content'>
      <div className='f-document-section-grid'>
        { displayedBlocks }
      </div>
      { seeMoreButton }
    </div>
  );
};

DocumentGrid.propTypes = {
  documentBlocks: PropTypes.arrayOf(PropTypes.element).isRequired,
  defaultSize: PropTypes.number.isRequired
};

DocumentGrid.defaultProps = {
  documentBlocks: [],
  defaultSize: 3
};

export default DocumentGrid;
