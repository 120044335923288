import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as R from 'ramda';
import Home from './Home';
import ContractDetails from './ContractDetails';

const paths = (root) => (k) => {
  const config = {
    contractDetail: {
      editPath: `${root}/details`,
      cancelPath: root
    }
  };
  if (R.isNil(k)) return config;
  return R.prop(k, config);
};

const Index = ({ match }) => {
  const path = v => `${match.url}${v}`;
  const pathConfig = paths(match.url);

  return (
    <Switch>
      <Route exact path={path('')} render={ () =>
        <Home pathConfig={pathConfig()}/> }
      />
      <Route path={pathConfig('contractDetail').editPath} render={ () =>
        <ContractDetails pathConfig={pathConfig('contractDetail')}/> }
      />
      <Redirect to={path('')}/>
    </Switch>
  );
};

Index.propTypes = {
  match: PropTypes.object
};

export default Index;
