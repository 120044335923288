/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCmsMercerQueryVariables = {||};
export type QCmsMercerQueryResponse = {|
  +cms: ?{|
    +mercer: ?{|
      +mercerUrl: ?string
    |}
  |}
|};
export type QCmsMercerQuery = {|
  variables: QCmsMercerQueryVariables,
  response: QCmsMercerQueryResponse,
|};
*/


/*
query QCmsMercerQuery {
  cms {
    mercer {
      mercerUrl
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsMercer",
        "kind": "LinkedField",
        "name": "mercer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mercerUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsMercerQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QCmsMercerQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ac56581fd249abb738a0430541bff169",
    "id": null,
    "metadata": {},
    "name": "QCmsMercerQuery",
    "operationKind": "query",
    "text": "query QCmsMercerQuery {\n  cms {\n    mercer {\n      mercerUrl\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4611772e1985aba605d79330299b4c5a';

module.exports = node;
