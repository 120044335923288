import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

// Special id for attachments of type "Autre" (mirrored on server side)
const ATTACHMENT_ID_OTHER = 'AUT';

// return true iff the discussion has at least 1 message with an attachment
const hasAnyAttachment = discussion => {
  const allMessagesData = R.pathOr([], ['allMessages', 'data'])(discussion);
  const hasAttachment = R.propSatisfies(RA.isNotNilOrEmpty, 'attachments');
  return R.any(hasAttachment)(allMessagesData);
};

export {
  ATTACHMENT_ID_OTHER,
  hasAnyAttachment
};
