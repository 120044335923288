/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'subscriberId',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "N° d'adhérent",
    placeholder: "N° d'adhérent",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'gcuIsAccept',
    required: false,
    input: 'switch',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;