import './index.less';
import React from 'react';
import PropTypes from 'prop-types';
import Footer from './Footer';
import Top from './Top';
import * as RA from 'ramda-adjunct';
import classNames from 'classnames';

const Layout = ({
  top,
  withTunnelBackground,
  children,
  doShowMenu,
  footer
}) => {
  // inject prop `doShowMenu` into our `Top` component
  const enhancedTop = RA.isNotNil(top)
    ? React.cloneElement(top, { doShowMenu: doShowMenu }) : null;
  const wrapperClass = classNames('f-layout-wrapper', {
    'with-menu': doShowMenu
  });

  return (
    <div className='f-layout'>
      { RA.isNotNil(top) && (
        <div className='f-layout-top'>{enhancedTop}</div>
      )}
      <div className={wrapperClass}>
        <div className={classNames('f-layout-body', {
          'f-layout-body-with-tunnel-bg': withTunnelBackground
        })}>
          {children}
        </div>
        { RA.isNotNil(footer) && footer }
      </div>
    </div>
  );
};

Layout.propTypes = {
  withTunnelBackground: PropTypes.bool.isRequired,
  doShowMenu: PropTypes.bool.isRequired,
  footer: PropTypes.element,
  top: PropTypes.element
};

Layout.defaultProps = {
  withTunnelBackground: false,
  doShowMenu: false,
  footer: undefined
};

export default Layout;

export {
  Footer,
  Top
};
