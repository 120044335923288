import React from 'react';
import { QAdherentCivilStatusQuery } from '@@queries/QAdherentCivilStatus';
import { QAdherentContactQuery } from '@@queries/QAdherentContact';
import { QAdherentProfessionalInformationQuery } from '@@queries/QAdherentProfessionalInformation';
import { QAdherentContracts } from '@@queries/QAdherentContracts';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useAdherent = (id, data) => {
  const environment = useRelayEnvironment();
  const [adherent, setAdherent] = React.useState({
    civilStatus: null,
    contact: null
  });

  const idRef = React.useRef();
  const dataRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(idRef.current, id) && R.equals(dataRef.current, data)) return;
    idRef.current = id;
    dataRef.current = data;
    if (R.contains('civilStatus', data)) getCivilStatus({ id });
    if (R.contains('contact', data)) getContact({ id });
    if (R.contains('professionalInformation', data)) getProfessionalInformation({ id });
    if (R.contains('contracts', data)) getContracts({ id });
  }, [id, data]);

  const getCivilStatus = async ({ id }) => {
    const { adherent } = await fetchQuery(environment, QAdherentCivilStatusQuery, { id }).toPromise();
    setAdherent(R.compose(
      R.assoc('civilStatus', R.propOr(null, 'civilStatus', adherent))
    ));
  };

  const getContact = async ({ id }) => {
    const { adherent } = await fetchQuery(environment, QAdherentContactQuery, { id }).toPromise();
    setAdherent(R.compose(
      R.assoc('contact', R.propOr(null, 'contact', adherent))
    ));
  };

  const getProfessionalInformation = async ({ id }) => {
    const { adherent } = await fetchQuery(environment, QAdherentProfessionalInformationQuery, { id }).toPromise();
    setAdherent(R.compose(
      R.assoc('professionalInformation', R.propOr(null, 'professionalInformation', adherent))
    ));
  };

  const getContracts = async ({ id }) => {
    const { adherent } = await fetchQuery(environment, QAdherentContracts, { id }).toPromise();
    setAdherent(R.compose(
      R.assoc('contracts', R.propOr(null, 'contracts', adherent))
    ));
  };

  return {
    adherent
  };
};

export default useAdherent;
