/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenderEnum = "Dr" | "F" | "FF" | "M" | "%future added value";
export type AdherentPersonalInformationInput = {|
  birthDate: string,
  civility: GenderEnum,
  firstName: string,
  lastName: string,
  maidenName?: ?string,
  nic: string,
|};
export type AdherentPersonalInformationMutationVariables = {|
  input: AdherentPersonalInformationInput
|};
export type AdherentPersonalInformationMutationResponse = {|
  +adherentPersonalInformation: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AdherentPersonalInformationMutation = {|
  variables: AdherentPersonalInformationMutationVariables,
  response: AdherentPersonalInformationMutationResponse,
|};
*/


/*
mutation AdherentPersonalInformationMutation(
  $input: AdherentPersonalInformationInput!
) {
  adherentPersonalInformation(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdherentPersonalInformationMutationResponse",
    "kind": "LinkedField",
    "name": "adherentPersonalInformation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdherentPersonalInformationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdherentPersonalInformationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9dc8910a73b33df40c13451f8a55224f",
    "id": null,
    "metadata": {},
    "name": "AdherentPersonalInformationMutation",
    "operationKind": "mutation",
    "text": "mutation AdherentPersonalInformationMutation(\n  $input: AdherentPersonalInformationInput!\n) {\n  adherentPersonalInformation(input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'da9349c06dff2e574ddfd93400055f19';

module.exports = node;
