import React from 'react';
import PropTypes from 'prop-types';

const ServiceBlock = ({ title, children }) => (
  <div className={'f-service-block'}>
    <div className={'f-welcome-block-title'}>
      {title}
    </div>
    {children}
  </div>
);

ServiceBlock.propTypes = {
  title: PropTypes.string.isRequired
};

export default ServiceBlock;
