import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const options = [
  { value: 2009, label: 2009 },
  { value: 2010, label: 2010 },
  { value: 2011, label: 2011 },
  { value: 2012, label: 2012 },
  { value: 2013, label: 2013 },
  { value: 2014, label: 2014 },
  { value: 2015, label: 2015 },
  { value: 2016, label: 2016 },
  { value: 2017, label: 2017 },
  { value: 2018, label: 2018 },
  { value: 2019, label: 2019 },
  { value: 2020, label: 2020 },
  { value: 2021, label: 2021 },
  { value: 2022, label: 2022 },
  { value: 2023, label: 2023 },
  { value: 2024, label: 2024 },
  { value: 2025, label: 2025 }
];

const SelectDocuments = ({
  title,
  type
}) => {
  const [year, setYear] = React.useState({ value: 2021, label: 2021 });

  const onChangeYear = (v) => {
    setYear(v);
  };
  return (
    <div className="f-card f-block">
      <h3>{title}</h3>
      <div style={{ width: '200px' }}>
        <Select
          value={year}
          options={options}
          onChange={onChangeYear}
        />
      </div>
      <div className="mt-4 flex ">
        <button disabled="true" className="f-button-sm f-button-coral mr-4" to={`/association/documents/${type}/${year.value}`}>
          Télécharger
        </button>
        <Link className="f-button-sm f-button-coral" to={`/association/documents/${type}/${year.value}`}>Afficher</Link>
      </div>
    </div>
  );
};

SelectDocuments.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string
};

export default SelectDocuments;
