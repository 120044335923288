import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Link } from 'react-router-dom';
import ForgotAdherentNumberModal from './ForgotAdherentNumberModal';
import { InformationBlock } from '@@components';
import { imageTiersPayant } from '@@images';
import TutoFirstConnectionModal from './TutoFirstConnectionModal';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';
import { isNotNil } from 'ramda-adjunct';

const R = require('ramda');

const Step1 = ({ id, onDone }) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('onBoardAdherent');

  const [openModal, setOpenModal] = useState({
    tutoModal: false,
    formModal: false
  });
  const [gcuIsAccept, setGcuIsAccept] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (fetching.isDone()) {
      onDone({
        id,
        payload: fetching.getPayload()
      });
    }
    if (fetching.getError()) {
      setErrorMessage(fetching.getError());
    }
  }, [fetching.isDone(), fetching.getPayload(), fetching.getError()]);

  const isDisabled = () => {
    return !R.equals([gcuIsAccept, fetching.isFetching()], [true, false]);
  };

  const handleCloseModal = (closeModal) => () => {
    const modalStatus = R.mergeLeft(closeModal, openModal);
    setOpenModal(modalStatus);
  };

  const sendGaAndHandleCloseModal = (ga, closeModal) => () => {
    sendGAEventIfDefined(ga);
    if (isNotNil(closeModal)) {
      handleCloseModal(closeModal)();
    }
  };

  const forgotButton = (
    <div className="flex justify-center">
      <button
        className="text-center f-link-turquoise"
        onClick={sendGaAndHandleCloseModal(configGA.ONBOARDING_FORGOTTEN_ID, { formModal: true })}
        type="button"
      >
        {'N° d\'adhérent oublié'}
      </button>
    </div>
  );

  const forgotModal = <ForgotAdherentNumberModal
    show={openModal.formModal}
    onDisable={handleCloseModal({ formModal: false })}
  />;

  const agreeCguLabel = (
    <div className={'f-connect-step1-cgu'}>
      {'Je certifie avoir pris connaissance des '}
      <Link className="f-link-turquoise" to="/cgu">
        {'conditions générales d\'utilisation'}
      </Link>
      {' et les accepte.'}
    </div>
  );

  const formsBlock = (
    <Forms
      formsData={formsData}
      alignedLabels={[null, agreeCguLabel]}
      columns={1}
      onChange={(val) => {
        fetching.reset();
        setGcuIsAccept(val.gcuIsAccept);
      }}
      onSubmit={onFormsSubmit}
      defaultValues={{
        gcuIsAccept
      }}
      submitSection={() => (
        <div className={'mt-2'}>
          {/* TODO the agreement text should not be below the checkbox,
           but directly on its right. Thus it should be inside the form,
            as a label or something. Same goes for the error message, it
             feels like it should be between the pwd field and the checkbox
              (for error msg, there is no spec on Zeplin as of 05/08/21) */}
          {errorMessage && <div className="text-danger">{errorMessage}</div>}

          <div className="f-connect-step1-block">
            <div className={'flex items-center'}>
              <button
                className="f-button f-button-coral"
                disabled={isDisabled()}
                type="submit"
                onClick={sendGaAndHandleCloseModal(configGA.ONBOARDING_CONTINUE)}
              >
                {'Continuer'}
              </button>
              <button
                className="text-popup-text text-turquoise font-bold text-left ml-4"
                type="button"
                onClick={sendGaAndHandleCloseModal(configGA.ONBOARDING_TUTO, { tutoModal: true })}
              >
                {'En savoir plus sur la création de votre compte'}
              </button>
            </div>
            <p>
              {'J\'ai déjà un compte'},&nbsp;
              <Link className="f-link-turquoise" to="/account/signin/signin">
                {'je me connecte.'}
              </Link>
            </p>
          </div>
        </div>
      )}
      topRightButtons={[forgotButton]}/>
  );
  const infoBlock = (
    <InformationBlock
      title="Où trouver mon numéro d'adhérent ?"
      img={imageTiersPayant}>
      {'Votre numéro d\'adhérent figure sur votre carte de tiers payant MERCER, votre courrier de connexion, votre échéancier annuel, et sur les différentes correspondances de l\'APPA.'}
    </InformationBlock>
  );

  return (
    <>
      <div className={'f-connect-container'}>
        <div className={'f-connect-forms-block'}>
          <div className={'f-connect-title'}>
            {'Créez votre espace pour gérer vos contrats santé prévoyance'}
          </div>
          {formsBlock}
        </div>
        {infoBlock}
      </div>
      {forgotModal}
      <TutoFirstConnectionModal
        show={openModal.tutoModal}
        onDisabled={handleCloseModal({ tutoModal: false })}
      />
    </>
  );
};

Step1.propTypes = {
  id: PropTypes.string,
  onDone: PropTypes.func
};

export default Step1;
