import React from 'react';
import classNames from 'classnames';

const TextArea = ({ inputRef, name, rows, onChange, value, hasError, placeholder }) => (
  <textarea
    rows={rows}
    placeholder={placeholder}
    className={classNames(
      {
        'f-textarea-error': hasError,
        'f-textarea': !hasError
      })}
    name={name}
    onChange={onChange}
    value={value}
  />
);

TextArea.defaultProps = {
  rows: 6
};

export default TextArea;
