import React from 'react';
import { QAdherentContracts } from '@@queries/QAdherentContracts';

const R = require('ramda');
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useContracts = (id) => {
  const environment = useRelayEnvironment();
  const [contracts, setContracts] = React.useState([]);

  const idRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(idRef.current, id)) return;
    idRef.current = id;
    getContracts(id);
  }, [id]);

  const getContracts = async (id) => {
    const { adherent } = await fetchQuery(environment, QAdherentContracts, {
      id
    }).toPromise();
    setContracts(R.propOr([], 'contracts', adherent));
  };

  return {
    contracts
  };
};

export default useContracts;
