/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QAdherentFirstConnectionStatusQueryVariables = {|
  subscriberId: string
|};
export type QAdherentFirstConnectionStatusQueryResponse = {|
  +getAdherentFirstConnectionStatus: ?boolean
|};
export type QAdherentFirstConnectionStatusQuery = {|
  variables: QAdherentFirstConnectionStatusQueryVariables,
  response: QAdherentFirstConnectionStatusQueryResponse,
|};
*/


/*
query QAdherentFirstConnectionStatusQuery(
  $subscriberId: ID!
) {
  getAdherentFirstConnectionStatus(subscriberId: $subscriberId)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriberId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "subscriberId",
        "variableName": "subscriberId"
      }
    ],
    "kind": "ScalarField",
    "name": "getAdherentFirstConnectionStatus",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentFirstConnectionStatusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAdherentFirstConnectionStatusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "84877f02732dfe829f065bceafbfd52e",
    "id": null,
    "metadata": {},
    "name": "QAdherentFirstConnectionStatusQuery",
    "operationKind": "query",
    "text": "query QAdherentFirstConnectionStatusQuery(\n  $subscriberId: ID!\n) {\n  getAdherentFirstConnectionStatus(subscriberId: $subscriberId)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '748f264abe5d37e7a45f18c72b983c3d';

module.exports = node;
