/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractTypologyEnum = "PHHU" | "interne" | "liberal" | "retraite" | "%future added value";
export type HealthOptionEnum = "famille" | "isole" | "%future added value";
export type PeriodicityEnum = "Annuel" | "Hebdomadaire" | "Mensuel" | "Quotidien" | "Semestriel" | "Trimestriel" | "%future added value";
export type PricingOptionValueTypeEnum = "Boolean" | "Decimal" | "Int" | "%future added value";
export type ContractFilterInput = {|
  category: string,
  contractId: string,
|};
export type QAdherentContractDetailsQueryVariables = {|
  id: string,
  filter?: ?ContractFilterInput,
|};
export type QAdherentContractDetailsQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +contract: ?{|
      +id: string,
      +startDate: ?string,
      +endDate: ?string,
      +contract: ?string,
      +category: ?string,
      +directDebit: ?boolean,
      +periodicity: ?PeriodicityEnum,
      +activityRate: ?number,
      +closurePattern: ?string,
      +SEPAMandate: ?{|
        +RUM: ?string,
        +signatureDate: ?string,
        +revocationDate: ?string,
        +account: ?{|
          +startDate: ?string,
          +endDate: ?string,
          +IBAN: ?string,
          +BIC: ?string,
          +holder: ?string,
        |},
      |},
      +pricingOptions: ?$ReadOnlyArray<?{|
        +startDate: ?string,
        +endDate: ?string,
        +type: ?string,
        +index: ?string,
        +valueType: ?PricingOptionValueTypeEnum,
        +value: ?string,
      |}>,
      +detail: ?{|
        +label: ?string,
        +typology: ?ContractTypologyEnum,
        +function: ?string,
        +activity: ?string,
        +health: ?{|
          +option: ?HealthOptionEnum,
          +complementaryText: ?string,
          +guarantee: ?$ReadOnlyArray<?{|
            +family: ?string,
            +label: ?string,
            +value: ?string,
          |}>,
        |},
        +pension: ?{|
          +complementaryText: ?string,
          +guarantee: ?$ReadOnlyArray<?{|
            +family: ?string,
            +label: ?string,
            +value: ?string,
          |}>,
        |},
        +assistance: ?{|
          +complementaryText: ?string,
          +guarantee: ?$ReadOnlyArray<?{|
            +family: ?string,
            +label: ?string,
            +value: ?string,
          |}>,
        |},
      |},
    |},
  |}
|};
export type QAdherentContractDetailsQuery = {|
  variables: QAdherentContractDetailsQueryVariables,
  response: QAdherentContractDetailsQueryResponse,
|};
*/


/*
query QAdherentContractDetailsQuery(
  $id: ID!
  $filter: ContractFilterInput
) {
  adherent(id: $id) {
    id
    contract(filter: $filter) {
      id
      startDate
      endDate
      contract
      category
      directDebit
      periodicity
      activityRate
      closurePattern
      SEPAMandate {
        RUM
        signatureDate
        revocationDate
        account {
          startDate
          endDate
          IBAN
          BIC
          holder
        }
      }
      pricingOptions {
        startDate
        endDate
        type
        index
        valueType
        value
      }
      detail {
        label
        typology
        function
        activity
        health {
          option
          complementaryText
          guarantee {
            family
            label
            value
          }
        }
        pension {
          complementaryText
          guarantee {
            family
            label
            value
          }
        }
        assistance {
          complementaryText
          guarantee {
            family
            label
            value
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contract",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directDebit",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodicity",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityRate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closurePattern",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "SEPAMandate",
  "kind": "LinkedField",
  "name": "SEPAMandate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "RUM",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signatureDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revocationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SEPAMandateAccount",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "IBAN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "BIC",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "holder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "PricingOption",
  "kind": "LinkedField",
  "name": "pricingOptions",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "index",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueType",
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typology",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "function",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activity",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "complementaryText",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractGuarantee",
  "kind": "LinkedField",
  "name": "guarantee",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    },
    (v16/*: any*/),
    (v14/*: any*/)
  ],
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractHealth",
  "kind": "LinkedField",
  "name": "health",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "option",
      "storageKey": null
    },
    (v20/*: any*/),
    (v21/*: any*/)
  ],
  "storageKey": null
},
v23 = [
  (v20/*: any*/),
  (v21/*: any*/)
],
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractPension",
  "kind": "LinkedField",
  "name": "pension",
  "plural": false,
  "selections": (v23/*: any*/),
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractAssistance",
  "kind": "LinkedField",
  "name": "assistance",
  "plural": false,
  "selections": (v23/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentContractDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Adherent",
        "kind": "LinkedField",
        "name": "adherent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdherentContrat",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contrat",
                "kind": "LinkedField",
                "name": "detail",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v22/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QAdherentContractDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Adherent",
        "kind": "LinkedField",
        "name": "adherent",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "AdherentContrat",
            "kind": "LinkedField",
            "name": "contract",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contrat",
                "kind": "LinkedField",
                "name": "detail",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v22/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39487c982390652a07110760bf26c635",
    "id": null,
    "metadata": {},
    "name": "QAdherentContractDetailsQuery",
    "operationKind": "query",
    "text": "query QAdherentContractDetailsQuery(\n  $id: ID!\n  $filter: ContractFilterInput\n) {\n  adherent(id: $id) {\n    id\n    contract(filter: $filter) {\n      id\n      startDate\n      endDate\n      contract\n      category\n      directDebit\n      periodicity\n      activityRate\n      closurePattern\n      SEPAMandate {\n        RUM\n        signatureDate\n        revocationDate\n        account {\n          startDate\n          endDate\n          IBAN\n          BIC\n          holder\n        }\n      }\n      pricingOptions {\n        startDate\n        endDate\n        type\n        index\n        valueType\n        value\n      }\n      detail {\n        label\n        typology\n        function\n        activity\n        health {\n          option\n          complementaryText\n          guarantee {\n            family\n            label\n            value\n          }\n        }\n        pension {\n          complementaryText\n          guarantee {\n            family\n            label\n            value\n          }\n        }\n        assistance {\n          complementaryText\n          guarantee {\n            family\n            label\n            value\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f35da54a928bb72b0a21a2e41edcb6f';

module.exports = node;
