import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QCmsDocumentsQuery = graphql`
  query QCmsDocumentsQuery($documentsFilter: CmsDocumentsFilterInput) {
    cms {
      documents(documentsFilter: $documentsFilter) {
        title
        url
        date
      }
    }
  }
`;

export {
  QCmsDocumentsQuery
};
