import {
  graphql,
  requestSubscription
} from 'react-relay';

const R = require('ramda');
const subscription = graphql`
  subscription DiscussionCloseNotificationSubscription($customerId: ID!, $discussionId: ID!) {
    discussionCloseNotification(customerId: $customerId, discussionId: $discussionId) {
      id
      status
    }
  }
`;

export default (environment, args, notifier) => {
  requestSubscription(
    environment,
    {
      subscription,
      variables: args,
      updater: (store, { discussionCloseNotification }) => {
        if (R.is(Function, notifier)) notifier(discussionCloseNotification);
      },
      onError: error => {
        console.error('SUBSCRIPTION ERROR:', error);
      }
    }
  );
};
