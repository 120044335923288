import React from 'react';
import {
  Field
} from '@@components';
import { useForm } from 'react-hook-form';
import inputComponents from './inputs';
import PropTypes from 'prop-types';
import ErrorMessage from '@@components/forms/inputs/helpers/ErrorMessage';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import classNames from 'classnames';

// Prop topRightButtons contains a list of components (one for each form
// field) to be appended to their respective labels
const Forms = ({
  formsData,
  defaultValues,
  hiddenFields,
  onChange,
  onSubmit,
  options,
  submitSection,
  columns,
  resetOnSubmit,
  topRightButtons,
  alignedLabels
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
    errors,
    formState: { isSubmitSuccessful }
  } = useForm({ defaultValues });

  const [visible, setVisible] = React.useState({});
  const valuesRef = React.useRef(null);
  const formValues = watch();

  React.useEffect(() => {
    const updateVisible = async (fields, values) => {
      const response = await Promise.all(fields.map(async field => {
        if (R.is(Function, field.handlers.visible)) {
          let value = field.handlers.visible(values);
          if (R.is(Promise, value)) value = await value;
          return { [field.name]: value };
        }
        return { [field.name]: true };
      }));
      setVisible(R.mergeAll(response));
    };

    // * Check if the values changed
    if (R.equals(valuesRef.current, formValues)) return;

    // * Update form visibility
    valuesRef.current = formValues;
    updateVisible(formsData, valuesRef.current);
    if (R.is(Function, onChange)) onChange(valuesRef.current);
  }, [formValues]);

  const hiddenFieldsRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(hiddenFieldsRef.current, hiddenFields)) return;
    hiddenFieldsRef.current = hiddenFields;
    R.forEachObjIndexed((v, k) => setValue(k, v), hiddenFields);
  }, [hiddenFields]);

  React.useEffect(() => {
    if (resetOnSubmit && isSubmitSuccessful) {
      reset(R.clone(defaultValues));
    }
  }, [isSubmitSuccessful, reset]);

  const isFieldIgnored = field => !visible[field.name];
  const isFieldHidden = field => R.or(
    R.equals(field.input, 'hidden'),
    R.has(field.name, hiddenFields));
  const getInputAndErrorsJSX = ({ field }) => {
    const placeholder = R.equals(field.placeholder, '__UNDEFINED__')
      ? field.label : field.placeholder;

    if (!R.has(field.input, inputComponents)) return null;

    const inputJSX = React.createElement(
      inputComponents[field.input],
      {
        getValues,
        control,
        register,
        field: { ...field, placeholder },
        options,
        errors
      }
    );

    const errorsJSX = <ErrorMessage field={field} errors={errors} />;
    return {
      inputJSX: inputJSX,
      errorsJSX: errorsJSX
    };
  };

  const fieldAndIndexToJSX = (field, index) => {
    if (isFieldIgnored(field)) {
      return null;
    }
    if (isFieldHidden(field)) {
      return (
        <input key={field.name} name={field.name} type='hidden'
          ref={register} />
      );
    }
    const topBtn = topRightButtons[index];
    const alignedLabel = alignedLabels[index];
    const { inputJSX, errorsJSX } = getInputAndErrorsJSX({ field });
    const label = R.when(
      R.anyPass([
        R.equals('__UNDEFINED__'),
        R.equals('Justificatif de domicile')
      ]),
      R.always(''),
      field.label);
    return (
      <Field key={field.name}
        label={label}
        topRightButton={topBtn}
        alignedLabel={alignedLabel}
        errorsJSX={errorsJSX}
        className={classNames({ 'row-span-2': R.equals(field.name, 'comment') })}
      >
        { inputJSX }
      </Field>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`f-form-layout md:grid-cols-${columns}`}>
        {RA.mapIndexed(fieldAndIndexToJSX, formsData)}
      </div>
      {submitSection({ reset, setValue, getValues })}
    </form>
  );
};

export default Forms;

Forms.propTypes = {
  formsData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string
    })
  ),
  defaultValues: PropTypes.object,
  hiddenFields: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.object,
  submitSection: PropTypes.func.isRequired,
  columns: PropTypes.number,
  resetOnSubmit: PropTypes.bool,
  topRightButtons: PropTypes.arrayOf(PropTypes.element).isRequired,
  alignedLabels: PropTypes.arrayOf(PropTypes.element).isRequired
};

Forms.defaultProps = {
  columns: 2,
  resetOnSubmit: false,
  topRightButtons: [],
  alignedLabels: []
};
