import React from 'react';
import PropTypes from 'prop-types';
import * as RA from 'ramda-adjunct';
import * as R from 'ramda';

const CotisationContent = ({ text, holder, iban }) => (
  <ul>
    <li>{text}</li>
    {RA.isNotNilOrEmpty(holder) && (
      <li>
        {'Titulaire du compte : '} <span className={'font-bold'}>{R.toUpper(holder)}</span>
      </li>
    )}
    {RA.isNotNilOrEmpty(iban) && (
      <li>
        {'Iban : '} <span className={'font-bold'}>{iban}</span>
      </li>
    )}
  </ul>
);

CotisationContent.propTypes = {
  text: PropTypes.string.isRequired,
  holder: PropTypes.string,
  iban: PropTypes.string
};

export default CotisationContent;
