import React from 'react';
import classNames from 'classnames';
import { cornerUpLeftYellow, cornerDownRightTurquoise } from '@@images';
import PropTypes from 'prop-types';
const R = require('ramda');

const useSections = () => {
  const [sections, setSections] = React.useState([]);

  const sectionsRef = React.useRef(sections);
  React.useEffect(() => {
    if (R.equals(sections, sectionsRef.current)) return;
    sectionsRef.current = sections;
    sections.forEach(({ visible, ref }) => {
      ref.hidden = !visible;
    });
  }, [sections]);

  const onSectionFilter = (id) => {
    const isFilterAll = () => {
      let count = 0;
      sections.forEach(section => {
        if (section.visible) count++;
      });
      return sections.length === count;
    };

    if (id === 'all' && !isFilterAll()) {
      setSections(R.map(R.over(R.lensProp('visible'), R.always(true))));
    } else if (id !== 'all') {
      const index = R.findIndex(R.propEq('id', id), sections);
      if (isFilterAll()) {
        setSections(R.map(section => {
          return R.over(R.lensProp('visible'), R.always(section.index === index), section);
        }));
      } else {
        setSections(R.over(R.lensIndex(index), R.over(R.lensProp('visible'), R.not)));
      }
    }
  };

  const register = (id, title) => (ref) => {
    if (R.isNil(ref)) return;
    const index = R.findIndex(R.propEq('id', id), sections);
    if (index !== -1) return;
    ref.hidden = false;
    setSections(sections => [...sections, { id, title, ref, key: 0, visible: true, index: R.length(sections) }]);
  };

  const sectionTitle = (id) => {
    const index = R.findIndex(R.propEq('id', id), sections);
    if (index === -1) return '';
    return sections[index].title;
  };

  return {
    register,
    onSectionFilter,
    sectionTitle,
    sections
  };
};

const Sections = ({ sections, onSectionFilter, children }) => {
  const isFilterAll = () => {
    let count = 0;
    sections.forEach(section => {
      if (section.visible) count++;
    });
    return sections.length === count;
  };

  const sectionTagToJSX = section => {
    const { visible, id, title } = section;
    const className = classNames('f-filter-section',
      { 'f-filter-section-selected': visible && !isFilterAll() });
    return (
      <div key={id} className={className} onClick={() => onSectionFilter(id)}>
        {title}
      </div>
    );
  };

  const filterComponent = (
    <div className="f-filter"
      style={{
        backgroundImage: `url(${cornerUpLeftYellow}), url(${cornerDownRightTurquoise})`,
        backgroundPosition: 'top left -1px, bottom right -1px',
        backgroundRepeat: 'no-repeat, no-repeat'
      }}
    >
      <div className="f-filter-display">{'Afficher'}</div>
      <div
        className={classNames('f-filter-section',
          { 'f-filter-section-selected': isFilterAll() })}
        onClick={() => onSectionFilter('all')}
      >
        {'Tous'}
      </div>
      { R.map(sectionTagToJSX, sections) }
    </div>
  );

  return (
    <>
      { filterComponent }
      <div className="f-document-section-container">
        { children }
      </div>
    </>
  );
};

const Section = React.forwardRef(({ title, children }, ref) => (
  <div className="f-document-section" ref={ref}>
    <div className="f-document-section-title">{title}</div>
    <div className="f-document-section-body">
      {children}
    </div>
  </div>
));

Sections.propTypes = {
  // TODO specify shape (array of Section objects)
  sections: PropTypes.array.isRequired,
  onSectionFilter: PropTypes.func.isRequired
};

export default Sections;

export {
  useSections,
  Section
};
