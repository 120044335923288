import React from 'react';
import DatePicker from '../../input/DatePicker';
import { Controller } from 'react-hook-form';
import ErrorMessage from './helpers/ErrorMessage';
const R = require('ramda');

const Input = ({ control, register, field, errors }) => {
  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={null}
      rules={{ required: field.required }}
      render={({ onChange, value }) => {
        return (
          <>
            <DatePicker
              value={value}
              placeholder={field.placeholder}
              format={R.when(R.isNil, R.always('dd/MM/yyyy'), field.format)}
              onChange={onChange}
              hasError={R.keys(errors).includes(field.name)}
            />
          </>
        );
      }}
    />
  );
};

export default Input;
