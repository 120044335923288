import React from 'react';

const Blocks = ({
  children,
  nbCols
}) => (
  <div className={`f-blocks-${nbCols}`}>
    {children}
  </div>
);

export default Blocks;
