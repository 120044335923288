import { graphql } from 'react-relay';

const QDiscussionSignRequestQuery = graphql`
  query QDiscussionSignRequestQuery($discussionId: ID!) {
    discussionSignRequest(discussionId: $discussionId) {      
      id
      youSign {
        memberId
        signatureUi
      }
      youSignUrl
    }
  }
`;

export {
  QDiscussionSignRequestQuery
};
