import React, { useEffect, useRef } from 'react';
import { useCms } from '@@hooks';
import { Page } from '@@components';
import ReactMarkdown from 'react-markdown';

import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useHistory } from 'react-router-dom';
import { linkCatcher } from '@@root/utils/linkCatcher';

const DonneesPersonnelles = () => {
  const { page } = useCms(['page'], { pageId: 'donnees_personnelles' });
  const history = useHistory();
  const textRef = useRef(null);

  useEffect(() => {
    const redirect = (href) => history.push(href);
    const onClick = linkCatcher(redirect);
    if (textRef && textRef.current) {
      textRef.current.addEventListener('click', onClick);
    }
    return () => {
      if (textRef && textRef.current) {
        textRef.current.removeEventListener('click', onClick);
      }
    };
  }, []);

  const textBlock = RA.isNotNil(page) && (
    <ReactMarkdown className={'f-react-markdown'}>
      {R.propOr('', 'content', page)}
    </ReactMarkdown>
  );

  return (
    <Page pageTitle={'Données personnelles'}>
      <div className={'f-donnees-personnelles-body'}>
        <div ref={textRef} className={'f-donnees-personnelles-text'}>
          {textBlock}
        </div>
      </div>
    </Page>
  );
};

export default DonneesPersonnelles;
