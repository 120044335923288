import React from 'react';
import PropTypes from 'prop-types';
import { always, cond, equals, isNil, propOr, T } from 'ramda';
import Modal from '@@components/modale';
import { useCms } from '@@hooks';
import MercerInfo from './MercerInfo';
import EuropAssistanceInfo from './EuropAssistanceInfo';

const ExternalServicesModal = ({
  show,
  setDisabled,
  service
}) => {
  const { contact, mercer } = useCms(['contact', 'mercer']);
  if (isNil(contact) || isNil(mercer)) return null;

  const mercerPhoneNumber = propOr('', 'mercerPhoneNumber', contact);
  const mercerUrl = propOr('#', 'mercerUrl', mercer);
  const europAssistancePhoneNumberMetropole = propOr('', 'europAssistanceMetropolePhoneNumber', contact);
  const europAssistancePhoneNumberDROM = propOr('', 'europAssistanceDROMPhoneNumber', contact);

  const mercerTemplate = <MercerInfo mercerPhoneNumber={mercerPhoneNumber} href={mercerUrl}/>;
  const europAssistanceTemplate = <EuropAssistanceInfo
    europAssistancePhoneNumberMetropole={europAssistancePhoneNumberMetropole}
    europAssistancePhoneNumberDROM={europAssistancePhoneNumberDROM}
  />;

  const serviceToJsx = cond([
    [equals(ExternalServices.MERCER), always(mercerTemplate)],
    [equals(ExternalServices.EUROP_ASSISTANCE), always(europAssistanceTemplate)],
    [T, always('')]
  ]);

  return (
    <Modal show={show} onDisable={setDisabled}
      className={'f-window-messaging-ext-services'}
    >
      <div className='f-messaging-ext-services-modal-container'>
        {serviceToJsx(service)}
      </div>
    </Modal>
  );
};

ExternalServicesModal.propTypes = {
  show: PropTypes.bool,
  setDisabled: PropTypes.func,
  service: PropTypes.string
};

export const ExternalServices = {
  MERCER: 'mercer',
  EUROP_ASSISTANCE: 'europAssistance'
};

export default ExternalServicesModal;
