import React from 'react';
import PropTypes from 'prop-types';
import MessageList from './MessageList';
import QDiscussion from '@@queries/QDiscussion';
import { DiscussionCloseNotificationSubscription } from '@@graphql/subscriptions';
import { useRelayEnvironment } from 'react-relay';
import { useCms, useKeycloakSession } from '@@hooks';
import ReplyTo from './ReplyTo';
import { discussionStatus } from '@@enum';
import { BreadCrumbButtons } from '@@components/BreadCrumb';
import { Redirect } from 'react-router-dom';
import * as RA from 'ramda-adjunct';

const R = require('ramda');

const DiscussionFrame = ({
  discussionId,
  discussionTitle
}) => {
  const { user } = useKeycloakSession();

  // Signature to display in collaborators messages
  const { messagingSignature } = useCms(['messagingSignature']);
  const signature = R.propOr('', 'signature', messagingSignature);

  const getAllMessages = R.prop('allMessages');
  const getRecipients = R.prop('recipients');
  const getMessageTitle = R.prop('title');
  const getMessageStatus = R.prop('status');
  const getPendingSignRequest = R.prop('pendingSignRequest');
  const environment = useRelayEnvironment();

  React.useEffect(() => {
    if (R.isNil(user.customerId) || R.isNil(discussionId)) return;
    const subscription = DiscussionCloseNotificationSubscription(environment, {
      customerId: user.customerId,
      discussionId: discussionId
    });
    return () => {
      if (!R.isNil(subscription)) subscription.dispose();
    };
  }, [discussionId]);

  if (R.isNil(discussionId)) return null;

  return (
    <QDiscussion args={{ id: discussionId }}>
      {({ getDiscussion }) => {
        if (R.isNil(getDiscussion)) {
          return <Redirect to={'/messagerie'}/>;
        }
        const actionId = getMessageTitle(getDiscussion);
        const lastMessage = R.head(getAllMessages(getDiscussion).data);
        const lastMessageSenderId = R.propOr('', 'sender', lastMessage);
        const collaboratorName = R.propOr('', 'collaboratorName', lastMessage);
        const pendingSignRequest = getPendingSignRequest(getDiscussion);

        const title = discussionTitle(actionId);

        if (RA.propNotEq('id', discussionId, getDiscussion)) {
          // Waiting for the newly selected discussion to load
          return (<></>);
        }
        return (
          <>
            <div className={'f-mailbox-breadcrumb'}>
              <BreadCrumbButtons elements={[
                { name: 'Messagerie', link: '/messagerie' },
                { name: title }
              ]}/>
            </div>
            <div className={'f-messaging-discussion-title'}>
              {title}
            </div>
            <MessageList
              items={getAllMessages(getDiscussion).data}
              pagination={getAllMessages(getDiscussion).pagination}
              status={getMessageStatus(getDiscussion)}
              pendingSignRequest={pendingSignRequest}
              signature={signature}
            />
            {
              R.equals(getMessageStatus(getDiscussion), discussionStatus.OPENED) && (
                <ReplyTo
                  lastMessageSenderId={lastMessageSenderId}
                  collaboratorName={collaboratorName}
                  discussionId={discussionId}
                  title={getMessageTitle(getDiscussion)}
                  recipients={getRecipients(getDiscussion)}
                />
              )
            }
          </>
        );
      }}
    </QDiscussion>
  );
};

DiscussionFrame.propTypes = {
  discussionId: PropTypes.string,
  discussionTitle: PropTypes.func.isRequired
};

export default DiscussionFrame;
