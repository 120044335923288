import React from 'react';
import PropTypes from 'prop-types';
import {
  getGenericDocumentUrl,
  getLabel
} from '@@lib/contracts';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Block, Loading } from '@@components';
import ContractDetailsCoversTable from './ContractDetailsCoversTable';
import { iconPdf } from '@@images';

const withLoader = (loading, condition, component) => {
  if (loading) return <Loading/>;
  if (condition) return component;
  return null;
};

const ContractDetailsCovers = ({
  contract,
  hidePdfLinks,
  loading,
  allCovers,
  baseRefund,
  exampleRefund
}) => {
  const allCoversUrl = getGenericDocumentUrl({
    document: allCovers, download: false
  });
  const baseRefundUrl = getGenericDocumentUrl({
    document: baseRefund, download: false
  });
  const exampleRefundUrl = getGenericDocumentUrl({
    document: exampleRefund, download: false
  });

  const allCoversButton = withLoader(
    loading, RA.isNotNilOrEmpty(allCovers),
    <a className="f-button f-button-coral"
      href={allCoversUrl} target="_blank" rel="noreferrer"
    >
      {'Consulter toutes mes garanties'}
    </a>
  );

  const baseRefundLink = R.not(hidePdfLinks) && withLoader(
    loading, RA.isNotNilOrEmpty(baseRefund),
    <div className="f-contract-pdf-link">
      <img src={iconPdf} alt="pdf-logo"/>
      <a className="f-contract-pdf-link-text"
        href={baseRefundUrl} target="_blank" rel="noreferrer"
      >
        {'Connaître les bases de remboursement santé'}
      </a>
    </div>
  );

  const exampleRefundLink = R.not(hidePdfLinks) && withLoader(
    loading, RA.isNotNilOrEmpty(exampleRefund),
    <div className="f-contract-pdf-link">
      <img src={iconPdf} alt="pdf-logo"/>
      <a
        className="f-contract-pdf-link-text"
        href={exampleRefundUrl} target="_blank" rel="noreferrer"
      >
        {'Consulter des exemples de remboursement'}
      </a>
    </div>
  );

  return (
    <Block>
      <div className={'f-contract-primary-block'}>
        <div className={'f-contract-primary-title'}>
          {getLabel('guarantee')(contract)}
        </div>
        <ContractDetailsCoversTable contract={contract}/>
        <div className={'f-contract-primary-footer'}>
          {allCoversButton}
          {baseRefundLink}
          {exampleRefundLink}
        </div>
      </div>
    </Block>
  );
};

ContractDetailsCovers.propTypes = {
  contract: PropTypes.object,
  hidePdfLinks: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  allCovers: PropTypes.object,
  baseRefund: PropTypes.object,
  exampleRefund: PropTypes.object
};

export default ContractDetailsCovers;
