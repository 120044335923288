import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';
import { Controller } from 'react-hook-form';

const R = require('ramda');

const UserContactList = ({ field, errors, options, control }) => {
  const classes = R.keys(errors || {}).includes(field.name) ? 'f-input-red' : 'f-input';
  const $options = R.propOr([], field.source, options);

  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => (
        <div className={'flex flex-col'}>
          {R.map(({
            token,
            text
          }) => (
            <label key={token} className="m-1 p-2 border rounded-lg">
              <input className="mr-2" type="radio"
                onChange={() => onChange(token)} name={field.name}
              />
              {text}
            </label>
          ), $options)}
        </div>
      )}
    />
  );
};

export default UserContactList;
