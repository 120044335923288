import { Controller } from 'react-hook-form';
import { RibInput } from '@@components/input';

import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';

const R = require('ramda');

const Input = ({ register, field, control, errors }) => {
  const hasError = R.keys(errors).includes(field.name);
  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => <>
        <RibInput hasError={hasError} onChange={onChange} value={value} />
      </>}
    />
  );
};

export default Input;
