import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { sendGAEventIfDefined } from '@@lib/ga';

const R = require('ramda');
const RA = require('ramda-adjunct');

const getClassName = ({ link, location, isMobile, isInABox }) => {
  const isActive = R.propSatisfies(R.includes(link), 'pathname', location);
  const mobilePostfix = isMobile ? '-mobile' : '';
  const activePostfix = isActive ? ' active' : '';
  const boxPostfix = isInABox ? ' box' : ' logout';
  return `f-top-block${mobilePostfix}${activePostfix}${boxPostfix}`;
};

const buildImageJSX = ({ img, isInABox, isSingle }) => {
  const boxPostfix = isInABox ? '' : ' logout';
  const singlePostfix = isSingle ? ' single' : '';
  const className = `f-top-block-img${boxPostfix}${singlePostfix}`;
  return (
    <img className={className} src={img} alt={`image ${img}`}/>
  );
};

const buildTextJSX = ({ blockName, blockInfo, blockShortName, isMobile, isSingle }) => {
  const singlePostfix = isSingle ? ' single' : '';
  const className = `f-top-block-text${singlePostfix}`;
  const doAlignRow = isMobile && !isSingle;
  return (
    <div className={className}>
      {doAlignRow && (
        <p>{RA.isNotNil(blockShortName) ? blockShortName : blockName}</p>
      )}
      {!doAlignRow && (
        <div>
          <p>{blockName}</p>
          {RA.isNotNil(blockInfo) && <p>{blockInfo}</p>}
        </div>
      )}
    </div>
  );
};

/**
 * Menu Blocks located at the top right of the screen (desktop view) or at
 * the bottom of the dropdown (mobile view)
 *
 * @param link when specified, clicking on the block redirects there
 * @param onClick when specified, clicking on the block executes the
 *        provided callback instead of performing a redirection
 * @param img
 * @param blockName
 * @param blockInfo
 * @param blockShortName
 * @param location current route
 * @param isMobile true iff the screen is in mobile view
 * @param isInABox true iff a box surrounds the button (Logout has no box)
 * @param isSingle true iff there is no other button around
 * @param ga Google Analytics event's config
 */
const Block = ({
  link,
  onClick,
  img,
  blockName,
  blockInfo,
  blockShortName,
  location,
  isMobile,
  isInABox,
  isSingle,
  ga
}) => {
  const history = useHistory();
  const onBlockClick = R.cond([
    [R.propSatisfies(RA.isNotNil, 'onClick'), R.prop('onClick')],
    [R.propSatisfies(RA.isNotNil, 'link'), ({ link }) => () => history.push(link)],
    [R.T, R.always(RA.noop)]
  ])({ onClick, link });
  const handleOnClick = () => {
    sendGAEventIfDefined(ga);
    onBlockClick();
  };

  // className: f-top-block[-mobile] [active] [box]
  const className = getClassName({ link, location, isMobile, isInABox });
  const blockImage = buildImageJSX({ img, isInABox, isSingle });
  const blockText = buildTextJSX({ blockName, blockInfo, blockShortName, isMobile, isSingle });

  return (
    <div className={className} onClick={handleOnClick}>
      {blockImage}
      {blockText}
    </div>
  );
};

Block.propTypes = {
  blockInfo: PropTypes.string,
  blockName: PropTypes.string.isRequired,
  blockShortName: PropTypes.string,
  img: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isInABox: PropTypes.bool.isRequired,
  isSingle: PropTypes.bool.isRequired,
  link: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  onClick: PropTypes.func,
  ga: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};

Block.defaultProps = {
  isInABox: true,
  isSingle: false
};

export default withRouter(Block);
