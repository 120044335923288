/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CmsStaticDocumentsFilterInput = {|
  title?: ?string,
  type?: ?string,
|};
export type QCmsStaticDocumentsQueryVariables = {|
  documentsFilter?: ?CmsStaticDocumentsFilterInput
|};
export type QCmsStaticDocumentsQueryResponse = {|
  +cms: ?{|
    +staticDocuments: ?$ReadOnlyArray<?{|
      +fileId: ?string,
      +fileSource: ?string,
      +title: ?string,
      +type: ?string,
      +author: ?string,
      +description: ?string,
      +fileName: ?string,
    |}>
  |}
|};
export type QCmsStaticDocumentsQuery = {|
  variables: QCmsStaticDocumentsQueryVariables,
  response: QCmsStaticDocumentsQueryResponse,
|};
*/


/*
query QCmsStaticDocumentsQuery(
  $documentsFilter: CmsStaticDocumentsFilterInput
) {
  cms {
    staticDocuments(documentsFilter: $documentsFilter) {
      fileId
      fileSource
      title
      type
      author
      description
      fileName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentsFilter"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "documentsFilter",
            "variableName": "documentsFilter"
          }
        ],
        "concreteType": "CmsStaticDocument",
        "kind": "LinkedField",
        "name": "staticDocuments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileSource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "author",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsStaticDocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCmsStaticDocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e66ca1f83b7eebe207b6c085f365493a",
    "id": null,
    "metadata": {},
    "name": "QCmsStaticDocumentsQuery",
    "operationKind": "query",
    "text": "query QCmsStaticDocumentsQuery(\n  $documentsFilter: CmsStaticDocumentsFilterInput\n) {\n  cms {\n    staticDocuments(documentsFilter: $documentsFilter) {\n      fileId\n      fileSource\n      title\n      type\n      author\n      description\n      fileName\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'acfb5272ed22b850e48d33d05e21f806';

module.exports = node;
