/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/
const R = require('ramda');

import useFetching from '@@hooks/useFetching';
import { useRelayEnvironment } from 'react-relay';

import {

  AdherentAddressMutation,

  AdherentEmailsMutation,

  AdherentFamilyStatusMutation,

  AdherentPersonalInformationMutation,

  AdherentPhoneNumbersMutation,

  AdherentPhoneNumbersOtpMutation,

  AdherentProfessionalInformationMutation,

  CloseDiscussionMutation,

  CreateNewPasswordMutation,

  EmailToVerifyMutation,

  ForgotAdherentNumberMutation,

  ForgotOldPasswordMutation,

  MeBlockMutation,

  OldAdherentPasswordMutation,

  OnBoardAdherentMutation,

  OptPhoneNumberVerifyMutation,

  OtpNotReceiveMutation,

  OtpUpdateEmailMutation,

  OtpVerifyEmailMutation,

  PhoneNumberToVerifyMutation,

  SampleFormMutation,

  SendDiscussionMutation,

  SendMessageMutation,

  SendRequestIfHaveNoEmailMutation,

  SignInMutation,

  UpdateAdherentEmailMutation,

  UpdateAdherentPasswordMutation,

  UpdateSessionMutation,
} from '@@formsMutations';

import {

  adherentAddressFormData,

  adherentEmailsFormData,

  adherentFamilyStatusFormData,

  adherentPersonalInformationFormData,

  adherentPhoneNumbersFormData,

  adherentPhoneNumbersOtpFormData,

  adherentProfessionalInformationFormData,

  closeDiscussionFormData,

  createNewPasswordFormData,

  emailToVerifyFormData,

  forgotAdherentNumberFormData,

  forgotOldPasswordFormData,

  meBlockFormData,

  oldAdherentPasswordFormData,

  onBoardAdherentFormData,

  optPhoneNumberVerifyFormData,

  otpNotReceiveFormData,

  otpUpdateEmailFormData,

  otpVerifyEmailFormData,

  phoneNumberToVerifyFormData,

  sampleFormFormData,

  sendDiscussionFormData,

  sendMessageFormData,

  sendRequestIfHaveNoEmailFormData,

  signInFormData,

  updateAdherentEmailFormData,

  updateAdherentPasswordFormData,

  updateSessionFormData,
} from '@@formsData';

const handler = {

  adherentAddress: {
    mutation: AdherentAddressMutation,
    formData: adherentAddressFormData
  },

  adherentEmails: {
    mutation: AdherentEmailsMutation,
    formData: adherentEmailsFormData
  },

  adherentFamilyStatus: {
    mutation: AdherentFamilyStatusMutation,
    formData: adherentFamilyStatusFormData
  },

  adherentPersonalInformation: {
    mutation: AdherentPersonalInformationMutation,
    formData: adherentPersonalInformationFormData
  },

  adherentPhoneNumbers: {
    mutation: AdherentPhoneNumbersMutation,
    formData: adherentPhoneNumbersFormData
  },

  adherentPhoneNumbersOtp: {
    mutation: AdherentPhoneNumbersOtpMutation,
    formData: adherentPhoneNumbersOtpFormData
  },

  adherentProfessionalInformation: {
    mutation: AdherentProfessionalInformationMutation,
    formData: adherentProfessionalInformationFormData
  },

  closeDiscussion: {
    mutation: CloseDiscussionMutation,
    formData: closeDiscussionFormData
  },

  createNewPassword: {
    mutation: CreateNewPasswordMutation,
    formData: createNewPasswordFormData
  },

  emailToVerify: {
    mutation: EmailToVerifyMutation,
    formData: emailToVerifyFormData
  },

  forgotAdherentNumber: {
    mutation: ForgotAdherentNumberMutation,
    formData: forgotAdherentNumberFormData
  },

  forgotOldPassword: {
    mutation: ForgotOldPasswordMutation,
    formData: forgotOldPasswordFormData
  },

  meBlock: {
    mutation: MeBlockMutation,
    formData: meBlockFormData
  },

  oldAdherentPassword: {
    mutation: OldAdherentPasswordMutation,
    formData: oldAdherentPasswordFormData
  },

  onBoardAdherent: {
    mutation: OnBoardAdherentMutation,
    formData: onBoardAdherentFormData
  },

  optPhoneNumberVerify: {
    mutation: OptPhoneNumberVerifyMutation,
    formData: optPhoneNumberVerifyFormData
  },

  otpNotReceive: {
    mutation: OtpNotReceiveMutation,
    formData: otpNotReceiveFormData
  },

  otpUpdateEmail: {
    mutation: OtpUpdateEmailMutation,
    formData: otpUpdateEmailFormData
  },

  otpVerifyEmail: {
    mutation: OtpVerifyEmailMutation,
    formData: otpVerifyEmailFormData
  },

  phoneNumberToVerify: {
    mutation: PhoneNumberToVerifyMutation,
    formData: phoneNumberToVerifyFormData
  },

  sampleForm: {
    mutation: SampleFormMutation,
    formData: sampleFormFormData
  },

  sendDiscussion: {
    mutation: SendDiscussionMutation,
    formData: sendDiscussionFormData
  },

  sendMessage: {
    mutation: SendMessageMutation,
    formData: sendMessageFormData
  },

  sendRequestIfHaveNoEmail: {
    mutation: SendRequestIfHaveNoEmailMutation,
    formData: sendRequestIfHaveNoEmailFormData
  },

  signIn: {
    mutation: SignInMutation,
    formData: signInFormData
  },

  updateAdherentEmail: {
    mutation: UpdateAdherentEmailMutation,
    formData: updateAdherentEmailFormData
  },

  updateAdherentPassword: {
    mutation: UpdateAdherentPasswordMutation,
    formData: updateAdherentPasswordFormData
  },

  updateSession: {
    mutation: UpdateSessionMutation,
    formData: updateSessionFormData
  },

}
const useForms = (formName, { updater } = {}) => {
  const environment = useRelayEnvironment();
  const fetching = useFetching();

  const onFormsSubmit = (input) => {
    fetching.start();
    handler[formName].mutation(
      environment,
      input,
      (ok, error, payload) => {
        if (!ok) return fetching.stop(R.isNil(error) ? 'undefined_error' : error);
        fetching.stop(null, payload);
      },
      { updater }
    );
  };

  return {
    fetching,
    onFormsSubmit,
    formsData: handler[formName].formData
  };
};

export default useForms;