import React from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const NoOptionsMessage = props => {
  return (
    <components.NoOptionsMessage {...props}>
      {'Aucun résultat...'}
    </components.NoOptionsMessage>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={'f-select-one-arrow-box'}>
        <img src={'/icons/dropdown.svg'} alt={'dropdown'}/>
      </div>
    </components.DropdownIndicator>
  );
};

const SelectOneOption = ({
  options,
  value,
  onChange,
  placeholder,
  hasError,
  sortedByLabel
}) => {
  // TODO: find a way to apply a tailwind class for the error style
  const controlStyle = hasError ? { borderColor: '#fca5a5' } : {};
  // Override some default provided react-select styles
  const customStyle = {
    control: () => ({ ...controlStyle }),
    indicatorSeparator: () => ({}),
    singleValue: provided => ({ ...provided, color: '' }),
    dropdownIndicator: () => ({}),
    menu: provided => ({ ...provided, marginTop: '4px' })
  };

  // Sort options alphabetically (default behavior)
  const sortedOptions = R.when(
    R.always(R.equals(true, sortedByLabel)),
    R.sort(R.ascend(R.prop('label')))
  )(options);

  return (
    <Select
      className={'f-select-one-container'}
      classNamePrefix={'f-select-one'}
      styles={customStyle}
      components={{ NoOptionsMessage, DropdownIndicator }}
      value={value}
      onChange={onChange}
      options={sortedOptions}
      placeholder={placeholder}
    />
  );
};

SelectOneOption.propTypes = {
  className: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
  sortedByLabel: PropTypes.bool.isRequired
};

SelectOneOption.defaultProps = {
  className: '',
  hasError: false,
  sortedByLabel: true
};

export default SelectOneOption;
