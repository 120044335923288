import React from 'react';
import { Page } from '@@components';
import { Switch, Route } from 'react-router-dom';
import Home from './Home';
import Documents from './Documents';
const R = require('ramda');

const Index = ({
  match
}) => {
  return (
    <Page title="Vie de l'association">
      <Switch>
        <Route exact path={`${match.url}`} component={Home} />
        <Route exact path={`${match.url}/documents/:type/:year`} render={({ match }) => <Documents type={match.params.type} year={match.params.year} />} />
      </Switch>
    </Page>
  );
};

export default Index;
