import { graphql } from 'react-relay';

const QCmsContactQuery = graphql`
  query QCmsContactQuery {
    cms {
      contact {
        appaPhoneNumber
        appaAddress
        mercerPhoneNumber
        santeclairPhoneNumber
        europAssistanceMetropolePhoneNumber
        europAssistanceDROMPhoneNumber
        europAssistanceURL
      }
    }
  }
`;

export {
  QCmsContactQuery
};
