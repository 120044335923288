import React from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Redirect } from 'react-router-dom';
import { ErrorMessage } from '@@components';
import OtpNotReceiveModal from './OtpNotReceiveModal';
import ReSendOtpSmsModal from './ReSendOtpSmsModal';
import BackButton from '@@components/BackButton';

const R = require('ramda');
const RA = require('ramda-adjunct');

const Step4And7 = ({
  setStep,
  anonEmail,
  email,
  onDone,
  uuid,
  otpType,
  id,
  subscriberId,
  phoneNumber,
  forgotPasswordTunnel,
  handlePreviousStep
}) => {
  const [code, setCode] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [uuidOrUuidModal, setUuidOrUuidModal] = React.useState(uuid);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isCodeValid, setIsCodeValid] = React.useState(false);

  const data = {
    email: {
      formName: 'otpVerifyEmail',
      text1: `Nous venons de vous envoyer un code d'authentification par email à l’adresse ${anonEmail}.`,
      text2: 'Veuillez saisir le code reçu pour finaliser votre inscription à votre espace adhérent.',
      previousStep: 'step_3',
      buttonText: 'Suivant',
      email
    },
    phoneNumber: {
      formName: 'optPhoneNumberVerify',
      text1: 'Veuillez saisir le code reçu par SMS pour finaliser votre inscription à votre espace adhérent.',
      previousStep: 'step_6',
      buttonText: forgotPasswordTunnel ? 'Suivant' : 'Se connecter',
      phoneNumber
    }
  };

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms(R.path([otpType, 'formName'], data));

  React.useEffect(() => {
    if (fetching.isDone()) {
      onDone({
        id,
        payload: fetching.getPayload()
      });
      if (R.equals(otpType, 'phoneNumber') && RA.isFalse(forgotPasswordTunnel)) {
        setIsCodeValid(true);
      }
      if (R.equals(otpType, 'phoneNumber') && RA.isTrue(forgotPasswordTunnel)) {
        setStep('step_5');
      }
    }
    if (fetching.getError()) {
      setErrorMessage(fetching.getError());
    }
  }, [fetching.isDone(), fetching.getError()]);

  const onSubmit = (id) => (val) => onFormsSubmit({ ...val, id });

  const enableOtpTypeEmail = R.equals(otpType, 'email');

  const formsBlock = (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        id: uuid,
        email: R.path([otpType, 'email'], data),
        phoneNumber: R.path([otpType, 'phoneNumber'], data),
        subscriberId,
        forgotPasswordTunnel
      }}
      onChange={(val) => {
        setCode(val.code);
        fetching.reset();
      }}
      onSubmit={onSubmit(uuidOrUuidModal)}
      submitSection={() => (
        <>
          <div className="mb-12">
            {
              enableOtpTypeEmail
                ? (
                  <button type="button"
                    onClick={() => setOpenModal(true)}
                    className="f-link-turquoise"
                  >
                    {'Je n\'ai pas reçu le code'}
                  </button>)
                : (
                  <ReSendOtpSmsModal
                    setUuid={setUuidOrUuidModal}
                    subscriberId={subscriberId}
                    phoneNumber={phoneNumber}
                  />)
            }
          </div>
          <div className="f-connect-bottom-buttons">
            {(fetching.isDoneWithError() && !errorMessage) && (
              <div className="ml-4 text-purpleNavy">
                {'erreur de connexion'}
              </div>
            )}
            <BackButton onClick={handlePreviousStep(R.prop(otpType, data).previousStep, otpType)}/>
            <button
              className="f-button f-button-coral"
              disabled={RA.isNilOrEmpty(code) ? true : fetching.isFetching()}
              type="submit"
            >
              {R.prop(otpType, data).buttonText}
            </button>
          </div>
          {RA.isNotNil(errorMessage) && <ErrorMessage value={errorMessage} />}
        </>
      )}
    />
  );

  const optNotReceiveModal = enableOtpTypeEmail && (
    <OtpNotReceiveModal
      title={'Je n\'ai pas reçu le code'}
      show={openModal}
      onDisable={() => setOpenModal(false)}
      formDefaultValues={{ type: otpType }}
      formName={'otpNotReceive'}
    />
  );

  return (
    <div className='f-connect-container'>
      {isCodeValid && <Redirect to="/" />}
      { optNotReceiveModal }
      <div className='f-connect-forms-block'>
        <div className='f-connect-title'>
          {'Saisissez le code reçu'}
        </div>
        <div className='f-connect-intro'>
          <p>{R.prop(otpType, data).text1}</p>
          <p>{R.prop(otpType, data).text2}</p>
        </div>
        { formsBlock }
      </div>
    </div>
  );
};

Step4And7.propTypes = {
  id: PropTypes.string,
  onDone: PropTypes.func,
  setStep: PropTypes.func,
  anonEmail: PropTypes.string,
  email: PropTypes.string,
  uuid: PropTypes.string,
  otpType: PropTypes.string.isRequired,
  subscriberId: PropTypes.string,
  phoneNumber: PropTypes.string,
  forgotPasswordTunnel: PropTypes.bool,
  handlePreviousStep: PropTypes.func.isRequired
};

export default Step4And7;
