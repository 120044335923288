import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import * as R from 'ramda';

const UploadBlock = ({
  name,
  uploadUrl,
  types,
  onError,
  uploadText,
  onChange
}) => {
  const onDrop = React.useCallback(async (acceptedFiles) => {
    const file = R.prop(0, acceptedFiles);
    const formData = new FormData();
    formData.append(name, file, file.name);

    const response = await fetch(uploadUrl, {
      method: 'POST',
      body: formData
    });

    if (response.ok) {
      const { uploadId, fileName } = await response.json();
      onChange({ uploadId, fileName });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    noDrag: false,
    preventDropOnDocument: true,
    accept: 'image/jpeg, image/png, application/pdf'
  });

  return (
    <div {...getRootProps()} className="f-upload-attachments-block">
      <input {...getInputProps()} />
      <div className="f-upload-attachments-block-title">
        {'Déposer votre fichier ici'}
      </div>
      <div className="f-upload-attachments-block-text">
        {'Formats autorisés : .pdf, .jpg, .png - 20 Mo max'}
      </div>
    </div>
  );
};

UploadBlock.propTypes = {
  name: PropTypes.string
};

UploadBlock.defaultProps = {
};

export default UploadBlock;
