// This code is based on https://github.com/substack/catch-links
// The original version was anonymously attaching this function to an
// eventListener without a way to remove it
import url from 'url';

export const linkCatcher = (callback) => (event) => {
  if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey || event.defaultPrevented) {
    return true;
  }

  let anchor = null;
  for (let n = event.target; n.parentNode; n = n.parentNode) {
    if (n.nodeName === 'A') {
      anchor = n;
      break;
    }
  }

  if (!anchor) return true;

  const u = url.parse(anchor.getAttribute('href'));

  if (u.host && u.host !== location.host) return true;

  event.preventDefault();

  const href = url.resolve(location.pathname, u.path || '') + (u.hash || '');
  callback(href);
  return false;
};
