/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type MessageFilterInput = {|
  id?: ?string
|};
export type PaginateInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type QDiscussionQueryVariables = {|
  id?: ?string,
  messageFilter?: ?MessageFilterInput,
  paginate?: ?PaginateInput,
|};
export type QDiscussionQueryResponse = {|
  +getDiscussion: ?{|
    +id: string,
    +title: ?string,
    +recipients: ?$ReadOnlyArray<?string>,
    +status: ?string,
    +pendingSignRequest: ?boolean,
    +allMessages: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +title: ?string,
        +recipients: ?$ReadOnlyArray<?string>,
        +sender: ?string,
        +text: ?string,
        +order: ?number,
        +collaboratorName: ?string,
        +dates: ?{|
          +creation: ?string,
          +lastUpdate: ?string,
        |},
        +attachments: ?$ReadOnlyArray<?{|
          +id: ?string,
          +documentType: ?string,
          +fileName: ?string,
        |}>,
      |}>,
      +pagination: ?{|
        +total: number,
        +limit: number,
        +page: number,
        +pages: number,
      |},
    |},
  |}
|};
export type QDiscussionQuery = {|
  variables: QDiscussionQueryVariables,
  response: QDiscussionQueryResponse,
|};
*/


/*
query QDiscussionQuery(
  $id: ID
  $messageFilter: MessageFilterInput
  $paginate: PaginateInput
) {
  getDiscussion(id: $id) {
    id
    title
    recipients
    status
    pendingSignRequest
    allMessages(filter: $messageFilter, paginate: $paginate) {
      data {
        id
        title
        recipients
        sender
        text
        order
        collaboratorName
        dates {
          creation
          lastUpdate
        }
        attachments {
          id
          documentType
          fileName
        }
      }
      pagination {
        total
        limit
        page
        pages
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "messageFilter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paginate"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "recipients",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Discussion",
    "kind": "LinkedField",
    "name": "getDiscussion",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pendingSignRequest",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "messageFilter"
          },
          {
            "kind": "Variable",
            "name": "paginate",
            "variableName": "paginate"
          }
        ],
        "concreteType": "PaginatedMessages",
        "kind": "LinkedField",
        "name": "allMessages",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Message",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "collaboratorName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Dates",
                "kind": "LinkedField",
                "name": "dates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "creation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastUpdate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "documentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fileName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pagination",
            "kind": "LinkedField",
            "name": "pagination",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "limit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "page",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QDiscussionQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QDiscussionQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "96b9868e6cd51e4f7f5c9671f1203072",
    "id": null,
    "metadata": {},
    "name": "QDiscussionQuery",
    "operationKind": "query",
    "text": "query QDiscussionQuery(\n  $id: ID\n  $messageFilter: MessageFilterInput\n  $paginate: PaginateInput\n) {\n  getDiscussion(id: $id) {\n    id\n    title\n    recipients\n    status\n    pendingSignRequest\n    allMessages(filter: $messageFilter, paginate: $paginate) {\n      data {\n        id\n        title\n        recipients\n        sender\n        text\n        order\n        collaboratorName\n        dates {\n          creation\n          lastUpdate\n        }\n        attachments {\n          id\n          documentType\n          fileName\n        }\n      }\n      pagination {\n        total\n        limit\n        page\n        pages\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '996dac2627707b12581de1b09fe90c15';

module.exports = node;
