const options = [
  {
    value: 'mobile_pro',
    label: 'Mobile professionnel'
  }, {
    value: 'mobile_perso',
    label: 'Mobile personnel'
  }, {
    value: 'fix_perso',
    label: 'Fixe personnel'
  }
];

export default options;
