import Tokens from '../lib/Tokens';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const R = require('ramda');

const {
  Environment,
  Network,
  Observable,
  RecordSource,
  Store
} = require('relay-runtime');

const GQLEnvironment = async () => {
  const sendRequest = async (url, headers, body, refresh_token = false) => {
    const access_token = await Tokens.getAccessToken(refresh_token);
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        ...headers,
        Authorization: `Bearer ${access_token}`
      },
      body
    });

    if (response.status === 200) {
      return await response.json();
    }

    if (response.status === 401) {
      return await sendRequest(url, headers, body, true);
    }

    return new Error('graphql request failed');
  };

  const fetchQuery = async (operation, variables, cacheConfig) => {
    let body = new FormData();
    body = JSON.stringify({
      // queryId: operation.id,
      query: operation.text,
      variables
    });
    const headers = {
      Accept: '*/*',
      'Content-Type': 'application/json'
    };

    return await sendRequest('/graphql', headers, body);
  };

  const graphqlWsData = async () => {
    const response = await fetch('/graphqlwsurl');
    return await response.json();
  };

  const { subscriptionUrl, subscriptionAccessToken } = await graphqlWsData();
  const subscriptionClient = new SubscriptionClient(subscriptionUrl, {
    reconnect: true,
    connectionParams: {
      authToken: `Bearer ${subscriptionAccessToken}`
    }
  });

  const subscribe = (request, variables) => {
    const subscribeObservable = subscriptionClient.request({
      query: request.text,
      operationName: request.name,
      variables
    });
    // Important: Convert subscriptions-transport-ws observable type to Relay's
    return Observable.from(subscribeObservable);
  };

  return new Environment({
    network: Network.create(fetchQuery, subscribe),
    store: new Store(new RecordSource())
  });
};

export default GQLEnvironment;
