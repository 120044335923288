import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import ReactMarkdown from 'react-markdown';
import { CONTRACT_TYPE_HEALTH } from '@@lib/contracts';
import {
  getHealthTitle,
  getPensionTitleAndDescription,
  getValue
} from '@@pages/contracts/utils';

// Pour un contrat santé, les garanties sont affichées selon le schéma:
// [title] : [value]
// - [title] correspond à guarantee.label
// - [value] correspond à guarantee.value
const healthGuaranteeToJSX = (guarantee, index) => {
  const value = getValue(guarantee);
  const title = getHealthTitle(guarantee);
  return (
    <li key={index}>
      <ReactMarkdown>
        {`${title}${value}`}
      </ReactMarkdown>
    </li>
  );
};

// Pour un contrat prévoyance, les garanties sont affichées selon le schéma:
// [title] : [value]
// [description]
// - [title] correspond à la première ligne de guarantee.label (jusqu'au 1er \n)
// - [description] correspond au reste du texte de guarantee.label
// - [value] correspond à guarantee.value
const pensionGuaranteeToJSX = (guarantee, index) => {
  const value = getValue(guarantee);
  const { title, description } = getPensionTitleAndDescription(guarantee);
  return (
    <li key={index}>
      <ReactMarkdown>
        {`${title}${value}${description}`}
      </ReactMarkdown>
    </li>
  );
};

const familyToJSX = (guaranteesByFamily, familyName, isHealth) => {
  const guaranteeList = R.propOr([], familyName, guaranteesByFamily);

  const titlePill = RA.isNotEmpty(familyName) && (
    <div className={'f-contract-details-pill my-2'}>
      {familyName}
    </div>
  );

  const mapper = isHealth ? healthGuaranteeToJSX : pensionGuaranteeToJSX;
  return (
    <div key={familyName}>
      {titlePill}
      <ul className={'f-contract-primary-list-item'}>
        {RA.mapIndexed(mapper, guaranteeList)}
      </ul>
    </div>
  );
};

const ContractDetailsCoversTable = ({ contract }) => {
  const guaranteeNotEmpty = R.allPass([
    R.propSatisfies(RA.isNotNilOrEmpty, 'label'),
    R.propSatisfies(RA.isNotNilOrEmpty, 'value')
  ]);
  const guaranteesByFamily = R.compose(
    R.groupBy(R.propOr('', 'family')),
    R.filter(guaranteeNotEmpty),
    R.pathOr([], ['detail', 'guarantee'])
  )(contract);
  const isHealth = R.propEq('contractType', CONTRACT_TYPE_HEALTH, contract);

  const familyNames = R.keys(guaranteesByFamily);
  const familyNameToJSX = name => familyToJSX(guaranteesByFamily, name, isHealth);

  return (
    <div className={'f-contract-primary-list'}>
      {R.map(familyNameToJSX, familyNames)}
      <div className={'f-contract-primary-list-footer'}>
        <ReactMarkdown>
          {R.pathOr('', ['detail', 'complementaryText'], contract)}
        </ReactMarkdown>
      </div>
    </div>
  );
};

ContractDetailsCoversTable.propTypes = {
  contract: PropTypes.object
};

export default ContractDetailsCoversTable;
