/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCmsHeaderFooterQueryVariables = {||};
export type QCmsHeaderFooterQueryResponse = {|
  +cms: ?{|
    +header: ?{|
      +phone: ?string
    |},
    +footer: ?{|
      +phone: ?string
    |},
  |}
|};
export type QCmsHeaderFooterQuery = {|
  variables: QCmsHeaderFooterQueryVariables,
  response: QCmsHeaderFooterQueryResponse,
|};
*/


/*
query QCmsHeaderFooterQuery {
  cms {
    header {
      phone
    }
    footer {
      phone
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "phone",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsHeader",
        "kind": "LinkedField",
        "name": "header",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CmsFooter",
        "kind": "LinkedField",
        "name": "footer",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsHeaderFooterQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QCmsHeaderFooterQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c74f69da13555f6a00158a074fc35902",
    "id": null,
    "metadata": {},
    "name": "QCmsHeaderFooterQuery",
    "operationKind": "query",
    "text": "query QCmsHeaderFooterQuery {\n  cms {\n    header {\n      phone\n    }\n    footer {\n      phone\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0750440afb16e6cdb332cc89a28a0c3';

module.exports = node;
