/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QDiscussionSignRequestQueryVariables = {|
  discussionId: string
|};
export type QDiscussionSignRequestQueryResponse = {|
  +discussionSignRequest: ?{|
    +id: ?string,
    +youSign: ?{|
      +memberId: ?string,
      +signatureUi: ?string,
    |},
    +youSignUrl: ?string,
  |}
|};
export type QDiscussionSignRequestQuery = {|
  variables: QDiscussionSignRequestQueryVariables,
  response: QDiscussionSignRequestQueryResponse,
|};
*/


/*
query QDiscussionSignRequestQuery(
  $discussionId: ID!
) {
  discussionSignRequest(discussionId: $discussionId) {
    id
    youSign {
      memberId
      signatureUi
    }
    youSignUrl
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "discussionId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "discussionId",
        "variableName": "discussionId"
      }
    ],
    "concreteType": "DiscussionSignRequest",
    "kind": "LinkedField",
    "name": "discussionSignRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DiscussionSignRequestYouSignDetails",
        "kind": "LinkedField",
        "name": "youSign",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memberId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "signatureUi",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "youSignUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QDiscussionSignRequestQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QDiscussionSignRequestQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1c5c211fcb9b2fb3cf1672dab6a73cf3",
    "id": null,
    "metadata": {},
    "name": "QDiscussionSignRequestQuery",
    "operationKind": "query",
    "text": "query QDiscussionSignRequestQuery(\n  $discussionId: ID!\n) {\n  discussionSignRequest(discussionId: $discussionId) {\n    id\n    youSign {\n      memberId\n      signatureUi\n    }\n    youSignUrl\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c79446b1157fb0fa739ea3341eec1ca4';

module.exports = node;
