import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { typologies } from '@@enum';

const labels = {
  civility: {
    M: 'M.',
    F: 'Mme',
    FF: 'Mlle',
    Dr: 'Dr.'
  },
  maritalStatus: {
    celibataire: 'Célibataire',
    concubin: 'Concubin(e)',
    divorce: 'Divorcé(e)',
    marie: 'Marié(e)',
    pacse: 'Pacsé(e)',
    veuf: 'Veuf(ve)'
  }
};

export const getLabel = (label, value) => R.pathOr('', [label, value], labels);

export const getField = (object) => (path) => R.pathOr('', path, object);

export const getContractField = contract => getField(contract);

export const getPHHUContractIfPresent = R.when(
  R.any(R.pathEq(['detail', 'typology'], typologies.PHHU)),
  R.filter(R.pathEq(['detail', 'typology'], typologies.PHHU)));

export const findFunction = (contracts) => R.compose(
  R.head,
  R.map(R.path(['detail', 'function']))
)(getPHHUContractIfPresent(contracts));

export const findActivity = (contracts) => R.compose(
  R.head,
  R.map(R.path(['detail', 'activity'])),
  R.filter(R.pathEq(['detail', 'typology'], typologies.PHHU))
)(getPHHUContractIfPresent(contracts));

export const findEchelon = (contracts) => R.compose(
  R.head,
  R.filter(RA.isNotEmpty),
  R.map(
    contract => R.propOr('', 'index', R.find(R.propEq('type', 'ECHELON'), getContractField(contract)(['pricingOptions'])))
  )
)(getPHHUContractIfPresent(contracts));

export const getActivityRate = (contracts) => R.compose(
  R.head,
  R.map(R.prop('activityRate')),
  R.filter(R.pathSatisfies(R.includes(R.__, [typologies.PHHU, typologies.INTERNE]), ['detail', 'typology']))
)(getPHHUContractIfPresent(contracts));

export const typologyEq = (typology, contracts) => R.any(R.pathEq(['detail', 'typology'], typology), contracts);
