import ColoredIcon from '@@components/ColoredIcon';
import React from 'react';
import PropTypes from 'prop-types';

const BackButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="f-button-grey f-button-back"
      onClick={onClick}
    >
      <div className="flex justify-center w-full">
        <ColoredIcon icon="arrow-down.svg" height={12} width={12}
          className="m-0"/>
      </div>
    </button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func
};

export default BackButton;
