import { graphql } from 'react-relay';

const QCmsMercerQuery = graphql`
  query QCmsMercerQuery {
    cms {
      mercer {
        mercerUrl
      }
    }
  }
`;

export {
  QCmsMercerQuery
};
