/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGetMessageNotifiedQueryVariables = {||};
export type QGetMessageNotifiedQueryResponse = {|
  +getMessageNotified: ?$ReadOnlyArray<?{|
    +_id: ?string,
    +discussionId: ?string,
    +messageId: ?string,
    +title: ?string,
    +createdAt: ?string,
    +type: ?string,
    +text: ?string,
  |}>
|};
export type QGetMessageNotifiedQuery = {|
  variables: QGetMessageNotifiedQueryVariables,
  response: QGetMessageNotifiedQueryResponse,
|};
*/


/*
query QGetMessageNotifiedQuery {
  getMessageNotified {
    _id
    discussionId
    messageId
    title
    createdAt
    type
    text
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MessageNotifier",
    "kind": "LinkedField",
    "name": "getMessageNotified",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discussionId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "messageId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGetMessageNotifiedQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QGetMessageNotifiedQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "908b4ca89150a5e79f8fcdf6c49972a3",
    "id": null,
    "metadata": {},
    "name": "QGetMessageNotifiedQuery",
    "operationKind": "query",
    "text": "query QGetMessageNotifiedQuery {\n  getMessageNotified {\n    _id\n    discussionId\n    messageId\n    title\n    createdAt\n    type\n    text\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae57da9547bd0049d5403542bc6002ad';

module.exports = node;
