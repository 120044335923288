/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenderEnum = "Dr" | "F" | "FF" | "M" | "%future added value";
export type MeBlockInput = {|
  email: string,
  firstname: string,
  gender: GenderEnum,
  lastname: string,
  network: string,
|};
export type MeBlockMutationVariables = {|
  input: MeBlockInput
|};
export type MeBlockMutationResponse = {|
  +meBlock: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type MeBlockMutation = {|
  variables: MeBlockMutationVariables,
  response: MeBlockMutationResponse,
|};
*/


/*
mutation MeBlockMutation(
  $input: MeBlockInput!
) {
  meBlock(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MeBlockMutationResponse",
    "kind": "LinkedField",
    "name": "meBlock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MeBlockMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MeBlockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a71b53ece47201958c31ce80c9865ded",
    "id": null,
    "metadata": {},
    "name": "MeBlockMutation",
    "operationKind": "mutation",
    "text": "mutation MeBlockMutation(\n  $input: MeBlockInput!\n) {\n  meBlock(input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ffd0ec9801c03ee617c1c0195e6c44a1';

module.exports = node;
