import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import useKeycloakSession from '../../../hooks/useKeycloakSession';
import { dateToHumanFull } from '@@lib/dateToHumanFull';
import CollabMessageSign from './CollabMessageSign';

const Message = ({
  value,
  messageId,
  signature
}) => {
  const { dates, text, attachments, sender, collaboratorName } = value;

  const { user } = useKeycloakSession();

  const { customerId } = user;

  const isFromMe = R.equals(customerId, sender);

  const classes = classNames('f-messaging-message',
    {
      'f-message-received': !isFromMe,
      'f-message-sent': isFromMe
    });

  const downloadAttachmentUrl = (attachment) => {
    return `/documents/download/${messageId}/${R.propOr('', 'id', attachment)}?fileName=${R.propOr('', 'fileName', attachment)}`;
  };

  return (
    <div className="f-messaging-message-container">

      <div className={classes}>
        <div className="f-messaging-message-header">
          {
            !isFromMe &&
            <div className="f-header-sender">
              {collaboratorName}
            </div>
          }
          {isFromMe && <div className="f-header-sender">{'Moi'}</div>}
          <div className="f-header-date">
            Le {dateToHumanFull(dates.creation, '-')}
          </div>
        </div>
        <div className="f-messaging-message-content">{text}</div>
        {
          R.length(attachments) > 0 && (
            <div className="f-messaging-message-attachments">
              {
                attachments.map((attachment) =>
                  <a key={R.propOr('', 'id', attachment)}
                    className="f-messaging-message-attachment"
                    href={downloadAttachmentUrl(attachment)}
                    target="_blank" rel="noreferrer">
                    <img src="/icons/icon-paperclip.svg" alt="attachment-icon"/>
                    <p>
                      {R.propOr('', 'fileName', attachment)}
                    </p>
                  </a>
                )}
            </div>
          )}
        {
          !isFromMe && <CollabMessageSign name={collaboratorName} signature={signature}/>
        }
      </div>

    </div>
  );
};

Message.propTypes = {
  value: PropTypes.shape({
    attachments: PropTypes.array.isRequired,
    dates: PropTypes.shape({
      creation: PropTypes.string.isRequired,
      lastUpdate: PropTypes.string
    }).isRequired,
    id: PropTypes.string.isRequired,
    order: PropTypes.number.isRequired,
    recipients: PropTypes.arrayOf(
      PropTypes.string
    ).isRequired,
    sender: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    collaboratorName: PropTypes.string
  }).isRequired,
  messageId: PropTypes.string.isRequired
};

export default Message;
