import PropTypes from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const ExpectedAttachments = ({
  types,
  attachedTypes,
  value
}) => {
  const indexedTypes = R.indexBy(R.prop('id'), types);

  if (R.isNil(value)) return null;

  const documents = R.compose(
    R.join(' ou '),
    R.map(index => R.path([index, 'label'], indexedTypes))
  );

  const comments = R.compose(
    R.filter(RA.isNotNilOrEmpty),
    R.map(index => R.path([index, 'comment'], indexedTypes))
  );

  const remainingMandatoryAttachments = R.compose(
    R.reject(R.any(R.includes(R.__, attachedTypes))),
    R.propOr([], 'mandatory')
  )(value);

  const remainingOptionalAttachments = R.compose(
    R.reject(R.propSatisfies(R.any(R.includes(R.__, attachedTypes)), 'attachement')),
    R.propOr([], 'optional')
  )(value);

  const hasAnyAttachment = R.or(
    RA.isNotNilOrEmpty(remainingMandatoryAttachments),
    RA.isNotNilOrEmpty(remainingOptionalAttachments)
  );

  const mandatoryAttachmentToJSX = (items, index) => {
    const allComments = comments(items);
    return (
      <React.Fragment key={`mandatory-${index}`}>
        <li className={'text-navy-blue font-bold'}>
          {documents(items)}
        </li>
        { RA.mapIndexed((com, idx) =>
          <li key={idx}>{com}</li>
        )(allComments)}
      </React.Fragment>
    );
  };

  const optionalAttachmentToJSX = ({ context, attachement }, index) => (
    <li key={`optional-${index}`} className={'text-navy-blue font-bold'}>
      {`${context} : ${documents(attachement)}`}
    </li>
  );

  return hasAnyAttachment && (
    <div className="py-4">
      <div className="text-navy-blue">
        {'Pour le traitement de cette demande, veuillez nous fournir ces pièces :'}
      </div>
      <ul className="list-disc list-inside">
        { RA.mapIndexed(mandatoryAttachmentToJSX, remainingMandatoryAttachments) }
        { RA.mapIndexed(optionalAttachmentToJSX, remainingOptionalAttachments) }
      </ul>
    </div>
  );
};

ExpectedAttachments.defaultProps = {
  attachedTypes: []
};

ExpectedAttachments.propTypes = {
  value: PropTypes.shape({
    mandatory: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.string
      )
    ),
    optional: PropTypes.arrayOf(
      PropTypes.shape({
        attachement: PropTypes.arrayOf(
          PropTypes.string
        ),
        context: PropTypes.string
      })
    )
  }),
  attachedTypes: PropTypes.array,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      comment: PropTypes.string
    })
  )

};

export default ExpectedAttachments;
