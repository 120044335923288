import React from 'react';
import { useCms } from '@@hooks';
const R = require('ramda');

const Documents = ({ type, year }) => {
  const { documents } = useCms(['documents'], { documentsFilter: { year, type } });

  if (R.isNil(documents)) return null;

  return (
    <div>
      <ul>
        {documents.map((document, index) => (
          <li key={index}>
            <a href={`/documents/cms/${document.url}`}>{document.title}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Documents;
