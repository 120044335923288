import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import classNames from 'classnames';

const Field = ({
  label,
  value,
  children,
  topRightButton,
  alignedLabel,
  errorsJSX,
  className
}) => {
  // on top of the input row: text + optional button
  const header = R.isNil(alignedLabel) && RA.isNotNilOrEmpty(label) && (
    <div className='f-block-field-row-header'>
      <div className='f-block-field-row-header-label'>
        {label}
      </div>
      {topRightButton}
    </div>
  );

  const inputContent = R.isNil(children) ? (<div>{value}</div>) : children;
  const body = (
    <div className='f-block-field-row-input'>
      {inputContent}
      {alignedLabel}
    </div>);

  return (
    <div className={classNames('f-block-field', className)}>
      {header}
      {body}
      {errorsJSX}
    </div>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  alignedLabel: PropTypes.element,
  errorsJSX: PropTypes.element,
  topRightButton: PropTypes.element,
  className: PropTypes.string
};

Field.defaultProps = {
  className: ''
};

export default Field;
