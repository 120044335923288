import React from 'react';
import { QBackOfficeMessageThemesQuery } from '@@queries/QBackOfficeMessageThemes';
import { QExtranetMessageThemesQuery } from '@@queries/QExtranetMessageThemes';
import { QMessageAttachmentTypesQuery } from '@@queries/QMessageAttachmentTypes';
import { QCmsContactQuery } from '@@queries/QCmsContact';

const R = require('ramda');
const { fetchQuery, useRelayEnvironment } = require('react-relay');

export const useReferences = (data) => {
  const environment = useRelayEnvironment();
  const [content, setContent] = React.useState({
    messageThemes: null,
    messageAttachmentTypes: null
  });

  const dataRef = React.useRef();
  React.useEffect(() => {
    if (R.equals(dataRef.current, data)) return;
    dataRef.current = data;
    if (R.contains('backOfficeMessageThemes', data)) getBackOfficeMessageThemes();
    if (R.contains('extranetMessageThemes', data)) getExtranetMessageThemes();
    if (R.contains('messageAttachmentTypes', data)) getMessageAttachmentTypes();
  }, [data]);

  const getBackOfficeMessageThemes = async () => {
    const { references } = await fetchQuery(environment, QBackOfficeMessageThemesQuery).toPromise();
    setContent(R.compose(
      R.assoc('backOfficeMessageThemes', R.path(['messageThemesBackOffice'], references))
    ));
  };

  const getExtranetMessageThemes = async () => {
    const { references } = await fetchQuery(environment, QExtranetMessageThemesQuery).toPromise();
    setContent(R.compose(
      R.assoc('extranetMessageThemes', R.path(['messageThemesExtranet'], references))
    ));
  };

  const getMessageAttachmentTypes = async () => {
    const { references } = await fetchQuery(environment, QMessageAttachmentTypesQuery).toPromise();
    setContent(R.compose(
      R.assoc('messageAttachmentTypes', references.messageAttachmentTypes)
    ));
  };

  return {
    ...content
  };
};

export default useReferences;
