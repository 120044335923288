/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OtpVerifyEmailInput = {|
  code: string,
  email: string,
  id: string,
  subscriberId: string,
|};
export type OtpVerifyEmailMutationVariables = {|
  input: OtpVerifyEmailInput
|};
export type OtpVerifyEmailMutationResponse = {|
  +otpVerifyEmail: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type OtpVerifyEmailMutation = {|
  variables: OtpVerifyEmailMutationVariables,
  response: OtpVerifyEmailMutationResponse,
|};
*/


/*
mutation OtpVerifyEmailMutation(
  $input: OtpVerifyEmailInput!
) {
  otpVerifyEmail(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OtpVerifyEmailMutationResponse",
    "kind": "LinkedField",
    "name": "otpVerifyEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OtpVerifyEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OtpVerifyEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f92f9d235bf9b6d68b2ddadf2e136005",
    "id": null,
    "metadata": {},
    "name": "OtpVerifyEmailMutation",
    "operationKind": "mutation",
    "text": "mutation OtpVerifyEmailMutation(\n  $input: OtpVerifyEmailInput!\n) {\n  otpVerifyEmail(input: $input) {\n    ok\n    error\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5400f38c7b2eb93846f2afcd9adfa7b6';

module.exports = node;
