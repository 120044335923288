import { graphql } from 'react-relay';

const QAdherentFirstConnectionStatusQuery = graphql`
  query QAdherentFirstConnectionStatusQuery($subscriberId: ID!) {
    getAdherentFirstConnectionStatus(subscriberId: $subscriberId)
  }
`;

export {
  QAdherentFirstConnectionStatusQuery
};
