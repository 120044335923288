import React from 'react';
import { SignOutMutation } from '@@mutations/userSession';
import useFetching from './useFetching';
const { useRelayEnvironment } = require('react-relay');

const useUserSession = () => {
  const fetching = useFetching();
  const environment = useRelayEnvironment();

  const onSignOut = () => {
    fetching.start();
    SignOutMutation(environment, (ok, error) => {
      if (!ok) return fetching.stop('failed');
      fetching.stop();
    });
  };

  return {
    fetching,
    onSignOut
  };
};

export default useUserSession;
