/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/

const R = require('ramda');
import {
commitMutation,
graphql
} from 'react-relay';

const mutation = graphql`
mutation SendMessageMutation($input: SendMessageInput!) {
  sendMessage(input: $input) {
    ok
    error
    
    discussionId 
    
    messageId 
    
    
      attachments {
      id
uploadId
fileName
documentType
    }
     
    
    
      message {
      recipients
title
text
sender
order
    }
     
    
    
      dates {
      creation
lastUpdate
    }
     
    
  }
}
`;

export default async (environment, input, done, { updater }) => {
  const variables = { input };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore, response) => {
        if (R.is(Function, updater)) updater(input, proxyStore, response);
      },
      onCompleted: ({ sendMessage }) => {
        const { ok, error } = sendMessage;
        done(ok, error, R.omit(['ok', 'error'], sendMessage));
      },
      onError: err => console.error(err)
    }
  );
};