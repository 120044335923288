import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as R from 'ramda';
import { dateToHumanFull } from '@@lib/dateToHumanFull';
import { Pagination } from '@@components';
import { paperclipIcon } from '@@images';
import { hasAnyAttachment } from '@@pages/message/components/utils';

const DiscussionList = ({
  items,
  onSelect,
  current,
  discussionTitle,
  pagination,
  setPage,
  unreadDiscussions
}) => {
  const $onSelect = (discussionId) => (e) => {
    e.preventDefault();
    onSelect(discussionId);
  };

  const discussionToJSX = discussion => {
    const hasAttachments = hasAnyAttachment(discussion);
    const isUnread = R.includes(discussion.id, unreadDiscussions);
    const classes = classNames('f-messaging-discussions-item', {
      'f-messaging-discussions-item-current': R.equals(discussion.id, current)
    });

    return (
      <div key={discussion.id} className={classes}
        onClick={$onSelect(discussion.id)}
      >
        {isUnread && (
          <div className="f-discussion-list-unread-pill"/>
        )}
        {hasAttachments && (
          <div className='f-discussion-list-icon-paperclip'
            style={{ backgroundImage: `url(${paperclipIcon})` }}/>
        )}
        <div className="f-messaging-discussions-item-title">
          {discussionTitle(discussion.title)}
        </div>
        <div className="f-messaging-discussions-item-date">
          {dateToHumanFull(discussion.dates.lastUpdate, 'à')}
        </div>
      </div>
    );
  };

  return (
    <div className="f-discussion-list-container">
      {
        R.map(discussionToJSX, items)
      }
      {
        R.gt(pagination.total, pagination.limit) &&
        <Pagination
          onChange={setPage}
          current={pagination.page}
          total={pagination.total}
          pageSize={pagination.limit}
        />
      }
    </div>
  );
};

DiscussionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      dates: PropTypes.shape({
        creation: PropTypes.string.isRequired,
        lastUpdate: PropTypes.string.isRequired
      })
    })
  ),
  onSelect: PropTypes.func.isRequired,
  current: PropTypes.string,
  discussionTitle: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPage: PropTypes.func.isRequired,
  unreadDiscussions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DiscussionList;
