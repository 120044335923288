import React, { useEffect, useRef, useState } from 'react';
import { QAdherentDocumentsQuery } from '@@queries/QAdherentDocuments';

const R = require('ramda');

const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useFetchQuery = (gqlQuery, args) => {
  const environment = useRelayEnvironment();
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const resultData = useRef({});
  useEffect(() => {
    const subscription = fetchQuery(environment, gqlQuery, args)
      .subscribe({
        start: () => {
          // console.log('start fetchQuery...', gqlQuery, args);
        },
        complete: () => {
          // console.log('completed fetchQuery!');
          setIsLoaded(true);
        },
        error: (error) => {
          // console.error('oops, an error!', error);
          setErrorMsg(JSON.stringify(error, null, 4));
        },
        next: (data) => {
          // console.log(' next packet received...', data);
          resultData.current = data;
        }
      });
    return () => subscription.unsubscribe();
  }, []);

  return {
    isLoaded: isLoaded,
    errorMsg: errorMsg,
    data: resultData.current
  };
};

export const useDocument = (id, filter) => {
  const environment = useRelayEnvironment();
  const [documents, setDocuments] = React.useState([]);
  const idRef = React.useRef();
  const filterRef = React.useRef();

  React.useEffect(() => {
    if (R.equals(idRef.current, id) && R.equals(filterRef.current, filter)) return;
    idRef.current = id;
    filterRef.current = filter;
    getDocuments(id, filter);
  }, [id]);

  const getDocuments = async (id, filter) => {
    const { adherent } = await fetchQuery(environment, QAdherentDocumentsQuery, { id, filter }).toPromise();
    setDocuments(R.propOr({}, 'documents', adherent));
  };

  return {
    documents
  };
};

export default useDocument;
