/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DocumentFilterInput = {|
  contractId?: ?string,
  status?: ?string,
  type?: ?string,
|};
export type QAdherentDocumentsQueryVariables = {|
  id: string,
  filter?: ?DocumentFilterInput,
|};
export type QAdherentDocumentsQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +documents: ?$ReadOnlyArray<?{|
      +_id: ?string,
      +fileId: string,
      +fileSource: ?string,
      +subscriberId: string,
      +title: ?string,
      +author: ?string,
      +type: ?string,
      +subType: ?string,
      +description: ?string,
      +status: ?string,
      +createdAt: ?string,
      +updatedAt: ?string,
      +fileName: ?string,
    |}>,
  |}
|};
export type QAdherentDocumentsQuery = {|
  variables: QAdherentDocumentsQueryVariables,
  response: QAdherentDocumentsQueryResponse,
|};
*/


/*
query QAdherentDocumentsQuery(
  $id: ID!
  $filter: DocumentFilterInput
) {
  adherent(id: $id) {
    id
    documents(filter: $filter) {
      _id
      fileId
      fileSource
      subscriberId
      title
      author
      type
      subType
      description
      status
      createdAt
      updatedAt
      fileName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Adherent",
    "kind": "LinkedField",
    "name": "adherent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "filter",
            "variableName": "filter"
          }
        ],
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileSource",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriberId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "author",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fileName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentDocumentsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QAdherentDocumentsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "df72b1067afebb4283fc4a70e1d4b566",
    "id": null,
    "metadata": {},
    "name": "QAdherentDocumentsQuery",
    "operationKind": "query",
    "text": "query QAdherentDocumentsQuery(\n  $id: ID!\n  $filter: DocumentFilterInput\n) {\n  adherent(id: $id) {\n    id\n    documents(filter: $filter) {\n      _id\n      fileId\n      fileSource\n      subscriberId\n      title\n      author\n      type\n      subType\n      description\n      status\n      createdAt\n      updatedAt\n      fileName\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43eaac789ac3156ff6c4d74c119fac60';

module.exports = node;
