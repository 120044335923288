import React, { useState } from 'react';
import Modal from '@@components/modale/index';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';
import { iconCheck } from '@@images';
import cookieManager from '@@root/utils/cookieManager';
import { Link } from 'react-router-dom';
import { PAGE_DONNEES_PERSONNELLES } from '@@pages/home/utils';

const TITLE_MODAL_1 = 'Gestion des cookies';
const TITLE_MODAL_2 = 'Préférences des cookies';
const TITLE_MODAL_NECESSARY = 'Cookies strictement nécessaires';
const TEXT_MODAL_NECESSARY = 'Ces cookies sont indispensables pour vous' +
  ' permettre de naviguer au sein du site\net d’utiliser les fonctionnalités' +
  ' proposées, notamment pour accéder aux sections\nsécurisées. Sans ces' +
  ' cookies, les services demandés ne pourront pas être assurés.';
const TITLE_MODAL_PERFORMANCE = 'Cookies de performance';
const TEXT_MODAL_PERFORMANCE = 'Ces cookies nous permettent de déterminer le' +
  ' nombre de visites et les sources du\ntrafic sur notre site web, afin de' +
  ' mesurer et d’améliorer les performances. Ils nous\naident également à' +
  ' identifier les pages les plus / moins visitées et à évaluer\ncomment les' +
  ' visiteurs naviguent sur le site. Toutes les informations collectées par' +
  '\nces cookies sont agrégées et donc anonymisées. Si vous n’acceptez pas' +
  ' ces\ncookies, nous ne pourrons pas savoir quand vous aurez réalisé votre' +
  ' visite sur notre\nsite web, et nous ne pourrons pas mesurer sa' +
  ' performance (Google Analytics,\nFacebook).';
const BTN_TEXT_MODAL_CUSTOMIZE = 'Personnaliser mes préférences de cookies';
const BTN_TEXT_MODAL_SAVE = 'Enregistrer les paramètres';
const BTN_TEXT_ACCEPT = 'Tout autoriser';
const BTN_TEXT_DECLINE = 'Tout refuser';
const TEXT_MODAL_1 = 'Nous utilisons des cookies sur notre site internet' +
  ' afin d’améliorer votre expérience de navigation et réaliser\ndes' +
  ' statistiques de visites. Pour plus d’informations sur nos cookies' +
  ' utilisés, ';
const TEXT_MODAL_LINK = 'cliquez ici';
const TEXT_MODAL_2 = '.';
const TEXT_MODAL_3 = `Cliquez sur « ${BTN_TEXT_ACCEPT} » pour accepter` +
  ' tous les cookies et vous rendre directement sur le site,\nou sur' +
  ` « ${BTN_TEXT_MODAL_CUSTOMIZE} » pour n’autoriser que les cookies` +
  ' strictement nécessaires.';
const INDICATOR_PERF_ON = 'Actifs';
const INDICATOR_PERF_OFF = 'Inactifs';
const INDICATOR_NECESSARY = 'Toujours Actifs';

const CookiesModal = ({ onSave }) => {
  const [page, setPage] = useState(0); // 0: Gestion, 1: Préférence
  const [tab, setTab] = useState(0); // 0: Nécessaire, 1: Performance
  const [isPerfActive, setIsPerfActive] = useState(
    cookieManager().hasPerformanceCookie()
  );

  const saveParamsAndExit = ({ perf }) => {
    cookieManager().setNecessaryCookie();
    if (perf) {
      cookieManager().setPerformanceCookie();
    } else {
      cookieManager().removePerformanceCookie();
    }
    onSave();
  };

  const toggleButtonIsPerfActive = (
    <Switch
      checked={isPerfActive}
      onChange={ () => setIsPerfActive(!isPerfActive) }
      checkedChildren={
        <img className={'mt-1.5'} src={iconCheck} alt={'icon-check'}/>
      }
    />
  );

  const nameAndIndexToTab = (name, index) => (
    <div
      key={index}
      className={classNames('f-cookies-modal-tab', {
        'is-currently-active': R.equals(tab, index)
      })}
      onClick={ () => setTab(index) }
    >
      <span className={classNames({ 'f-shrink': R.length(name) > 25 })}>
        {name}
      </span>
    </div>
  );

  const buttonSave = (
    <div className='f-cookies-modal-content-btn'
      onClick={ () => saveParamsAndExit({ perf: isPerfActive }) }
    >
      {BTN_TEXT_MODAL_SAVE}
    </div>
  );
  const buttonCustomize = (
    <div className='f-cookies-modal-content-btn'
      onClick={ () => setPage(1) }
    >
      {BTN_TEXT_MODAL_CUSTOMIZE}
    </div>
  );
  const buttonAccept = (
    <button className='f-button f-button-coral f-small-btn'
      onClick={ () => saveParamsAndExit({ perf: true }) }
    >
      {BTN_TEXT_ACCEPT}
    </button>
  );
  const buttonDecline = (
    <button className='f-button f-button-coral-reverse f-small-btn'
      onClick={ () => saveParamsAndExit({ perf: false }) }
    >
      {BTN_TEXT_DECLINE}
    </button>
  );

  const getTabText = () => (
    <div className='f-cookies-modal-text'>
      { R.equals(tab, 0) && <>{TEXT_MODAL_NECESSARY}</> }
      { R.equals(tab, 1) && <>{TEXT_MODAL_PERFORMANCE}</> }
    </div>
  );

  const getMainText = () => (
    <div className='f-cookies-modal-text'>
      <p>
        <span>{TEXT_MODAL_1}</span>
        <Link to={PAGE_DONNEES_PERSONNELLES}>
          {TEXT_MODAL_LINK}
        </Link>
        <span>{TEXT_MODAL_2}</span>
      </p>
      <p>
        {TEXT_MODAL_3}
      </p>
    </div>
  );

  const getTitle = () => (
    <div className='f-cookies-modal-title'>
      { R.equals(page, 0) && (
        <span>{TITLE_MODAL_1}</span>
      )}
      { R.equals(page, 1) && (
        <span className={'f-shrink'}>{TITLE_MODAL_2}</span>
      )}
    </div>
  );

  const getTabs = () => R.equals(page, 1) && (
    <div className='f-cookies-modal-tabs'>
      { RA.mapIndexed(nameAndIndexToTab,
        [TITLE_MODAL_NECESSARY, TITLE_MODAL_PERFORMANCE])
      }
    </div>
  );

  const getTabHeader = () => R.equals(page, 1) && (
    <div className='f-cookies-modal-content-header'>
      { R.equals(tab, 0) && (
        <>
          <div className='f-cookies-modal-content-header-classic'>
            <span className={'f-shrink'}>{TITLE_MODAL_NECESSARY}</span>
          </div>
          <div className='f-cookies-modal-content-header-classic'>
            {INDICATOR_NECESSARY}
          </div>
        </>
      )}
      { R.equals(tab, 1) && (
        <>
          <div className='f-cookies-modal-content-header-classic'>
            {TITLE_MODAL_PERFORMANCE}
          </div>
          <div className='f-cookies-modal-perf-indicator'>
            <p className={classNames('f-cookies-modal-perf-indicator-text',
              { 'is-perf-not-active': !isPerfActive }
            )}>
              { isPerfActive ? INDICATOR_PERF_ON : INDICATOR_PERF_OFF }
            </p>
            {toggleButtonIsPerfActive}
          </div>
        </>
      )}
    </div>
  );

  const getBody = () => (
    <div className='f-cookies-modal-content-body'>
      { R.equals(page, 0) && getMainText() }
      { R.equals(page, 1) && getTabText() }
    </div>
  );

  const getFooter = () => (
    <div className='f-cookies-modal-content-footer'>
      { R.equals(page, 0) && <>{buttonCustomize}</> }
      { R.equals(page, 1) && <>{buttonSave}</> }
      { buttonAccept }
      { buttonDecline }
    </div>
  );

  const getContent = () => (
    <div className='f-cookies-modal-content'>
      { getTabHeader() }
      { getBody() }
      { getFooter() }
    </div>
  );

  return (
    <Modal show={ true } className={'f-modal-window-cookies'} overhead={false}>
      <div className='f-cookies-modal-container'>
        { getTitle() }
        <div className='f-cookies-modal-body'>
          { getTabs() }
          { getContent() }
        </div>
      </div>
    </Modal>
  );
};

CookiesModal.propTypes = {
  onSave: PropTypes.func.isRequired
};

export default CookiesModal;
