// These constants are mirrored in server/controllers/document/helper.js
export const DOCUMENT_TYPE_ADHESION = 'DOCUMENT_ADHESION';
export const DOCUMENT_TYPE_ATTESTATION = 'ATTESTATION';
export const DOCUMENT_TYPE_ECHEANCIER = 'ECHEANCIER';
export const DOCUMENT_TYPE_GUIDE = 'DOCUMENT_GUIDE';
export const DOCUMENT_TYPE_NOTICE = 'DOCUMENT_NOTICE';
export const DOCUMENT_TYPE_PROOF = 'JUSTIFICATIF_DOMICILE';

export const DOCUMENT_SUBTYPE_ATTESTATION_GENERATED = 'ATTESTATION_GENERATED';

// documents statiques
export const STATIC_DOC_TYPE_FICHE_CONCIERGERIE = 'fiche_conciergerie';
export const STATIC_DOC_TYPE_FICHE_CONDITIONS_RESILIATION = 'modalites_resiliation';
export const STATIC_DOC_TYPE_TUTORIEL_ONBOARDING = 'tutoriel_onboarding';

export const DOCUMENT_STATUS_NEW = 'NEW';
export const DOCUMENT_STATUS_TOUCHED = 'TOUCHED';

export const FILE_SOURCE_CMS = 'cms';
export const FILE_SOURCE_FILE_STORAGE = 'file-storage';

// Taille max pour une pièce jointe/document téléchargé: 20 Mo
export const MAX_SIZE_BYTES = 20000000;

export const SECTION_TYPES = [
  {
    sectionType: DOCUMENT_TYPE_ADHESION,
    references: {
      sectionNumber: 's1',
      name: 'Documents d\'adhésion'
    },
    title: 's1'
  },
  {
    sectionType: DOCUMENT_TYPE_ATTESTATION,
    references: {
      sectionNumber: 's2',
      name: 'Attestations'
    },
    title: 's2'
  },
  {
    sectionType: DOCUMENT_TYPE_ECHEANCIER,
    references: {
      sectionNumber: 's3',
      name: 'Échéanciers'
    },
    title: 's3'
  },
  {
    sectionType: DOCUMENT_TYPE_GUIDE,
    references: {
      sectionNumber: 's4',
      name: 'Guides'
    },
    title: 's4'
  },
  {
    sectionType: DOCUMENT_TYPE_NOTICE,
    references: {
      sectionNumber: 's5',
      name: 'Notices'
    },
    title: 's5'
  }
];
