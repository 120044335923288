import React from 'react';
import { Block } from '@@components';
import { Link, useHistory } from 'react-router-dom';
import { useAdherent, useKeycloakSession } from '@@hooks';
import ProfessionalInformation from '@@pages/account/ProfessionalInformation';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';
import * as R from 'ramda';
import { filterActiveContracts } from '@@lib/contracts';
import { configMA, sendMAEventIfDefined } from '@@lib/ma';

const EditInfosProPrimaryBlock = () => {
  const { user } = useKeycloakSession();
  const { adherent } = useAdherent(user.customerId, ['professionalInformation', 'contracts']);
  const history = useHistory();
  const activeContracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  const onClickUpdateProInfo = () => {
    sendGAEventIfDefined(configGA.CONTRACT_PENSION_HOSPI_UPDATE_PRO_INFO);
    sendMAEventIfDefined(configMA.CONTRACT_PENSION_HOSPI_UPDATE_PRO_INFO);
  };
  return (
    <Block>
      <div className={'f-contract-primary-block'}>
        <div className={'f-contract-primary-title'}>
          {'Mes informations professionnelles'}
        </div>

        <ProfessionalInformation
          professionalInformation={adherent.professionalInformation}
          contracts={activeContracts}
          onEdit={() => history.push('/mesinformations/modifier/informationsProfessionnelles')}
          fitToGrid={false}
        />

        <div className={'f-contract-primary-buttons'}>
          <Link className={'f-button f-button-coral'}
            to={'/messagerie/ouvrir?action=02'}
            onClick={onClickUpdateProInfo}
          >
            {'Modifier mes informations professionnelles'}
          </Link>
        </div>
      </div>
    </Block>
  );
};

export default EditInfosProPrimaryBlock;
