/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DiscussionFilterInput = {|
  id?: ?string,
  recipient?: ?string,
  sender?: ?string,
  status?: ?$ReadOnlyArray<?string>,
|};
export type PaginateInput = {|
  limit?: ?number,
  page?: ?number,
|};
export type QAllDiscussionsQueryVariables = {|
  filter?: ?DiscussionFilterInput,
  paginate: PaginateInput,
|};
export type QAllDiscussionsQueryResponse = {|
  +allDiscussions: ?{|
    +id: ?string,
    +data: ?$ReadOnlyArray<?{|
      +id: string,
      +status: ?string,
      +title: ?string,
      +dates: ?{|
        +creation: ?string,
        +lastUpdate: ?string,
      |},
      +allMessages: ?{|
        +data: ?$ReadOnlyArray<?{|
          +attachments: ?$ReadOnlyArray<?{|
            +id: ?string
          |}>
        |}>
      |},
    |}>,
    +pagination: ?{|
      +total: number,
      +limit: number,
      +page: number,
      +pages: number,
    |},
  |}
|};
export type QAllDiscussionsQuery = {|
  variables: QAllDiscussionsQueryVariables,
  response: QAllDiscussionsQueryResponse,
|};
*/


/*
query QAllDiscussionsQuery(
  $filter: DiscussionFilterInput
  $paginate: PaginateInput!
) {
  allDiscussions(filter: $filter, paginate: $paginate) {
    id
    data {
      id
      status
      title
      dates {
        creation
        lastUpdate
      }
      allMessages {
        data {
          attachments {
            id
          }
          id
        }
      }
    }
    pagination {
      total
      limit
      page
      pages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "paginate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "paginate",
    "variableName": "paginate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Dates",
  "kind": "LinkedField",
  "name": "dates",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Attachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Pagination",
  "kind": "LinkedField",
  "name": "pagination",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "limit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAllDiscussionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedDiscussions",
        "kind": "LinkedField",
        "name": "allDiscussions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Discussion",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaginatedMessages",
                "kind": "LinkedField",
                "name": "allMessages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAllDiscussionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PaginatedDiscussions",
        "kind": "LinkedField",
        "name": "allDiscussions",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Discussion",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PaginatedMessages",
                "kind": "LinkedField",
                "name": "allMessages",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Message",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a58c6d4a296f5a0245bfcace79dcc39",
    "id": null,
    "metadata": {},
    "name": "QAllDiscussionsQuery",
    "operationKind": "query",
    "text": "query QAllDiscussionsQuery(\n  $filter: DiscussionFilterInput\n  $paginate: PaginateInput!\n) {\n  allDiscussions(filter: $filter, paginate: $paginate) {\n    id\n    data {\n      id\n      status\n      title\n      dates {\n        creation\n        lastUpdate\n      }\n      allMessages {\n        data {\n          attachments {\n            id\n          }\n          id\n        }\n      }\n    }\n    pagination {\n      total\n      limit\n      page\n      pages\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '09e0ffb2a47c9397fe9fa4ead5b38abd';

module.exports = node;
