import { graphql } from 'react-relay';

const QMAnalyticsConfigQuery = graphql`
  query QMAnalyticsConfigQuery {
    configMa {
      key
    }
  }
`;

export {
  QMAnalyticsConfigQuery
};
