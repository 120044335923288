import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { ErrorMessage } from '@@components';
import BackButton from '@@components/BackButton';

const RA = require('ramda-adjunct');

const Step6 = ({
  id,
  phoneNumber,
  onDone,
  subscriberId,
  forgotPasswordTunnel,
  handlePreviousStep,
  setSkipPhoneValidation,
  skipNumberValidation
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('phoneNumberToVerify');

  React.useEffect(() => {
    if (fetching.isDone()) {
      onDone({
        id,
        payload: fetching.getPayload()
      });
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  React.useEffect(() => {
    setErrorMessage(fetching.getError());
  }, [fetching.getError()]);

  const handlePreviousStepBtn = () => {
    setSkipPhoneValidation(true);
    skipNumberValidation();
  };

  const handleSubmit = (val) => {
    onFormsSubmit(val);
    setSkipPhoneValidation(false);
  };

  const formsBlock = (
    <Forms
      formsData={formsData}
      columns={1}
      onChange={() => fetching.reset()}
      onSubmit={handleSubmit}
      defaultValues={{
        subscriberId,
        phoneNumber
      }}
      submitSection={() => (
        <>
          <div className="f-connect-bottom-buttons">
            {forgotPasswordTunnel && <BackButton onClick={handlePreviousStep}/>}
            <button
              className="f-button f-button-coral"
              disabled={fetching.isFetching()}
              type="submit"
            >
              {'Valider le téléphone'}
            </button>
            {
              forgotPasswordTunnel
                ? <button
                  onClick={handlePreviousStepBtn}
                  className="f-button f-button-coral-reverse"
                  type="button"
                >
                  {'Suivant'}
                </button>
                : <Link
                  className="f-button f-button-coral-reverse"
                  to="/"
                >
                  {'Se connecter'}
                </Link>
            }
          </div>
          {RA.isNotNil(errorMessage) && <ErrorMessage value={errorMessage} />}
        </>
      )}
    />
  );

  return (
    <div className='f-connect-container'>
      <div className='f-connect-forms-block'>
        <div className={'f-connect-title'}>
          {'Validez votre numéro de téléphone'}
        </div>
        <div className={'f-connect-intro'}>
          <p>
            {'Vous avez la possibilité de valider votre numéro de téléphone' +
              ' mobile. Celui-ci vous servira alors d\'identifiant de' +
              ' connexion. Cette étape est facultative, vous pouvez à tout' +
              ' moment valider votre numéro de mobile depuis votre profil.'}
          </p>
          <p>
            {' Vous pouvez dès à présent vous connecter sur votre' +
              ' Espace Adhérent APPA si vous le souhaitez.'}
          </p>
        </div>
        {formsBlock}
      </div>
    </div>
  );
};

Step6.propTypes = {
  id: PropTypes.string,
  onDone: PropTypes.func,
  handlePreviousStep: PropTypes.func,
  phoneNumber: PropTypes.string,
  subscriberId: PropTypes.string,
  forgotPasswordTunnel: PropTypes.bool.isRequired,
  setSkipPhoneValidation: PropTypes.func.isRequired,
  skipNumberValidation: PropTypes.func.isRequired
};

export default Step6;
