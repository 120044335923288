import {
  commitMutation,
  graphql
} from 'react-relay';

const mutation = graphql`
mutation SignOutMutation {
  signOut {
    ok
    error
    userSession {
      id
      username
    }
  }
}
`;

export default async (environment, done) => {
  const variables = {};

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ signOut }) => {
        const { ok, error } = signOut;
        done(ok, error, signOut.userSession);
      },
      onError: err => console.error(err)
    }
  );
};
