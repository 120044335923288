import {
  commitMutation,
  graphql
} from 'react-relay';

const mutation = graphql`
    mutation ReSendOtpSmsMutation($phoneNumber: String!, $subscriberId: String!) {
      reSendOtpSms(phoneNumber: $phoneNumber, subscriberId: $subscriberId) {
            ok
            error
            otp
        }
    }
`;

export default async (environment, { phoneNumber, subscriberId }, done) => {
  const variables = {
    phoneNumber,
    subscriberId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ reSendOtpSms }) => {
        const { ok, error, otp } = reSendOtpSms;
        done(ok, error, otp);
      },
      onError: err => console.error(err)
    }
  );
};
