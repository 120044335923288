/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QMAnalyticsConfigQueryVariables = {||};
export type QMAnalyticsConfigQueryResponse = {|
  +configMa: ?{|
    +key: ?string
  |}
|};
export type QMAnalyticsConfigQuery = {|
  variables: QMAnalyticsConfigQueryVariables,
  response: QMAnalyticsConfigQueryResponse,
|};
*/


/*
query QMAnalyticsConfigQuery {
  configMa {
    key
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MAnalytics",
    "kind": "LinkedField",
    "name": "configMa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QMAnalyticsConfigQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QMAnalyticsConfigQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "7c84bc00aab40ee5416a834a77567e98",
    "id": null,
    "metadata": {},
    "name": "QMAnalyticsConfigQuery",
    "operationKind": "query",
    "text": "query QMAnalyticsConfigQuery {\n  configMa {\n    key\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9df60d369fa049a207a9992d67166a89';

module.exports = node;
