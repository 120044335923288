/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateAdherentEmailInput = {|
  email: string,
  subscriberId: string,
|};
export type UpdateAdherentEmailMutationVariables = {|
  input: UpdateAdherentEmailInput
|};
export type UpdateAdherentEmailMutationResponse = {|
  +updateAdherentEmail: ?{|
    +ok: boolean,
    +error: ?string,
    +otp: ?string,
    +email: ?string,
    +emailErrors: ?$ReadOnlyArray<?string>,
  |}
|};
export type UpdateAdherentEmailMutation = {|
  variables: UpdateAdherentEmailMutationVariables,
  response: UpdateAdherentEmailMutationResponse,
|};
*/


/*
mutation UpdateAdherentEmailMutation(
  $input: UpdateAdherentEmailInput!
) {
  updateAdherentEmail(input: $input) {
    ok
    error
    otp
    email
    emailErrors
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAdherentEmailMutationResponse",
    "kind": "LinkedField",
    "name": "updateAdherentEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailErrors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateAdherentEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateAdherentEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "915d23f0218494a4a5614ba3e6bf8fe9",
    "id": null,
    "metadata": {},
    "name": "UpdateAdherentEmailMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateAdherentEmailMutation(\n  $input: UpdateAdherentEmailInput!\n) {\n  updateAdherentEmail(input: $input) {\n    ok\n    error\n    otp\n    email\n    emailErrors\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '219f9fd0699cdee8d46653f68ad98eb6';

module.exports = node;
