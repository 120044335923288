import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './Home';
import Signature from './Signature';

const R = require('ramda');

const paths = (root) => (k) => {
  const config = {
    discussion: {
      viewPath: (discussionId) => {
        if (R.isNil(discussionId)) return `${root}`;
        return `${root}/${discussionId}`;
      },
      signaturePath: (discussionId) => {
        if (R.isNil(discussionId)) return `${root}`;
        return `${root}/${discussionId}/signature`;
      },
      newMessagePath: `${root}/ouvrir`,
      cancelPath: root
    }
  };

  if (R.isNil(k)) return config;
  return R.prop(k, config);
};

const Index = ({ history, match }) => {
  const path = v => `${match.url}${R.when(R.isNil, R.always(''), v)}`;
  const pathConfig = paths(match.url);
  return (
    <Switch>
      <Route exact path={pathConfig('discussion').viewPath('')}
        render={({ match }) =>
          <Home pathConfig={pathConfig('discussion')}
            match={match} history={history}/>}
      />
      <Route exact path={pathConfig('discussion').viewPath(':discussionId')}
        render={({ match }) =>
          <Home pathConfig={pathConfig('discussion')}
            match={match} history={history}/>}
      />
      <Route exact path={pathConfig('discussion').signaturePath(':discussionId')}
        render={({ match }) =>
          <Signature pathConfig={pathConfig('discussion')}
            match={match} history={history}/>}
      />
      < Redirect to={path()}/>
    </Switch>
  );
};

export default Index;
