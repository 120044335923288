import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { BreadCrumb, Loading } from '@@components';
import { Link, useHistory } from 'react-router-dom';
import useForms from '@@formsHook';
import { maritalStatusOptions } from '@@data';
import Forms from '@@components/forms';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { contractHealthOption } from '@@enum';
import { filterActiveContracts } from '@@lib/contracts';

const TEXT_1 = 'Une évolution de votre statut marital peut avoir un impact' +
  ' sur votre contrat (bénéficiaire de votre contrat,' +
  ' désignation de bénéficiaire prévoyance..), n\'hésitez pas à' +
  ' nous contacter via votre messagerie ou par téléphone.';
const TEXT_2 = 'Vous avez souscrit à un contrat santé en formule isolée.' +
  ' Si vous souhaitez y intégrer votre conjoint(e), vous avez la possibilité' +
  ' de formaliser votre demande via le formulaire de contact.';

const EditFamilyStatus = ({ pathConfig }) => {
  const { user } = useKeycloakSession();
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('adherentFamilyStatus');

  const { adherent } = useAdherent(user.customerId, ['civilStatus', 'contracts']);
  const history = useHistory();

  useEffect(() => {
    if (fetching.isDone()) {
      history.push(pathConfig.cancelPath);
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  if (R.isNil(adherent.civilStatus) || R.isNil(adherent.contracts)) {
    return <Loading/>;
  }

  const hasOptionAndNonEmptyGuarantees = option => R.allPass([
    R.pathEq(['detail', 'health', 'option'], option),
    R.pathSatisfies(RA.isNotNilOrEmpty, ['detail', 'health', 'guarantee'])
  ]);
  const hasHealthOption = option => R.any(hasOptionAndNonEmptyGuarantees(option));

  const hasPension = R.compose(
    R.any(RA.isNotNilOrEmpty),
    R.map(R.pathOr([], ['detail', 'pension', 'guarantee']))
  );

  const contracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);
  const hasOptionFamilleOrIsPension = R.either(hasHealthOption(contractHealthOption.FAMILLE), hasPension)(contracts);
  const hasOptionIsole = hasHealthOption(contractHealthOption.ISOLE)(contracts);

  const breadCrumb = <BreadCrumb
    rootPath={'mesinformations'}
    rootName={'Mes informations personnelles'}
    location={'Modification de votre situation familiale'}/>;

  const title = (
    <div className='subsection-title'>
      {'Modification de vos informations familiales'}
    </div>
  );

  const swornLabel = (
    <div className='f-edit-family-status-sworn-label'>
      {'J\'atteste sur l\'honneur l\'exactitude des informations' +
      ' renseignées.'}
    </div>
  );

  const blockOptionFamilleOrIsPension = hasOptionFamilleOrIsPension && (
    <div>
      <p className='f-edit-family-status-text'>
        { TEXT_1 }
      </p>
      <Forms
        formsData={formsData}
        alignedLabels={[null, null, swornLabel]}
        columns={1}
        defaultValues={{
          ...adherent.civilStatus,
          swornStatement: false,
          subscriberId: user.customerId
        }}
        options={{
          maritalStatus: maritalStatusOptions
        }}
        onChange={() => fetching.reset()}
        onSubmit={onFormsSubmit}
        submitSection={() => (
          <div className='f-edit-family-status-buttons mt-8'>
            <Link
              className='f-button f-button-grey f-edit-family-status-btn'
              to={pathConfig.cancelPath}
            >
              {'Annuler'}
            </Link>
            <button
              className='f-button f-button-coral f-edit-family-status-btn'
              disabled={fetching.isFetching()}
              type="submit"
            >
              {'Enregistrer'}
            </button>
          </div>
        )}
      />
    </div>
  );
  const blockOptionIsole = hasOptionIsole && (
    <div>
      <p className='f-edit-family-status-text'>
        { TEXT_2 }
      </p>
      <div className='f-edit-family-status-buttons'>
        <Link
          className='f-button f-button-coral f-edit-family-status-btn'
          to={'/messagerie/ouvrir?action=01-04'}>
          {'Modifier mes informations personnelles'}
        </Link>
      </div>
    </div>
  );

  return (
    <div className='f-base-page-container'>
      <div className='f-page'>
        <div className='f-page-body'>
          { breadCrumb }
          <div className='f-page-content-header'>
            { title }
          </div>
          <div className='f-edit-family-status-content'>
            { blockOptionFamilleOrIsPension }
            { blockOptionIsole }
          </div>
        </div>
      </div>
    </div>
  );
};

EditFamilyStatus.propTypes = {
  pathConfig: PropTypes.shape({
    cancelPath: PropTypes.string
  })
};

export default EditFamilyStatus;
