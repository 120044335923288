import React from 'react';
import ReactMarkdown from 'react-markdown';
import { ContactBlock, Page } from '@@components';
import { map, propOr } from 'ramda';
import { bigCornerTurquoise, bigCornerYellow, logoBesse, logoEurop, logoMercer, logoSanteClair } from '@@images';
import { useCms } from '@@hooks';
import { configGA } from '@@lib/ga';

const NOT_TAXED = '(appel non surtaxé)';
// non-breakable space '\u00A0' is used to prevent a line break on the
// ending character character '*' on some titles
const BLOCKS_CONTENT = [{
  title: 'Gestionnaire contrats APPA',
  img: logoBesse,
  phoneNumbers: [{ num: 'appaPhoneNumber', caption: NOT_TAXED, ga: configGA.CONTACTS_APPA }]
}, {
  title: 'Gestionnaire santé Mercer',
  img: logoMercer,
  phoneNumbers: [{ num: 'mercerPhoneNumber', caption: NOT_TAXED, ga: configGA.CONTACTS_MERCER }]
}, {
  title: 'Santéclair',
  img: logoSanteClair,
  phoneNumbers: [{ num: 'santeclairPhoneNumber', caption: NOT_TAXED, ga: configGA.CONTACTS_SANTECLAIR }]
}, {
  title: 'Europ Assistance',
  img: logoEurop,
  phoneNumbers: [
    {
      num: 'europAssistanceMetropolePhoneNumber',
      caption: '(en France métropolitaine)',
      ga: configGA.CONTACTS_ASSISTANCE_METRO
    },
    { num: 'europAssistanceDROMPhoneNumber', caption: '(pour les DROM)', ga: configGA.CONTACTS_ASSISTANCE_DROM }
  ]
}];

// eslint-disable-next-line react/display-name
const blockContentToJSX = contact => ({ title, img, phoneNumbers }) => {
  return <ContactBlock
    key={title}
    title={title}
    img={img}
    phoneNumbers={phoneNumbers}
    cmsContacts={contact}
  />;
};

const Contacts = () => {
  const { contact } = useCms(['contact']);
  return (
    <Page pageTitle={'Contact'}>
      <div className="f-contact-phone-grid">
        {map(blockContentToJSX(contact), BLOCKS_CONTENT)}
      </div>
      <div className="f-address-block relative">
        <img className="f-filter-corner f-filter-corner-upLeft" src={bigCornerTurquoise}/>
        <img className="f-filter-corner f-filter-corner-downRight" src={bigCornerYellow}/>
        <div className="f-contact-block-title">{'Notre adresse'}</div>
        <div className="f-contact-block-text">
          <div>
            <ReactMarkdown>{propOr('', 'appaAddress', contact)}</ReactMarkdown>
          </div>
          <div>
            <p className="font-bold">{'Horaires d\'ouverture\u00A0:'}</p>
            <p>{'Du lundi au Jeudi de 9h à 12h30 et de 13h30 à 18h'}</p>
            <p>{'et le Vendredi de 9h à 12h30 et de 13h30 à 17h'}</p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Contacts;
