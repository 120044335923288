import React from 'react';
import { Controller } from 'react-hook-form';
import { Attachments, UploadFile } from '@@components/input';
import * as RA from 'ramda-adjunct';

const Input = ({ register, field, control, getValues, errors }) => {
  const onChangeHandler = onChange => value => {
    const newValue = RA.isNotNilOrEmpty(value) ? value : null;
    onChange(newValue);
  };

  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={null}
      rules={{ required: field.required }}
      render={({ name, onChange, value }) => (
        <div className={'f-upload-file-container'}>
          <UploadFile
            uploadUrl="/upload/file"
            onChange={onChangeHandler(onChange)}
          />
          {/* <p>{JSON.stringify(value, null, 2)}</p> */}
          <Attachments
            style={'proof'}
            files={RA.isNilOrEmpty(value) ? [] : value}
            onChange={onChangeHandler(onChange)}
          />
        </div>
      )}/>
  );
};

export default Input;
