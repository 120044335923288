import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QReferencesQuery = graphql`
  query QReferencesQuery {
    references {
        keycloak {
            keycloakAuthUrl
            keycloakClientId
            keycloakRealm
        }
    }
  }
`;

const QReferences = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QReferencesQuery}
      args={args}
      fetchPolicy="network"
      mockData={mockData}
    >
      {populateChildren(['references'])(children, childDataProp)}
    </Query>
  );
};

export default QReferences;

export {
  QReferencesQuery
};
