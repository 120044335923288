const options = [{
  value: 'celibataire',
  label: 'Célibataire'
}, {
  value: 'concubin',
  label: 'Concubin(e)'
}, {
  value: 'divorce',
  label: 'Divorcé(e)'
}, {
  value: 'marie',
  label: 'Marié(e)'
}, {
  value: 'pacse',
  label: 'Pacsé(e)'
}, {
  value: 'veuf',
  label: 'Veuf / Veuve'
}];

export default options;
