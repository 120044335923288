import React from 'react';
import { SelectOneOption } from '@@components/input';
import classNames from 'classnames';
import { binIcon, paperclipIcon } from '@@images';
import PropTypes from 'prop-types';

const RA = require('ramda-adjunct');
const R = require('ramda');

const Attachments = ({
  style,
  files,
  onChange,
  documentTypeOptions
}) => {
  const $documentTypeOptions = R.compose(
    R.map(R.applySpec({
      label: R.prop('label'),
      value: R.prop('id')
    })),
    R.defaultTo([])
  )(documentTypeOptions);

  const $onDocumentTypeChange = (uploadId) => ({ value }) => {
    onChange(R.map((attachment) => {
      if (attachment.uploadId === uploadId) {
        return R.assoc('documentType', value, attachment);
      }
      return attachment;
    })(files));
  };

  const onDeleteClick = uploadId => () => {
    onChange(
      R.reject(R.propEq('uploadId', uploadId), files)
    );
  };

  const showSelect = !RA.isNilOrEmpty(documentTypeOptions);

  const isProof = R.equals(style, 'proof');
  const getClassContainer = () => {
    if (isProof) {
      return classNames('f-proof');
    }
    return classNames('f-attachments');
  };
  const getClassItem = () => {
    if (isProof) {
      return classNames('f-attachment-item-label', 'flex-grow-1', 'break-all');
    }
    return classNames('f-attachment-item-label', {
      'col-span-5': showSelect,
      'col-span-10': !showSelect
    });
  };
  const getClassDelete = () => {
    if (isProof) {
      return classNames('f-attachment-item-delete', 'flex-shrink-0');
    }
    return classNames('f-attachment-item-delete');
  };

  return (
    <ul className={getClassContainer()}>
      {files.map(({ fileName, uploadId, documentType }) => (
        <li key={uploadId}>
          <div className={getClassItem()}>
            <img src={paperclipIcon} alt={'paperclip'}/>
            <div className="px-2">{fileName}</div>
          </div>
          {showSelect && (
            <div className="col-span-5">
              <SelectOneOption
                options={$documentTypeOptions}
                value={R.find(R.propEq('value', documentType), $documentTypeOptions)}
                onChange={$onDocumentTypeChange(uploadId)}
                placeholder={'Type de document...'}
              />
            </div>
          )}
          <div className={getClassDelete()}
            onClick={onDeleteClick(uploadId)}>
            <img src={binIcon} className="h-4 w-4" alt={'bin'}/>
          </div>
        </li>
      ))
      }
    </ul >
  );
};

export default Attachments;

Attachments.defaultProps = {
  documentTypeOptions: null,
  className: ''
};

Attachments.propTypes = {
  style: PropTypes.oneOf(['proof', 'attachments']),
  onChange: PropTypes.func,
  documentTypeOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    id: PropTypes.string
  })),
  files: PropTypes.array
};
