import React, { useState } from 'react';
import ColoredIcon from '../../components/ColoredIcon';
import { house, messagingLogo, miniDocumentsLogo } from '@@images';
import Modal from '@@components/modale';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import classNames from 'classnames';
import UpdateAdherentFirstConnectionStatusMutation from '../../_graphql/mutations/UpdateAdherentFirstConnectionStatusMutation';
import { useRelayEnvironment } from 'react-relay';
import PropTypes from 'prop-types';

const TutoFirstConnexionModal = ({ subscriberId }) => {
  const environment = useRelayEnvironment();

  const [show, setShow] = useState(true);

  const onSubmit = () => {
    UpdateAdherentFirstConnectionStatusMutation(environment, { status: false, subscriberId }, () => {
      setShow(false);
    });
  };

  const stepsContent = [
    {
      stepId: '1',
      nextStep: '2',
      previousStep: null,
      title: 'Bienvenue\nsur votre nouvel Espace Adhérent APPA !',
      picture: house,
      text: ''
    },
    {
      stepId: '2',
      nextStep: '3',
      previousStep: '1',
      title: 'Accédez à votre Messagerie',
      picture: messagingLogo,
      text: 'Des questions ? Des documents à nous envoyer ?' +
        '\nVotre messagerie vous permet de communiquer' +
        '\nplus facilement, rapidement et en toute sécurité' +
        '\navec un gestionnaire dédié et à votre écoute.'
    },
    {
      stepId: '3',
      nextStep: '4',
      previousStep: '2',
      title: 'Suivez vos remboursements',
      picture: miniDocumentsLogo,
      text: 'Consultez le détails de vos remboursements santé' +
        '\nen quelques clics.'
    },
    {
      stepId: '4',
      nextStep: null,
      previousStep: '3',
      title: 'Actualisez votre situation',
      picture: miniDocumentsLogo,
      text: 'Modifiez vos informations personnelles directement' +
        '\nen ligne (adresse postale, numéro de téléphone, email…).' +
        '\nNos équipes gestion se chargeront du reste !'
    }
  ];

  const [currentStep, setCurrentStep] = useState('1');
  const [title, setTitle] = useState(stepsContent[0].title);
  const [children, setChildren] = useState({ picture: house, text: '' });

  const handleArrowButton = (currentStep, prevOrNext) => {
    const { previousStep, nextStep } = R.find(
      R.propEq('stepId', currentStep),
      stepsContent);
    const { title, picture, text } = R.find(
      R.propEq('stepId', R.equals(prevOrNext, 'next') ? nextStep : previousStep),
      stepsContent);
    setTitle(title);
    setChildren({ ...children, picture, text });
    setCurrentStep(R.equals(prevOrNext, 'next') ? nextStep : previousStep);
  };

  const footer = (
    <div>
      <div className="f-tuto-modal-block-footer">
        <div style={{ visibility: R.equals(currentStep, '1') ? 'hidden' : 'visible' }} >
          <button
            type="button"
            onClick={() => handleArrowButton(currentStep, 'prev')}
            className="f-tuto-modal-block-footer-button"
          >
            <div className="f-tuto-modal-block-footer-button-content">
              <ColoredIcon icon="arrow-down.svg" height={12} width={12}
                className="m-0" />
            </div>
          </button>
        </div>
        <div className="f-tuto-modal-block-footer-chip" >
          {
            R.equals(currentStep, '4') ? (
              <button
                className='f-button f-button-coral f-responsive-btn'
                type='button'
                onClick={onSubmit}
              >
                {'Découvrir mon espace'}
              </button>
            ) : (
              stepsContent.map(({ stepId }) => (
                <div
                  key={stepId}
                  className={`dot ${R.equals(stepId, currentStep) ? 'f-dot-turquoise' : 'f-dot-blue'}`}
                >
                  .
                </div>
              ))
            )
          }
        </div>
        <div style={{ visibility: R.equals(currentStep, '4') ? 'hidden' : 'visible' }} >
          <button
            type="button"
            onClick={() => handleArrowButton(currentStep, 'next')}
            className="f-tuto-modal-block-footer-button"
          >
            <div className="f-tuto-modal-block-footer-button-content">
              <ColoredIcon icon="arrow-up.svg" height={12} width={12}
                className="m-0" />
            </div>
          </button>
        </div>
      </div>
      <div className="flex justify-center" >
        <button
          className={classNames('f-tuto-modal-link', {
            invisible: R.equals(currentStep, '4')
          })}
          onClick={onSubmit}
          type="button"
        >
          {'Passer la présentation'}
        </button>
      </div>
    </div>
  );

  const hasText = RA.isNotNilOrEmpty(children.text);
  const blockBody = (
    <div className='f-tuto-modal-block-body'>
      <div className='f-tuto-modal-title'>
        { title }
      </div>
      <div className={classNames('f-tuto-modal-content', {
        'justify-start': hasText,
        'justify-center': !hasText
      })}>
        <div className={classNames({
          'f-tuto-modal-block-body-img-sm': hasText,
          'f-tuto-modal-block-body-img-lg': !hasText
        })}>
          <img src={ children.picture } alt='pic'/>
        </div>
        { hasText && (
          <div className='f-tuto-modal-block-body-text'>
            { children.text }
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Modal show={ show } footer={ footer } overhead={ false }
      className={ 'f-modal-window-tutorial' }
    >
      { blockBody }
    </Modal>
  );
};

TutoFirstConnexionModal.propTypes = {
  subscriberId: PropTypes.string.isRequired
};

export default TutoFirstConnexionModal;
