import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const CollabMessageSign = ({
  name,
  signature
}) => {
  return (
    <div className="mt-4">
      <div className={'f-messaging-signature-name'}>{name}</div>
      <ReactMarkdown className={'f-markdown-messaging-signature'}>
        {signature}
      </ReactMarkdown>
    </div>
  );
};

CollabMessageSign.propTypes = {
  name: PropTypes.string
};

export default CollabMessageSign;
