import React from 'react';
import { useKeycloakSession } from '@@hooks';
import {
  Route,
  Switch
} from 'react-router-dom';
import Home from '@@pages/home';

const R = require('ramda');

const ProtectedRoutes = () => {
  const { authenticated, onSignIn } = useKeycloakSession();
  React.useEffect(() => {
    if (!authenticated) onSignIn();
  }, []);
  return (
    <Switch>
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default ProtectedRoutes;
