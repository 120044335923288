/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'subscriberId',
    required: true,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'maritalStatus',
    required: true,
    input: 'toggleButtons',
    source: 'maritalStatus',
    label: "Vous êtes",
    placeholder: "Statut marital",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'swornStatement',
    required: true,
    input: 'switch',
    source: '__UNDEFINED__',
    label: "J'atteste sur l'honneur l'exactitude des informations renseignées",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;