/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'subscriberId',
    required: true,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'address1',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "N° et voie",
    placeholder: "N° et voie",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'address2',
    required: false,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Complément d'adresse 1",
    placeholder: "Appartement, résidence",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'address3',
    required: false,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Complément d'adresse 2",
    placeholder: "Lieu dit, service de distribution",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'zipCode',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Code postal",
    placeholder: "Code postal",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'city',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Ville",
    placeholder: "Ville",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'country',
    required: false,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Pays",
    placeholder: "Pays",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'pnd',
    required: false,
    input: 'hidden',
    source: '__UNDEFINED__',
    label: "__UNDEFINED__",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'attachment',
    required: true,
    input: 'file',
    source: '__UNDEFINED__',
    label: "Justificatif de domicile",
    placeholder: "Ajouter un justificatif de domicile",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;