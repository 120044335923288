import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import Modal from '@@components/modale';
import * as RA from 'ramda-adjunct';
import DiscussionConfirmationModal from './DiscussionConfirmationModal';

const getTextModal = () => 'Saisissez les informations ci-dessous pour' +
  ' être recontacté(e) par un de nos conseillers.' +
  '\nTous les champs sont obligatoires.';

const OtpNotReceiveModal = ({ show, onDisable, type, title, formName, formDefaultValues }) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms(formName);

  const [requestConfirmationText, setRequestConfirmationText] = React.useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);

  useEffect(() => {
    if (fetching.isDone()) {
      setRequestConfirmationText({ ok: true, error: false });
      setOpenConfirmationModal(true);
    }
    if (fetching.getError()) {
      setRequestConfirmationText({ ok: false, error: true });
      setOpenConfirmationModal(true);
    }
  }, [fetching.isDone(), fetching.getPayload(), fetching.getError()]);

  const isDisabled = fetching.isFetching();

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setRequestConfirmationText({});
  };

  return (
    <>
      <Modal show={show} onDisable={onDisable}
        className={RA.isNotEmpty(requestConfirmationText)
          ? 'f-window-messaging-confirm' : 'f-window-otp-not-receive'}
      >
        <div className='f-otp-not-receive-modal-container'>
          <div className='f-standard-modal-title'>
            { title }
          </div>
          <div className='f-standard-modal-intro'>
            { getTextModal() }
          </div>
          <div className='f-standard-modal-forms'>
            <Forms
              formsData={formsData}
              columns={2}
              onChange={fetching.reset}
              defaultValues={formDefaultValues}
              onSubmit={(val) => {
                onFormsSubmit(val);
                onDisable(false);
              }}
              submitSection={() => (
                <div>
                  <div className='f-standard-modal-buttons mt-8'>
                    <button className='f-button f-button-grey f-responsive-btn'
                      type='button'
                      disabled={isDisabled}
                      onClick={onDisable}
                    >
                      {'Annuler'}
                    </button>
                    <button className='f-button f-button-coral f-responsive-btn'
                      disabled={isDisabled}
                      type='submit'
                    >
                      {'Valider'}
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </Modal>
      <DiscussionConfirmationModal
        show={openConfirmationModal}
        onDisabled={handleCloseConfirmationModal}
        requestConfirmationText={requestConfirmationText}
      />
    </>
  );
};

OtpNotReceiveModal.propTypes = {
  show: PropTypes.bool,
  onDisable: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  formName: PropTypes.string.isRequired,
  formDefaultValues: PropTypes.object
};

export default OtpNotReceiveModal;
