import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QCmsHeaderFooterQuery = graphql`
  query QCmsHeaderFooterQuery {
    cms {
      header {
        phone
      }
      footer {
        phone
      }
    }
  }
`;

export {
  QCmsHeaderFooterQuery
};
