import { useEffect } from 'react';

/**
 * Calls onOutsideClick() when a click happen outside of the passed ref
 */
export const useOutsideClick = (ref, onOutsideClick) => {
  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};
