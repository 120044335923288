import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { typologies } from '@@enum';
import CotisationContent from '@@pages/contributions/components/CotisationContent';
import {
  cotisationHospiLogo,
  cotisationLiberalLogo,
  cotisationRetraiteLogo,
  cotisationRevenusAnnexesLogo
} from '@@images';
import { anonIban } from '@@lib/iban';

const typologyEq = typology => R.pathEq(['detail', 'typology'], typology);

export const COTISATION_TYPE_HOSPI = 'activite_hospitaliere';
export const COTISATION_TYPE_REVENU_ANNEXE = 'revenu_annexe';
export const COTISATION_TYPE_LIBERAL = 'activite_liberale';
export const COTISATION_TYPE_RETRAITE = 'contrat_sante';

// TODO Gérer le cas des revenus annexes après le retour de Christophe
const getCotisationTypes = R.cond([
  [typologyEq(typologies.PHHU), R.always(COTISATION_TYPE_HOSPI)],
  [typologyEq(typologies.INTERNE), R.always(COTISATION_TYPE_HOSPI)],
  [typologyEq(typologies.LIBERAL), R.always(COTISATION_TYPE_LIBERAL)],
  [typologyEq(typologies.RETRAITE), R.always(COTISATION_TYPE_RETRAITE)],
  [R.T, R.always('')]
]);

const cotisationConfig = {
  [COTISATION_TYPE_HOSPI]: {
    title: 'Cotisation activité hospitalière',
    img: cotisationHospiLogo
  },
  [COTISATION_TYPE_REVENU_ANNEXE]: {
    title: 'Cotisation additionnelle – Revenus annexes',
    img: cotisationRevenusAnnexesLogo
  },
  [COTISATION_TYPE_LIBERAL]: {
    title: 'Cotisation activité libérale',
    img: cotisationLiberalLogo
  },
  [COTISATION_TYPE_RETRAITE]: {
    title: 'Mon contrat retraite',
    img: cotisationRetraiteLogo
  }
};

const getHolder = R.compose(
  R.toLower,
  R.pathOr('', ['SEPAMandate', 'account', 'holder'])
);
const getIban = R.compose(
  anonIban,
  R.pathOr('', ['SEPAMandate', 'account', 'IBAN'])
);
// const getDebitDate = R.pathOr('', ['detail', 'directDebitDate']);

const mapContentConfig = getText => R.applySpec({
  text: getText,
  holder: getHolder,
  iban: getIban
});

const contentConfig = {
  PHHUNotDirectDebitNotHospital: mapContentConfig(R.always('Le mode de paiement de vos cotisations sur votre contrat est le chèque. Vous pouvez à tout instant modifier ce mode de règlement et passer en prélèvement automatique. N’hésitez pas à nous adresser votre demande via la messagerie.')),
  PHHUNotDirectDebit: mapContentConfig(R.always('Vos cotisations sont précomptées sur votre fiche de paie par votre centre hospitalier.')),
  NotPHHUNotDirectDebit: mapContentConfig(R.always('Le mode de paiement de vos cotisations sur votre contrat est le chèque. Vous pouvez à tout instant modifier ce mode de règlement et passer en prélèvement automatique. N’hésitez pas à nous adresser votre demande via la messagerie.')),
  DirectDebitAnnuel: mapContentConfig(R.always('Vos cotisations sont prélevées automatiquement une fois par an sur le compte suivant :')),
  DirectDebitHebdo: mapContentConfig(R.always('Vos cotisations prélevées automatiquement une fois par semaine sur le compte suivant :')),
  // DirectDebitMensuel: mapContentConfig(contract => `Vos cotisations prélevées automatiquement tous les ${getDebitDate(contract)} du mois sur le compte suivant :`),
  DirectDebitMensuel: mapContentConfig(R.always('Vos cotisations sont prélevées automatiquement tous les mois sur le compte suivant :')),
  DirectDebitQuotidien: mapContentConfig(R.always('Vos cotisations prélevées automatiquement tous les jours sur le compte suivant :')),
  DirectDebitSemestriel: mapContentConfig(R.always('Vos cotisations prélevées automatiquement tous les semestres sur le compte suivant :')),
  DirectDebitTrimestriel: mapContentConfig(R.always('Vos cotisations prélevées automatiquement tous les trimestres sur le compte suivant :')),
  defaultContent: mapContentConfig(R.always(''))
};

const directDebitByPeriodicityPredicate = periodicity => R.allPass([
  R.propEq('directDebit', true),
  R.propEq('periodicity', periodicity)
]);

const PHHUNotDirectDebitNotHospitalPredicate = hospital => R.allPass([
  R.propEq('directDebit', false),
  R.pathEq(['detail', 'typology'], typologies.PHHU),
  R.always(RA.isNilOrEmpty(hospital))
]);
const PHHUNotDirectDebitPredicate = R.allPass([
  R.propEq('directDebit', false),
  R.pathEq(['detail', 'typology'], typologies.PHHU)
]);
const NotPHHUNotDirectDebitPredicate = R.allPass([
  R.propEq('directDebit', false),
  RA.pathNotEq(['detail', 'typology'], typologies.PHHU)
]);
const DirectDebitAnnuelPredicate = directDebitByPeriodicityPredicate('Annuel');
const DirectDebitHebdoPredicate = directDebitByPeriodicityPredicate('Hebdomadaire');
const DirectDebitMensuelPredicate = directDebitByPeriodicityPredicate('Mensuel');
const DirectDebitQuotidienPredicate = directDebitByPeriodicityPredicate('Quotidien');
const DirectDebitSemestrielPredicate = directDebitByPeriodicityPredicate('Semestriel');
const DirectDebitTrimestrielPredicate = directDebitByPeriodicityPredicate('Trimestriel');

const getContent = hospital => R.cond([
  [PHHUNotDirectDebitNotHospitalPredicate(hospital), contentConfig.PHHUNotDirectDebitNotHospital],
  [PHHUNotDirectDebitPredicate, contentConfig.PHHUNotDirectDebit],
  [NotPHHUNotDirectDebitPredicate, contentConfig.NotPHHUNotDirectDebit],
  [DirectDebitAnnuelPredicate, contentConfig.DirectDebitAnnuel],
  [DirectDebitHebdoPredicate, contentConfig.DirectDebitHebdo],
  [DirectDebitMensuelPredicate, contentConfig.DirectDebitMensuel],
  [DirectDebitQuotidienPredicate, contentConfig.DirectDebitQuotidien],
  [DirectDebitSemestrielPredicate, contentConfig.DirectDebitSemestriel],
  [DirectDebitTrimestrielPredicate, contentConfig.DirectDebitTrimestriel],
  [R.T, contentConfig.defaultContent]
]);

export const buildCotisations = hospital => R.applySpec({
  contractId: R.prop('contract'),
  title: R.compose(
    type => R.pathOr('', [type, 'title'], cotisationConfig),
    getCotisationTypes
  ),
  content: R.compose(
    ({ text, iban, holder }) => <CotisationContent text={text} iban={iban} holder={holder}/>,
    getContent(hospital)
  ),
  img: R.compose(
    type => R.pathOr('', [type, 'img'], cotisationConfig),
    getCotisationTypes
  )
});
