import React from 'react';
import PropTypes from 'prop-types';
import { messagingLogo } from '@@images';
import Modal from '@@components/modale';

const TITLE_MODAL = 'Modifier mon adresse';
const TEXT_MODAL = 'Si vous avez changé de centre hospitalier,' +
  '\nmerci de modifier vos informations professionnelles' +
  '\nen nous contactant sur la messagerie.';

const EditAddressPHHUModal = ({
  show,
  setDisabled,
  onClickSave,
  onClickMail
}) => {
  return (
    <Modal show={show} onDisable={setDisabled} title={TITLE_MODAL}
      className={'f-modal-window-messaging-redirect'}
    >
      <div className="f-messaging-modal-container">
        <img className="f-messaging-modal-img" src={messagingLogo} alt="img"/>
        <div className="f-messaging-modal-text">
          {TEXT_MODAL}
        </div>
        <div className="f-messaging-modal-buttons">
          <button
            className="f-button f-button-grey f-edit-family-status-btn"
            onClick={setDisabled}
          >
            {'Annuler'}
          </button>
          <button
            className="f-button f-button-coral f-edit-family-status-btn"
            onClick={onClickMail}
          >
            {'Accéder à la messagerie'}
          </button>
          <button
            className="f-button f-button-coral f-edit-family-status-btn"
            onClick={onClickSave}
          >
            {'Enregistrer'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

EditAddressPHHUModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setDisabled: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  onClickMail: PropTypes.func.isRequired
};

export default EditAddressPHHUModal;
