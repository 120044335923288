/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGAnalyticsConfigQueryVariables = {||};
export type QGAnalyticsConfigQueryResponse = {|
  +configGa: ?{|
    +key: ?string
  |}
|};
export type QGAnalyticsConfigQuery = {|
  variables: QGAnalyticsConfigQueryVariables,
  response: QGAnalyticsConfigQueryResponse,
|};
*/


/*
query QGAnalyticsConfigQuery {
  configGa {
    key
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GAnalytics",
    "kind": "LinkedField",
    "name": "configGa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGAnalyticsConfigQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QGAnalyticsConfigQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6f2674454241a772841016ce345bc50b",
    "id": null,
    "metadata": {},
    "name": "QGAnalyticsConfigQuery",
    "operationKind": "query",
    "text": "query QGAnalyticsConfigQuery {\n  configGa {\n    key\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0347e4f6af620c4c627607e9e05677c7';

module.exports = node;
