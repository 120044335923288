import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@@components/modale';
import { confirmMessagingLogo } from '@@images';

const RequestConfirmModal = ({
  show,
  setDisabled,
  success
}) => {
  const successJsx = (
    <div>
      <p className={'f-messaging-confirm-modal-text-success'}>
        {'Nous avons bien pris en compte votre demande.'}
      </p>
      <p>
        {'Un conseiller vous contactera dans les meilleurs délais.'}
      </p>
    </div>
  );


  const errorJsx = (
    <div>
      <p className={'f-messaging-confirm-modal-text-error'}>
        {'Votre message n\'a pas pu être envoyé.'}
      </p>
      <p>
        {'Merci de remplir les champs demandés' +
        '\nou de télécharger la pièce justificative demandée.'}
      </p>
    </div>
  );



  return (
    <Modal show={show} onDisable={setDisabled}
      className={'f-window-messaging-confirm'}
    >
      <div className={'f-messaging-confirm-modal-container'}>
        <img className={'f-messaging-modal-img'}
          src={confirmMessagingLogo}
          alt={'img'} />
        <div className={'f-messaging-modal-text'}>
          {success ? successJsx : errorJsx}
        </div>
        <div className={'f-messaging-modal-buttons'}>
          <button
            className={'f-button f-button-coral f-edit-family-status-btn'}
            onClick={setDisabled}
          >
            {success ? 'Retour à la messagerie' : 'Retour au message'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

RequestConfirmModal.propTypes = {
  show: PropTypes.bool,
  setDisabled: PropTypes.func,
  success: PropTypes.bool
};

export default RequestConfirmModal;
