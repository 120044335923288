import React from 'react';
import * as R from 'ramda';
import { Block, Loading, MessagingRedirectionModal, Page } from '@@components';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { buildCotisations } from './helper';
import { useHistory } from 'react-router-dom';
import { imageCotisationsCorner } from '@@images';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';
import { filterActiveContracts } from '@@lib/contracts';

const TITLE_MODAL = 'Modifier mes préférences' +
  '\nde paiement';
const TEXT_MODAL = 'La modification du mode de paiement' +
  '\ns’effectue directement dans la messagerie.' +
  '\nVous allez être redirigé vers cet espace.';

const Home = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = React.useState(false);
  const { user } = useKeycloakSession();
  const { adherent } = useAdherent(user.customerId, ['contracts', 'professionalInformation']);

  const isLoading = R.compose(
    R.anyPass([R.isNil, R.isEmpty]),
    R.prop('contracts')
  )(adherent);

  if (isLoading) {
    return <Loading/>;
  }

  const hospital = R.pathOr('', ['professionalInformation', 'hospital'], adherent);

  const cotisations = R.compose(
    R.map(buildCotisations(hospital)),
    R.filter(R.pathSatisfies(isNotNilOrEmpty, ['detail', 'typology'])),
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  const handleModalCancelButton = () => setOpenModal(false);

  const editCotisation = () => {
    sendGAEventIfDefined(configGA.CONTRIBUTIONS_EDIT_GO_MESSAGING);
    history.push('/messagerie/ouvrir?action=06');
  };

  const handleOnClickEdit = () => {
    sendGAEventIfDefined(configGA.CONTRIBUTIONS_EDIT);
    setOpenModal(true);
  };

  const modal = <MessagingRedirectionModal
    show={openModal}
    setDisabled={handleModalCancelButton}
    onClick={editCotisation}
    title={TITLE_MODAL}
    text={TEXT_MODAL}
  />;

  const cotisationToJSX = ({ contractId, title, content, img }) => (
    <Block key={contractId}
      title={title}
      onEdit={handleOnClickEdit}
      leftLogo={img}
      additionalClasses={'f-contribution-container'}
      additionalStyles={{ backgroundImage: `url(${imageCotisationsCorner})` }}
    >
      <div className={'contribution-title'}>
        {'Préférences de paiement'}
      </div>
      <div className={'f-contribution-text'}>
        {content}
      </div>
    </Block>
  );

  return (
    <Page pageTitle="Cotisations">
      {modal}
      <div className="f-contributions-grid">
        {R.map(cotisationToJSX, cotisations)}
      </div>
    </Page>
  );
};

export default Home;
