/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PhoneNumberTypeEnum = "fix_perso" | "fix_pro" | "mobile_certified" | "mobile_perso" | "mobile_pro" | "%future added value";
export type AdherentPhoneNumbersInput = {|
  phoneNumbers: $ReadOnlyArray<?AdherentPhoneNumberInput>,
  subscriberId: string,
|};
export type AdherentPhoneNumberInput = {|
  certified?: ?boolean,
  type?: ?PhoneNumberTypeEnum,
  value?: ?string,
|};
export type AdherentPhoneNumbersMutationVariables = {|
  input: AdherentPhoneNumbersInput
|};
export type AdherentPhoneNumbersMutationResponse = {|
  +adherentPhoneNumbers: ?{|
    +ok: boolean,
    +error: ?string,
    +phoneNumberErrors: ?$ReadOnlyArray<?{|
      +type: ?PhoneNumberTypeEnum,
      +value: ?string,
      +certified: ?boolean,
      +errors: ?$ReadOnlyArray<?string>,
    |}>,
    +otpId: ?string,
  |}
|};
export type AdherentPhoneNumbersMutation = {|
  variables: AdherentPhoneNumbersMutationVariables,
  response: AdherentPhoneNumbersMutationResponse,
|};
*/


/*
mutation AdherentPhoneNumbersMutation(
  $input: AdherentPhoneNumbersInput!
) {
  adherentPhoneNumbers(input: $input) {
    ok
    error
    phoneNumberErrors {
      type
      value
      certified
      errors
    }
    otpId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AdherentPhoneNumbersMutationResponse",
    "kind": "LinkedField",
    "name": "adherentPhoneNumbers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PhoneNumberError",
        "kind": "LinkedField",
        "name": "phoneNumberErrors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "certified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errors",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otpId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdherentPhoneNumbersMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdherentPhoneNumbersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be750a1e868a4524c4bd6fe939081226",
    "id": null,
    "metadata": {},
    "name": "AdherentPhoneNumbersMutation",
    "operationKind": "mutation",
    "text": "mutation AdherentPhoneNumbersMutation(\n  $input: AdherentPhoneNumbersInput!\n) {\n  adherentPhoneNumbers(input: $input) {\n    ok\n    error\n    phoneNumberErrors {\n      type\n      value\n      certified\n      errors\n    }\n    otpId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'becb60e79a47ccc6f71cca79bd16b438';

module.exports = node;
