import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useCms } from '@@hooks';
import { Page } from '@@components';
import Layout, { Footer, Top } from '@@layout';

const R = require('ramda');

const Cgu = () => {
  const { contact } = useCms(['contact']);
  const { page } = useCms(['page'], { pageId: 'cgu' });

  if (R.isNil(page)) return null;

  return (
    <Layout
      footer={<Footer content={{ contact }}/>}
      top={<Top content={{ contact }}/>}
      doShowMenu={true}
    >
      <Page pageTitle={'Conditions Générales d\'Utilisation'}>
        <div className="w-full flex flex-wrap justify-between">
          <div className="f-card w-full">
            <ReactMarkdown className={'f-react-markdown'}>{page.content}</ReactMarkdown>
          </div>
        </div>
      </Page>
    </Layout>
  );
};

export default Cgu;
