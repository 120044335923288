import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ value }) => (
  <div className="text-danger mt-2 whitespace-pre-wrap">
    {value}
  </div>
);

ErrorMessage.propTypes = {
  value: PropTypes.string.isRequired
};

export default ErrorMessage;
