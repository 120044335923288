import { graphql } from 'react-relay';

const QGetDocumentNotifiedQuery = graphql`
  query QGetDocumentNotifiedQuery {
    getDocumentNotified {
        uploadId
        _id
        createdAt
    }
  }
`;

export { QGetDocumentNotifiedQuery };
