import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import useForms from '@@formsHook';
import { useAdherent, useData, useKeycloakSession } from '@@hooks';
import { getField } from './utils';
import Forms from '@@components/forms';
import { Link, useHistory } from 'react-router-dom';
import { BreadCrumb, Loading } from '@@components';

const TEXT_TITLE = 'Modification de vos informations professionnelles';
const TEXT_CARD_1 = 'Si vous souhaitez modifier d’autres informations' +
  ' professionnelles, vous pouvez faire une demande en passant par la' +
  ' messagerie.';
const TEXT_CARD_2 = 'Accéder à la messagerie';

const EditProfessionalInformation = ({ pathConfig }) => {
  const { user } = useKeycloakSession();
  const history = useHistory();
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('adherentProfessionalInformation');
  const { referential } = useData(['specialties']);
  const { adherent } = useAdherent(user.customerId, ['professionalInformation']);

  useEffect(() => {
    if (fetching.isDone()) {
      history.push(pathConfig.cancelPath);
    }
  }, [fetching.isDone()]);

  const onSubmit = ({ subscriberId, specialty }) => {
    // Map specialty id to label
    const specialtyLabel = R.compose(
      R.propOr('', 'name'),
      R.find(R.propEq('id', specialty)),
      R.propOr([], 'specialties')
    )(referential);
    onFormsSubmit({ subscriberId, specialty: specialtyLabel });
  };

  if (R.isNil(adherent.professionalInformation) || RA.isNilOrEmpty(R.prop('specialties', referential))) return <Loading/>;
  const specialty = getField(adherent.professionalInformation)(['specialty']);
  const specialties = R.map(RA.renameKeys({ id: 'value', name: 'label' }))(referential.specialties);

  const isFetching = fetching.isFetching();

  const breadCrumb = <BreadCrumb
    rootPath={'mesinformations'}
    rootName={'Mes informations personnelles'}
    location={TEXT_TITLE}/>;

  const title = (
    <div className='subsection-title'>
      {TEXT_TITLE}
    </div>
  );

  const formsBlock = (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        specialty: R.propOr('', 'value', R.find(R.propEq('label', specialty), specialties)),
        subscriberId: user.customerId
      }}
      options={{
        specialties
      }}
      onChange={fetching.reset}
      onSubmit={onSubmit}
      submitSection={() => (
        <div className='f-edit-family-status-buttons mt-8'>
          <Link
            className='f-button f-button-grey f-responsive-btn'
            to={pathConfig.cancelPath}
          >
            {'Annuler'}
          </Link>
          <button
            className='f-button f-button-coral f-responsive-btn'
            disabled={isFetching}
            type='submit'
          >
            {'Enregistrer'}
          </button>
        </div>
      )}
    />
  );

  const infoCard = (
    <div className='f-edit-pro-info-card-container'>
      <div className='f-edit-pro-info-card-logo'>
        <img src={'/icons/messagerie.svg'} alt='Icône messagerie'/>
      </div>
      <div className='f-edit-pro-info-card-content'>
        <p>
          {TEXT_CARD_1}
        </p>
        <div className='f-edit-family-status-buttons'>
          <Link
            className='f-button f-button-turquoise-reverse f-responsive-btn'
            to='/messagerie/ouvrir?action=02'
          >
            {TEXT_CARD_2}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className='f-base-page-container'>
      <div className='f-page'>
        <div className='f-page-body'>
          {breadCrumb}
          <div className='f-page-content-header'>
            { title }
          </div>
          <div className='f-edit-pro-forms-block'>
            { formsBlock }
          </div>
          { infoCard }
        </div>
      </div>
    </div>
  );
};

EditProfessionalInformation.propTypes = {
  pathConfig: PropTypes.shape({
    cancelPath: PropTypes.string
  })
};

export default EditProfessionalInformation;
