import { graphql } from 'react-relay';

const QBackOfficeMessageThemesQuery = graphql`
  query QBackOfficeMessageThemesQuery {
      references {
        messageThemesBackOffice {
          id
          title
          actionId
          subThemes {
            id
            actionId
            title
          }
        }
      }
    }
`;

export {
  QBackOfficeMessageThemesQuery
};
