import { useEffect, useState } from 'react';
import { QGetDocumentNotifiedQuery as query } from '../_graphql/queries/QGetDocumentNotified';
import {
  compose,
  filter,
  inc,
  isEmpty,
  length,
  propEq
} from 'ramda';
const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

const useDocumentNotified = () => {
  const environment = useRelayEnvironment();
  const [documentNotified, setDocumentNotified] = useState([]);

  const [key, setKey] = useState(0);

  let interval;
  useEffect(() => {
    if (interval) { return; }
    interval = setInterval(() => setKey(inc), 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let hasBeenCancelled = false;
    const fetchData = async () => {
      try {
        const { getDocumentNotified: data } = await fetchQuery(environment, query, {}).toPromise();
        if (!hasBeenCancelled) {
          setDocumentNotified(data || []);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    };

    fetchData();

    return () => (hasBeenCancelled = true);
  }, [key]);

  const checkDiscussionNotification = (discussionId) => {
    if (isEmpty(documentNotified)) {
      return false;
    }

    return compose(
      length,
      filter(propEq('discussionId', discussionId))
    )(documentNotified);
  };
  return {
    documentNotified,
    checkDiscussionNotification,
    nbDocumentssNotReaded: length(documentNotified)
  };
};

export default useDocumentNotified;
