import React from 'react';
import Sections, { Section, useSections } from '@@components/filter/Sections';
import SelectDocuments from './SelectDocuments';
const R = require('ramda');

const Home = () => {
  const { register, sectionTitle, sections, onSectionFilter } = useSections();

  return (
    <>
      <Sections sections={sections} onSectionFilter={onSectionFilter}>
        <Section ref={register('s1', 'Conseil d\'administration et bureau')} title={sectionTitle('s1')}>
          <div className="w-full grid grid-cols-3 gap-4">
            <SelectDocuments type="CONSEIL_ADMIN_BUREAU" title={sectionTitle('s1')} />
          </div>
        </Section>
        <Section ref={register('s2', 'Assemblées générales et PV')} title={sectionTitle('s2')}>
          <div className="w-full grid grid-cols-3 gap-4">
            <SelectDocuments type="RAPPORTS_AG" title={sectionTitle('s2')} />
          </div>
        </Section>
        <Section ref={register('s3', 'Lettres de l\'APPA (Archives)')} title={sectionTitle('s3')}>
          <div className="w-full grid grid-cols-3 gap-4">
            <SelectDocuments type="LETTRES" title={sectionTitle('s3')} />
          </div>
        </Section>
        <Section ref={register('s4', 'Status et règlement intérieur')} title={sectionTitle('s4')}>
          <div className="w-full grid grid-cols-3 gap-4">
            <SelectDocuments type="STATUTS" title="Statuts" />
            <SelectDocuments type="REGLEMENTS" title="Règlement" />
          </div>
        </Section>
      </Sections>
    </>
  );
};

export default Home;
