import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { __, curry, equals, isEmpty, propSatisfies } from 'ramda';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

export const MENU_ITEMS = [{
  text: 'Accueil',
  path: '/'
}, {
  text: 'Contrats',
  path: '/contrats',
  ga: configGA.MENU_CONTRACTS
}, {
  text: 'Documents',
  path: '/mesdocuments',
  ga: configGA.MENU_DOCUMENTS
}, {
  text: 'Cotisations',
  path: '/cotisations',
  ga: configGA.MENU_CONTRIBUTIONS
}, {
  text: 'Vie de l\'association',
  path: '/association',
  hidden: true
}];

const convertItemToListItem = (item, location) => {
  const { text, path, hidden, ga } = item;
  return !hidden &&
    <LiNavLink key={path} path={path} text={text} location={location} ga={ga}/>;
};

const getClassName = ({ path, location }) => {
  const isActive = propSatisfies(equals(path), 'pathname', location);
  const activePostfix = isActive ? ' active' : '';
  return `f-menu-item${activePostfix}`;
};

const LiNavLink = ({ path, location, text, ga }) => {
  const history = useHistory();
  const className = getClassName({ path, location });
  const onClick = () => {
    sendGAEventIfDefined(ga);
    history.push(path);
  };
  return <li onClick={onClick} className={className}>{text}</li>;
};

LiNavLink.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  path: PropTypes.string,
  text: PropTypes.string,
  ga: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};

const Menu = ({ items, location }) => {
  if (isEmpty(items)) return null;
  const curriedConvertItemToListItem = curry(convertItemToListItem);
  const convertWithLocation = curriedConvertItemToListItem(__, location);
  return (
    <div className={'f-menu-container'}>
      <div className={'f-menu-body'}>
        <ul>
          {items.map(convertWithLocation)}
        </ul>
      </div>
    </div>
  );
};

Menu.propTypes = {
  items: PropTypes.array,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default withRouter(Menu);
