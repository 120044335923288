import * as R from 'ramda';

export const MessageRecord = (store) => {
  const createDiscussion = (discussionId, message) => {
    const rec = store.get(discussionId);
    const dateId = `client:${discussionId}:dates`;

    if (rec) return;

    const dates = R.defaultTo(store.create(dateId, 'Dates'), store.get(dateId));

    dates.setValue(R.path(['dates', 'creation'], message), 'creation');
    dates.setValue(R.path(['dates', 'lastUpdate'], message), 'lastUpdate');

    const newDiscussion = store.create(discussionId, 'Discussion');
    newDiscussion.setValue(discussionId, 'id');
    newDiscussion.setValue(R.prop('title', message), 'title');
    newDiscussion.setValue('OPENED', 'status');
    newDiscussion.setLinkedRecord(dates, 'dates');

    const allDiscussion = store.get('allDiscussions');
    const data = R.compose(
      R.prepend(newDiscussion),
      R.defaultTo([])
    )(allDiscussion.getLinkedRecords('data'));

    allDiscussion.setLinkedRecords(data, 'data');
  };

  const addMessage = (messageId, discussionId, message) => {
    const discussion = store.get(discussionId);

    // * return if no discussion
    if (!discussion) return;

    const dateId = `client:${messageId}:dates`;
    const allMessageId = `client:${discussionId}:allMessages`;

    const dates = R.when(R.isNil, () => store.create(dateId, 'Dates'), store.get(dateId));

    dates.setValue(R.path(['dates', 'creation'], message), 'creation');
    dates.setValue(R.path(['dates', 'lastUpdate'], message), 'lastUpdate');

    const messageRec = R.when(R.isNil, () => store.create(messageId, 'Message'), store.get(messageId));
    messageRec.setValue(messageId, 'id');
    messageRec.setValue(R.prop('sender', message), 'sender');
    messageRec.setValue(R.propOr([], 'recipients', message), 'recipients');
    messageRec.setValue(R.prop('text', message), 'text');
    messageRec.setValue(R.prop('order', message), 'order');

    messageRec.setLinkedRecord(dates, 'dates');

    const attachments = R.propOr([], 'attachments', message).map((attachment) => {
      const attachmentRec = R.when(R.isNil, () => store.create(attachment.uploadId, 'Attachment'), store.get(attachment.uploadId));

      attachmentRec.setValue(attachment.id, 'id');
      attachmentRec.setValue(attachment.documentType, 'documentType');
      attachmentRec.setValue(attachment.fileName, 'fileName');
      attachmentRec.setValue(attachment.uploadId, 'uploadId');

      return attachmentRec;
    });

    messageRec.setLinkedRecords(R.defaultTo([], attachments), 'attachments');

    const allMessages = R.when(R.isNil, () => store.create(allMessageId, 'PaginatedMessages'), store.get(allMessageId));

    // * Add message to discussion
    const data = R.compose(
      R.prepend(messageRec),
      R.defaultTo([])
    )(allMessages.getLinkedRecords('data'));

    allMessages.setLinkedRecords(data, 'data');

    // * Update discussion with new message
    discussion.setLinkedRecord(allMessages, 'allMessages');
  };

  return { createDiscussion, addMessage };
};

export default MessageRecord;
