/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QGetDocumentNotifiedQueryVariables = {||};
export type QGetDocumentNotifiedQueryResponse = {|
  +getDocumentNotified: ?$ReadOnlyArray<?{|
    +uploadId: ?string,
    +_id: ?string,
    +createdAt: ?string,
  |}>
|};
export type QGetDocumentNotifiedQuery = {|
  variables: QGetDocumentNotifiedQueryVariables,
  response: QGetDocumentNotifiedQueryResponse,
|};
*/


/*
query QGetDocumentNotifiedQuery {
  getDocumentNotified {
    uploadId
    _id
    createdAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DocumentNotifier",
    "kind": "LinkedField",
    "name": "getDocumentNotified",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QGetDocumentNotifiedQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QGetDocumentNotifiedQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4c8e001fa91cc8a2d59d8a763ed76c63",
    "id": null,
    "metadata": {},
    "name": "QGetDocumentNotifiedQuery",
    "operationKind": "query",
    "text": "query QGetDocumentNotifiedQuery {\n  getDocumentNotified {\n    uploadId\n    _id\n    createdAt\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b51ba60797a3933a6d6fa9d3b09fedd5';

module.exports = node;
