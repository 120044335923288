import React, { useEffect, useState } from 'react';
import Sections, { Section, useSections } from '@@components/filter/Sections';
import { DocumentBlock, Page } from '@@components';
import FillDocumentMutation from '@@graphql/mutations/FillDocumentMutation';
import { useRelayEnvironment } from 'react-relay';
import { useKeycloakSession } from '@@hooks';
import { useFetchQuery } from '@@hooks/useDocument';
import { DOCUMENT_SUBTYPE_ATTESTATION_GENERATED, DOCUMENT_TYPE_ATTESTATION, SECTION_TYPES } from './utils';
import DocumentGrid from '@@components/DocumentGrid';
import { QAdherentDocumentsQuery } from '@@queries/QAdherentDocuments';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Link } from 'react-router-dom';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const documentBlockToJSX = ({
  fileId,
  title,
  description,
  fileName,
  fileSource,
  _id
}) => (
  <DocumentBlock
    key={fileId}
    fileId={fileId}
    title={title}
    description={description}
    fileName={fileName}
    source={fileSource}
    documentId={_id}
  />
);

const Home = () => {
  const { register, sectionTitle, sections, onSectionFilter } = useSections();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const environment = useRelayEnvironment();
  const { user } = useKeycloakSession();
  const customerId = R.propOr('', 'customerId')(user);

  const {
    isLoaded: isLoadedListDocuments,
    data: dataListDocuments
  } = useFetchQuery(QAdherentDocumentsQuery, { id: customerId });
  // todo - we could refine the filter here to only match the targeted types.

  useEffect(() => {
    if (isLoadedListDocuments) {
      const docs = R.pathOr([], ['adherent', 'documents'], dataListDocuments);
      setDocuments(docs);
      setLoading(false);
    }
  }, [isLoadedListDocuments, dataListDocuments]);

  const generateFillDocument = () => {
    setLoading(true);
    sendGAEventIfDefined(configGA.DOCUMENTS_GENERATE_HEALTH_CERTIFICATE);
    const subscriberId = user.customerId;

    FillDocumentMutation(
      environment,
      { subscriberId },
      async (ok, error, documentData) => {
        if (ok) {
          const newDocuments = R.compose(
            R.prepend(documentData), // Add the new one
            R.reject( // Remove old attestations
              R.allPass([
                R.propEq('type', DOCUMENT_TYPE_ATTESTATION),
                R.propEq('subType', DOCUMENT_SUBTYPE_ATTESTATION_GENERATED)
              ])
            )
          )(documents);
          setDocuments(newDocuments);
          setError('');
        } else {
          setError('Une erreur est survenue lors de la génération de document');
        }
        setLoading(false);
      });
  };

  const documentTypeEq = documentType =>
    R.any(R.propEq('type', documentType), documents);
  const getDocumentsOfType = documentType =>
    R.filter(R.propEq('type', documentType));

  const sectionToJSX = ({ sectionType, references, title }) => (
    documentTypeEq(sectionType) && (
      <Section key={title}
        ref={register(references.sectionNumber, references.name)}
        title={sectionTitle(title)}
      >
        <DocumentGrid
          documentBlocks={
            R.map(documentBlockToJSX,
              getDocumentsOfType(sectionType)(documents))
          }
          defaultSize={3}
        />
      </Section>)
  );

  const hasError = RA.isNotNilOrEmpty(error);

  return (
    <Page pageTitle={'Documents'}>
      <Sections sections={sections} onSectionFilter={onSectionFilter}>

        <div className={'f-document-top-buttons'}>
          <button className={'f-button f-button-coral f-responsive-btn'}
            onClick={generateFillDocument} disabled={loading}>
            {'Générer mon attestation santé'}
          </button>
          <Link
            className={'f-button f-button-coral f-responsive-btn'}
            to="/messagerie/ouvrir?action=05"
            onClick={() => sendGAEventIfDefined(configGA.DOCUMENTS_REQUEST_PENSION_CERTIFICATE)}
          >
            {'Demander une attestation prévoyance'}
          </Link>
        </div>

        {hasError && (
          <div className="f-document-top-error">
            {error}
          </div>
        )}

        <div className={'f-document-section-list'}>
          {R.map(sectionToJSX, SECTION_TYPES)}
        </div>

      </Sections>
    </Page>
  );
};

export default Home;
