/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'subscriberId',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "N° d'adhérent",
    placeholder: "123456",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'email',
    required: true,
    input: 'email',
    source: '__UNDEFINED__',
    label: "Email",
    placeholder: "Votre email",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'lastName',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Nom d'usage",
    placeholder: "Votre nom d'usage",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'phoneNumber',
    required: true,
    input: 'phoneNumber',
    source: '__UNDEFINED__',
    label: "Téléphone Mobile",
    placeholder: "Votre numéro de mobile",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'firstName',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Prénom",
    placeholder: "Votre prénom",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'comment',
    required: true,
    input: 'textarea',
    source: '__UNDEFINED__',
    label: "Commentaire",
    placeholder: "Votre message...",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'birthDate',
    required: true,
    input: 'birthDate',
    source: '__UNDEFINED__',
    label: "Date de naissance",
    placeholder: "12/08/1970",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;