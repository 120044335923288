import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { Link, useHistory } from 'react-router-dom';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { typologyEq } from './utils';
import { BreadCrumb, ErrorMessage, InformationBlock } from '@@components';
import { typologies } from '@@enum';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import EditAddressPHHUModal from '@@pages/account/EditAddressPHHUModal';
import { filterActiveContracts } from '@@lib/contracts';

const TEXT_JUSTIFICATIFS = 'Les justificatifs de domicile acceptés sont :' +
  ' une facture d\'électricité, d\'eau, de gaz ou de téléphone; une' +
  ' quittance de loyer de moins de 3 mois.';

const EditAddress = ({ pathConfig }) => {
  const { user } = useKeycloakSession();
  const customerId = R.propOr('', 'customerId')(user);
  const { adherent } = useAdherent(customerId, ['contact', 'contracts']);
  const [openModal, setOpenModal] = useState(false);
  const [dataToSubmit, setDataToSubmit] = useState({});
  const [errorMessage, setErrorMessage] = React.useState('');
  const history = useHistory();
  const redirectPath = useRef('/mesinformations');
  const activeContracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('adherentAddress');
  // when form submission completes, return to the profile page
  useEffect(() => {
    if (fetching.isDone()) {
      history.push(redirectPath.current);
    }
    if (RA.isNotNilOrEmpty(fetching.getError())) {
      setErrorMessage(fetching.getError());
    }
  }, [fetching.isDone(), fetching.getError()]);

  if (R.isNil(adherent.contact)) return null;

  const submitForm = data => {
    setOpenModal(false);
    // Get new value and cast pnd string to boolean
    const newValue = R.over(R.lensProp('pnd'), R.equals('true'), data);
    onFormsSubmit(newValue);
  };

  const onSubmit = (data) => {
    // PHHU ==> don't submit, open the popup instead
    if (typologyEq(typologies.PHHU, activeContracts)) {
      setDataToSubmit(data);
      setOpenModal(true);
    // not PHHU ==> submit right away
    } else {
      submitForm(data);
    }
  };

  const handleModalCancelButton = () => setOpenModal(false);
  const handleModalMailButton = () => {
    redirectPath.current = '/messagerie/ouvrir?action=02-02';
    submitForm(dataToSubmit);
  };
  const handleModalSaveButton = () => {
    submitForm(dataToSubmit);
  };

  const modal = <EditAddressPHHUModal
    show={openModal}
    setDisabled={handleModalCancelButton}
    onClickMail={handleModalMailButton}
    onClickSave={handleModalSaveButton}
  />;

  const breadCrumb = <BreadCrumb
    rootPath={'mesinformations'}
    rootName={'Mes informations personnelles'}
    location={'Modification de votre adresse'}/>;

  const title = (
    <div className='subsection-title'>
      {'Modification de votre adresse'}
    </div>
  );

  const forms = (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        ...adherent.contact.address,
        subscriberId: user.customerId,
        attachment: null
      }}
      onChange={() => fetching.reset()}
      onSubmit={onSubmit}
      submitSection={() => (
        <>
          <div className='f-edit-address-submit-box'>
            <Link
              className='f-button f-button-grey'
              to={pathConfig.cancelPath}
            >
              {'Annuler'}
            </Link>
            <button
              className='f-button f-button-coral'
              disabled={fetching.isFetching()}
              type='submit'
            >
              {'Enregistrer'}
            </button>
          </div>
          {RA.isNotNilOrEmpty(errorMessage) && (
            <ErrorMessage value={errorMessage} />
          )}
        </>
      )}
    />
  );

  const infoBlock = (
    <InformationBlock
      title='Quels justificatifs de domicile sont acceptés ?'
      img='/image/shutterstock_488616529_.png'
      className=''
    >
      {TEXT_JUSTIFICATIFS}
    </InformationBlock>
  );

  return (
    <div className='f-base-page-container'>
      <div className='f-page'>
        <div className='f-page-body'>
          { breadCrumb }
          <div className='f-page-content-header'>{ title }</div>
          <div className='f-edit-address-content'>
            <div className='f-edit-address-forms-box'>{ forms }</div>
            { modal }
            { infoBlock }
          </div>
        </div>
      </div>
    </div>
  );
};

EditAddress.propTypes = {
  pathConfig: PropTypes.shape({
    cancelPath: PropTypes.string
  })
};

export default EditAddress;
