import React from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import Modal from '@@components/modale';
import * as R from 'ramda';
import DiscussionConfirmationModal from './DiscussionConfirmationModal';

const getTitleModal = () => 'J\'ai oublié mon n° d\'adhérent';
const getTextModal2 = () =>
  'Saisir les informations ci-dessous pour recevoir votre numéro d\'adhérent' +
  '\npar email ou par téléphone. Tous les champs sont obligatoires.';

const ForgotAdherentNumberModal = ({ show, onDisable }) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('forgotAdherentNumber');

  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
  const [requestConfirmationText, setRequestConfirmationText] = React.useState({});

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setRequestConfirmationText({});
  };

  React.useEffect(() => {
    if (fetching.isDone()) {
      setRequestConfirmationText({ ok: true, error: false });
      setOpenConfirmationModal(true);
    }
    if (fetching.getError()) {
      setRequestConfirmationText({ ok: false, error: true });
      setOpenConfirmationModal(true);
    }
  }, [fetching.isDone(), fetching.getPayload(), fetching.getError()]);

  const isDisabled = fetching.isFetching();

  const handleSubmit = (val) => {
    onFormsSubmit(val);
    onDisable(false);
  };

  const contentWithForms = <>
    <div className={'f-standard-modal-intro'}>
      { getTextModal2() }
    </div>
    <div className={'f-standard-modal-forms'}>
      <Forms
        formsData={formsData}
        columns={2}
        onSubmit={handleSubmit}
        submitSection={() => (
          <div>
            <div>
              <div className={'f-standard-modal-buttons mt-8'}>
                <button className={'f-button f-button-grey f-responsive-btn'}
                  type={'button'}
                  disabled={isDisabled}
                  onClick={onDisable}
                >
                  {'Annuler'}
                </button>
                <button className={'f-button f-button-coral f-responsive-btn'}
                  disabled={isDisabled}
                  type='submit'
                >
                  {'Valider'}
                </button>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  </>;

  return (
    <>
      <Modal show={show} onDisable={onDisable}
        className={'f-window-forgot-adherent-number'}
      >
        <div className={'f-forgot-adherent-number-modal-container'}>
          <div className={'f-standard-modal-title'}>
            { getTitleModal() }
          </div>
          {contentWithForms}
        </div>
      </Modal>
      <DiscussionConfirmationModal
        show={openConfirmationModal}
        onDisabled={handleCloseConfirmationModal}
        requestConfirmationText={requestConfirmationText}
      />
    </>
  );
};

ForgotAdherentNumberModal.propTypes = {
  show: PropTypes.bool,
  onDisable: PropTypes.func
};

export default ForgotAdherentNumberModal;
