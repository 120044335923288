/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type OnBoardAdherentInput = {|
  gcuIsAccept?: ?boolean,
  subscriberId: string,
|};
export type OnBoardAdherentMutationVariables = {|
  input: OnBoardAdherentInput
|};
export type OnBoardAdherentMutationResponse = {|
  +onBoardAdherent: ?{|
    +ok: boolean,
    +error: ?string,
    +subscriberId: ?string,
    +onBoarded: ?boolean,
  |}
|};
export type OnBoardAdherentMutation = {|
  variables: OnBoardAdherentMutationVariables,
  response: OnBoardAdherentMutationResponse,
|};
*/


/*
mutation OnBoardAdherentMutation(
  $input: OnBoardAdherentInput!
) {
  onBoardAdherent(input: $input) {
    ok
    error
    subscriberId
    onBoarded
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "OnBoardAdherentMutationResponse",
    "kind": "LinkedField",
    "name": "onBoardAdherent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subscriberId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "onBoarded",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnBoardAdherentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnBoardAdherentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b3a74981265330134bccd1230122c383",
    "id": null,
    "metadata": {},
    "name": "OnBoardAdherentMutation",
    "operationKind": "mutation",
    "text": "mutation OnBoardAdherentMutation(\n  $input: OnBoardAdherentInput!\n) {\n  onBoardAdherent(input: $input) {\n    ok\n    error\n    subscriberId\n    onBoarded\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edf4c0fa3fa04e1ea402e41941b913df';

module.exports = node;
