import React, {useEffect, useRef} from 'react';
import { useDiscussion, useQueryUrl } from '@@hooks';
import { Redirect } from 'react-router-dom';

const R = require('ramda');
const RA = require('ramda-adjunct');
const Signature = ({
  match,
  pathConfig
}) => {

  const discussionId = R.path(['params', 'discussionId'], match);
  const queryParams = useQueryUrl();
  const status = R.pathOr('', [0, 'status'], queryParams);
  if (RA.isNotNilOrEmpty(status)) return <Redirect to={`/messagerie/${discussionId}`}/>;

  const { discussion } = useDiscussion(discussionId, ['signRequest']);
  if (RA.isNilOrEmpty(discussion)) return null;

  const { youSign, youSignUrl } = R.prop('discussionSignRequest', discussion);
  if (RA.isNilOrEmpty(youSignUrl)) return null;




  return (
    <div className="w-full flex flex-wrap justify-between">
      <div className="flex w-full h-sign-page-mob md:h-sign-page-dsk">
        <iframe
          className="flex-1"
          src={`${youSignUrl}/procedure/sign?members=${youSign.memberId}&signatureUi=${youSign.signatureUi}`}
          width="100%"
        />
      </div>
    </div>
  );
};

export default Signature;
