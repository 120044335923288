import {
  commitMutation,
  graphql
} from 'react-relay';

const mutation = graphql`
    mutation HasBeenNotifiedMutation($discussionId: String!) {
      hasBeenNotified(discussionId: $discussionId) {
            ok
            error
        }
    }
`;

export default async (environment, { discussionId }, done) => {
  const variables = {
    discussionId
  };

  commitMutation(
    environment,
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ hasBeenNotified }) => {
        const { ok, error } = hasBeenNotified;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
