import React, { useState } from 'react';
import { showIcon } from '@@images';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const PASSWORD_RULES = ['> 8 caractères', '1 majuscule', '1 chiffre'];
const ruleToJSX = (rule, index) => (
  <div key={index} className={'f-new-password-rule'}>
    <div className={'f-new-password-rule-dot'}/>
    <span className={'f-new-password-rule-text'}>{rule}</span>
  </div>
);

const Input = ({ register, field, errors }) => {
  const classes = R.keys(errors).includes(field.name)
    ? 'f-input-red' : 'f-input';

  const [revealedPassword, setRevealedVisiblePassword] = useState(false);
  const toggleReveal = () => setRevealedVisiblePassword(!revealedPassword);

  // inputs for creating a password also show syntax requirements
  const isNewPassword = R.compose(
    R.includes(R.__, ['newPassword', 'newPasswordConfirm']),
    R.propOr('', 'name')
  )(field);
  const newPasswordRules = isNewPassword && (
    <div className={'f-new-password-rules-container'}>
      {RA.mapIndexed(ruleToJSX, PASSWORD_RULES)}
    </div>
  );

  return (
    <div className="f-password-input-container">
      <div className="f-password-input-box">
        <input className={classes}
          ref={register({ required: field.required })}
          type={revealedPassword ? 'text' : 'password'}
          name={field.name}
          placeholder={field.placeholder}
        />
        <button
          className="f-block-field-button"
          tabIndex={-1} // prevent focus when pressing 'Tab' key
          type="button"
          style={{ backgroundImage: `url(${showIcon})` }}
          onClick={toggleReveal}
        />
      </div>
      {newPasswordRules}
    </div>
  );
};

export default Input;
