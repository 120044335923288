import React from 'react';
import PropTypes from 'prop-types';
import { logoEurop } from '@@images';

const TEXT = 'Pour cette demande, veuillez contacter' +
  '\nEurop Assistance au';

const EuropAssistanceInfo = ({
  europAssistancePhoneNumberMetropole,
  europAssistancePhoneNumberDROM
}) => (
  <>
    <img className='f-messaging-ext-services-modal-img-europ' src={logoEurop} alt="img"/>
    <div className={'f-messaging-ext-services-modal-text'}>
      {TEXT}
    </div>
    <div className={'f-messaging-ext-services-modal-text'}>
      <div className="f-messaging-ext-services-modal-phone-number">
        {europAssistancePhoneNumberMetropole}
      </div>
      <div className={'f-contact-block-caption'}>
        {'(en France métropolitaine)'}
      </div>
      <div className="f-messaging-ext-services-modal-phone-number mt-2">
        {europAssistancePhoneNumberDROM}
      </div>
      <div className={'f-contact-block-caption'}>
        {'(pour les DROM)'}
      </div>
    </div>
  </>
);

EuropAssistanceInfo.propTypes = {
  europAssistancePhoneNumberMetropole: PropTypes.string.isRequired,
  europAssistancePhoneNumberDROM: PropTypes.string.isRequired
};

export default EuropAssistanceInfo;
