import React from 'react';
import { QAdherentLastDocumentQuery } from '@@queries/QAdherentLastDocument';

const R = require('ramda');

const { fetchQuery } = require('react-relay');
const { useRelayEnvironment } = require('react-relay');

export const useLastDocument = (id) => {
  const environment = useRelayEnvironment();
  const [lastDocument, setLastDocument] = React.useState([]);
  const idRef = React.useRef();

  React.useEffect(() => {
    if (R.equals(idRef.current, id)) return;
    idRef.current = id;
    getLastDocument(id);
  }, [id]);

  const getLastDocument = async (id) => {
    const { adherent } = await fetchQuery(environment, QAdherentLastDocumentQuery, { id }).toPromise();
    setLastDocument(R.propOr({}, 'lastDocument', adherent));
  };

  return {
    lastDocument
  };
};

export default useLastDocument;
