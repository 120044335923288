import React from 'react';
import PropTypes from 'prop-types';
import { cotisationLiberalLogo, iconPdf } from '@@images';
import { Link } from 'react-router-dom';
import { Block } from '@@components';
import { getGenericDocumentUrl } from '@@lib/contracts';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';
import { configMA, sendMAEventIfDefined } from '@@lib/ma';

const INTRO_TEXT = 'Déclarer un sinistre prévoyance (arrêt de travail,' +
  ' invalidité), compléter un dossier en cours ou déclarer une reprise' +
  ' d’activité.';

const PensionSecondaryBlock = ({ workStoppageDeclarationGuide }) => {

  const workStoppageDeclarationGuideUrl = getGenericDocumentUrl({
    document: workStoppageDeclarationGuide, download: false
  });
  const onClickDeclareAClaim = () => {
    sendGAEventIfDefined(configGA.CONTRACT_PENSION_HOSPI_DECLARE);
    sendMAEventIfDefined(configMA.CONTRACT_PENSION_HOSPI_DECLARE);
  };
  return (
    <Block>
      <div className="f-contract-secondary-block">
        <div className="f-contract-secondary-title">
          {'Déclarer un évènement'}
        </div>
        <div className="f-contract-secondary-img">
          <img src={cotisationLiberalLogo} alt="logo"/>
        </div>
        <div className="f-contract-text">
          {INTRO_TEXT}
        </div>
        <div className="f-contract-secondary-buttons">
          <Link className="f-button f-button-coral"
            to={'/messagerie/ouvrir?action=04'}
            onClick={onClickDeclareAClaim}
          >
            {'Déclarer'}
          </Link>
        </div>
        <div className="f-contract-pdf-link">
          <img src={iconPdf} alt="pdf-logo"/>
          <a
            className="f-contract-secondary-pdf-link-text"
            href={workStoppageDeclarationGuideUrl} target="_blank" rel="noreferrer"
          >
            {'En savoir plus sur la déclaration\nd’un sinistre prévoyance'}
          </a>
        </div>
        {/* <div className="f-contract-pdf-link">
          <img src={'/icons/pdf.svg'} alt="pdf-logo"/>
          <div className='f-contract-pdf-link-text text-center'>
            {'En savoir plus sur la déclaration d\'un sinistre prévoyance'}
          </div>
        </div> */}
      </div>
    </Block>
  );
};

PensionSecondaryBlock.propTypes = {
  workStoppageDeclarationGuide: PropTypes.object
};

export default PensionSecondaryBlock;
