import React, { useEffect, useState } from 'react';
import { ServiceBlock } from '@@components';
import * as R from 'ramda';
import { propOr } from 'ramda';
import {
  CONTRACT_TYPE_ASSISTANCE,
  CONTRACT_TYPE_HEALTH,
  getGenericDocumentUrl,
  hasContractTypologies,
  hasGuaranteeOfType
} from '@@lib/contracts';
import { typologies } from '@@enum';
import { isNotDomTom } from '@@pages/home/utils';
import { useFetchQuery } from '@@hooks/useDocument';
import { QCmsStaticDocumentsQuery } from '@@queries/QCmsStaticDocuments';
import { STATIC_DOC_TYPE_FICHE_CONCIERGERIE } from '@@pages/documents/utils';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';

const Services = ({ adherent, mercer, contact, contracts }) => {
  const zipCode = R.pathOr('', ['contact', 'address', 'zipCode'], adherent);
  const mercerUrl = R.propOr('', 'mercerUrl', mercer);

  const hasAssistance = R.any(hasGuaranteeOfType(CONTRACT_TYPE_ASSISTANCE))(contracts);
  const hasHealth = R.any(hasGuaranteeOfType(CONTRACT_TYPE_HEALTH))(contracts);
  const hasPHHU = R.any(hasContractTypologies([typologies.PHHU]))(contracts);
  const isMetropolitan = isNotDomTom(zipCode);

  // Récupération de l'url du document statique "Conciergerie Connectée"
  const [conciergerieUrl, setConciergerieUrl] = useState('');
  const {
    isLoaded: isLoadedListDocuments,
    data: dataListDocuments
  } = useFetchQuery(QCmsStaticDocumentsQuery,
    { documentsFilter: { type: STATIC_DOC_TYPE_FICHE_CONCIERGERIE } });
  useEffect(() => {
    if (isLoadedListDocuments) {
      const doc = R.compose(
        R.head,
        R.pathOr('', ['cms', 'staticDocuments'])
      )(dataListDocuments);
      const url = getGenericDocumentUrl({ document: doc, download: false });
      console.log(dataListDocuments);
      console.log(url);
      setConciergerieUrl(url);
    }
  }, [isLoadedListDocuments, dataListDocuments]);

  const onClickMercer = () => sendGAEventIfDefined(configGA.HOME_SERVICE_MERCER);
  const blockHealth = hasHealth && (
    <ServiceBlock title={'Mon espace santé'}>
      <div className={'f-services-block-text'}>
        <p>{'Demander ou consulter\nun remboursement'}</p>
        <p>{'Demander ma carte de tiers payant'}</p>
        <p>{'Demander une prise en charge\nhospitalière'}</p>
        <p>{'Rechercher un professionnel de santé'}</p>
      </div>
      <a className={'f-services-block-button'} href={mercerUrl}
        target="_blank" rel="noreferrer" onClick={onClickMercer}
      >
        {'Accéder'}
      </a>
    </ServiceBlock>
  );

  const onClickAssistance = () => sendGAEventIfDefined(configGA.HOME_SERVICE_ASSISTANCE);
  const blockAssistance = hasAssistance && (
    <ServiceBlock title={'Mon assistance'}>
      <div className={'f-services-block-content'}>
        <div className={'f-services-block-text'}>
          <p>{'Accéder aux détails des garanties\nassistance.'}</p>
          <p>{'Besoin d\'informations complémentaires ?'}</p>
        </div>
        <div>
          <div className={'f-services-block-phone-number'}>
            {propOr('', 'europAssistanceMetropolePhoneNumber', contact)}
          </div>
          <div className={'f-services-block-phone-caption'}>
            {'(en France métropolitaine)'}
          </div>
        </div>
        <div>
          <div className={'f-services-block-phone-number'}>
            {propOr('', 'europAssistanceDROMPhoneNumber', contact)}
          </div>
          <div className={'f-services-block-phone-caption'}>
            {'(pour les DROM)'}
          </div>
        </div>
      </div>
      <a className={'f-services-block-button'}
        href={propOr('', 'europAssistanceURL', contact)}
        target="_blank" rel="noreferrer" onClick={onClickAssistance}
      >
        {'Contacter'}
      </a>
    </ServiceBlock>
  );

  const onClickConciergerie = () => sendGAEventIfDefined(configGA.HOME_SERVICE_CONCIERGE);
  const blockConciergerie = hasAssistance && hasPHHU && isMetropolitan && (
    <ServiceBlock title={'Ma conciergerie connectée'}>
      <div className={'f-services-block-text'}>
        <p>{'Disponible 24H/24 et 7J/7'}</p>
        <p>{'Bénéficier de services dans les univers\nde la famille ou du domicile'}</p>
        <p>{'Rechercher un hébergement pour\nles vacances'}</p>
        <p>{'Obtenir des conseils pour trouver\nun restaurant'}</p>
      </div>
      <a className={'f-services-block-button'} href={conciergerieUrl}
        target="_blank" rel="noreferrer" onClick={onClickConciergerie}
      >
        {'Accéder'}
      </a>
    </ServiceBlock>
  );

  return (
    <div className={'f-base-page-container f-services-bg mb-20'}>
      <div className={'f-page'}>
        <div className={'f-page-body'}>
          <div className={'f-services-title'}>
            {'Mes Services'}
          </div>
          <div className={'f-welcome-body'}>
            {blockHealth}
            {blockAssistance}
            {blockConciergerie}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
