import { graphql } from 'react-relay';

const QCmsStaticDocumentsQuery = graphql`
  query QCmsStaticDocumentsQuery($documentsFilter: CmsStaticDocumentsFilterInput) {
    cms {
      staticDocuments(documentsFilter: $documentsFilter) {
        fileId
        fileSource
        title
        type
        author
        description
        fileName
      }
    }
  }
`;

export {
  QCmsStaticDocumentsQuery
};
