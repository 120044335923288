import React from 'react';
const R = require('ramda');

const RadioInput = ({ options, name, onChange, value }) => (
  <>
    {R.map((option) => (
      <label key={option.value} className="m-3">
        <input className="mx-1" type="radio" onChange={() => onChange(option)} name={name} value={option.value}/>
        {option.label}
      </label>
    ), R.defaultTo([], options)
    )}
  </>
);

export default RadioInput;
