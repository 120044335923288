import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { Block, LoadingBlock, MessagingRedirectionModal, Page } from '@@components';
import { formatDate } from '@@lib/date';
import ProfessionalInformation from './ProfessionalInformation';
import { getField, getLabel, typologyEq } from './utils';
import { emailTypes, phoneTypes, typologies } from '@@enum';
import { configGA, sendGAEventIfDefined } from '@@lib/ga';
import { filterActiveContracts } from '@@lib/contracts';

const TITLE_MODAL = 'Modifier mes informations' +
  '\npersonnelles';
const TEXT_MODAL = 'La modification des informations personnelles s’effectue' +
  '\ndirectement dans la messagerie.' +
  '\nVous allez être redirigé vers cet espace.';

const Home = ({ pathConfig }) => {
  const [openModal, setOpenModal] = React.useState(false);
  const [hideBlockProfessionalSituation, setHideBlockProfessionalSituation] = React.useState(false);
  const history = useHistory();
  const { user } = useKeycloakSession();
  const { adherent } = useAdherent(user.customerId, ['civilStatus', 'contact', 'professionalInformation', 'contracts']);
  const activeContracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  React.useEffect(() => {
    if (typologyEq(typologies.RETRAITE, activeContracts)) {
      setHideBlockProfessionalSituation(true);
    } else {
      setHideBlockProfessionalSituation(false);
    }
  }, [activeContracts]);

  const getCivilStatusField = getField(adherent.civilStatus);
  const getContactField = getField(adherent.contact);
  const getEmails = () => R.pathOr([], ['contact', 'emails'], adherent);
  const getPhoneNumbers = () => R.pathOr([], ['contact', 'phoneNumbers'], adherent);

  const getMaidenNameLabel = () => R.when(
    RA.isNotNilOrEmpty,
    maidenName => `(né(e) ${maidenName})`
  )(getCivilStatusField(['maidenName']));

  const formatPhoneNumber = R.pipe(R.splitEvery(2), R.join(' '));

  const onEdit = (url, ga) => () => {
    sendGAEventIfDefined(ga);
    history.push(url);
  };

  const getCertifiedContactByType = certifType => R.compose(
    R.prop('value'),
    R.find(R.propEq('type', certifType))
  );

  const handleModalCancelButton = () => {
    setOpenModal(false);
  };

  const handleAdherentEdit = () => {
    setOpenModal(true);
  };

  const messagingRedirectionModal = (
    <MessagingRedirectionModal
      show={openModal}
      setDisabled={handleModalCancelButton}
      onClick={onEdit(pathConfig.personalInformation.editPath)}
      title={TITLE_MODAL}
      text={TEXT_MODAL}
    />
  );

  const getBlockCivilStatus = () => {
    if (!adherent.civilStatus) return <LoadingBlock/>;
    return (
      <div className="f-block-civil-status">
        <Block title={'Adhérent'} onEdit={handleAdherentEdit}>
          <ul>
            <li>
              {
                `${getLabel('civility', getCivilStatusField(['civility']))} 
                ${getCivilStatusField(['lastName'])} 
                ${getCivilStatusField(['firstName'])} 
                ${getMaidenNameLabel()}`
              }
            </li>
            <li>{`Né(e) le ${formatDate(getCivilStatusField(['birthDate']))}`}</li>
            <li>{`N° de sécurité sociale ${getCivilStatusField(['nic'])}`}</li>
          </ul>
        </Block>
        <Block title={'Situation familiale'}
          onEdit={onEdit(pathConfig.familyStatus.editPath)}>
          <ul>
            <li>{`${getLabel('maritalStatus', getCivilStatusField(['maritalStatus']))}`}</li>
          </ul>
        </Block>
      </div>
    );
  };

  const getBlockCoordinates = () => {
    if (!adherent.contact) return <LoadingBlock/>;
    return (
      <div className="f-block-coordinates">
        <Block title={'Téléphones'}
          onEdit={onEdit(pathConfig.phoneNumbers.editPath)}>
          <ul>
            {getPhoneNumbers().map((o, i) => (
              <li key={i}>{formatPhoneNumber(o.value)}</li>
            ))}
          </ul>
        </Block>
        <Block title={'Emails'}
          onEdit={onEdit(pathConfig.emails.editPath)}>
          <ul>
            {getEmails().map((o, i) => (
              <li key={i}>{o.value}</li>
            ))}
          </ul>
        </Block>
        <Block title={'Adresse'}
          onEdit={onEdit(pathConfig.address.editPath, configGA.PROFILE_EDIT_ADDRESS)}>
          <ul>
            <li>{getContactField(['address', 'address1'])}</li>
            <li>{getContactField(['address', 'address2'])}</li>
            <li>{getContactField(['address', 'address3'])}</li>
            <li>{getContactField(['address', 'zipCode'])} {getContactField(['address', 'city'])}</li>
            <li>{getContactField(['address', 'country'])}</li>
            <li>{getContactField(['address', 'pnd'])}</li>
          </ul>
        </Block>
        <Block title={'Identifiants de connexion'}
          onEdit={onEdit(pathConfig.password.editPath)}>
          <div>
            {getCertifiedContactByType(emailTypes.CERTIFIED)(getEmails())} <br/>
            {getCertifiedContactByType(phoneTypes.MOBILE_CERTIFIED)(getPhoneNumbers())}
            <br/>
            <Link className="f-link-turquoise"
              to={pathConfig.password.editPath}>
              {'Modifier mon mot de passe'}
            </Link>
          </div>
        </Block>
      </div>
    );
  };

  const getBlockProfession = () => (
    <ProfessionalInformation
      professionalInformation={adherent.professionalInformation}
      contracts={activeContracts}
      onEdit={onEdit(pathConfig.professionalInformation.editPath)}
    />
  );

  return (
    <Page pageTitle={'Vos informations personnelles'}>
      {messagingRedirectionModal}
      <div className={'f-account-civil-status'}>
        {getBlockCivilStatus()}
      </div>
      <div className={'f-account-coordinates'}>
        <h2 className={'f-account-subtitle'}>
          {'Vos coordonnées'}
        </h2>
        {getBlockCoordinates()}
      </div>
      {!hideBlockProfessionalSituation && (
        <div className={'f-account-profession'}>
          <h2 className={'f-account-subtitle'}>
            {'Votre situation professionnelle'}
          </h2>
          {getBlockProfession()}
        </div>
      )}
    </Page>
  );
};

Home.propTypes = {
  pathConfig: PropTypes.object
};

export default Home;
