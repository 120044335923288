import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { useKeycloakSession } from '@@hooks';
import Block from './components/Block';
import {
  adherentLogo,
  closeDropdownIcon,
  logoAppa,
  logoBesse,
  messagingLogo,
  mobileBgTransp,
  mobileBgWhite,
  openDropdownIcon,
  powerLogo
} from '@@images';
import Menu, { MENU_ITEMS } from '@@pages/home/Menu';
import { useOutsideClick } from '@@hooks/useOutsideClick';
import { useRouteChanged } from '@@hooks/useRouteChanged';
import { equals, gt } from 'ramda';
import { useReferences } from '@@hooks/useReferences';
import { configGA } from '@@lib/ga';

const RA = require('ramda-adjunct');

const R = require('ramda');

/**
 * A block is not visible iff:
 *   "needAuthenticated is true && authenticated is false"
 */
const isBlockVisible = ({ needAuthenticated }, authenticated) => {
  return !R.and(
    R.equals(true)(needAuthenticated),
    R.equals(false)(authenticated)
  );
};

const countVisibleBlocks = (blocks, authenticated) => {
  const mapper = block => isBlockVisible(block, authenticated) ? 1 : 0;
  const countList = R.map(mapper, blocks);
  return R.reduce(R.add, 0, countList);
};

const blockContentToJSX = (block, authenticated, isSingle, isMobile) => {
  const {
    link,
    img,
    blockName,
    blockInfo,
    blockShortName,
    onClick,
    isInABox,
    nbMessagesNotReaded = 0,
    ga
  } = block;

  const blockJSX = <Block
    key={blockName}
    link={link}
    img={img}
    blockName={blockName}
    blockInfo={blockInfo}
    blockShortName={blockShortName}
    onClick={onClick}
    isMobile={isMobile}
    isInABox={isInABox}
    isSingle={isSingle}
    ga={ga}
  />;

  const blockContent = equals('MESSAGERIE', blockName) && gt(nbMessagesNotReaded, 0)
    ? (
      <span className="relative inline-block">
        <span className={'f-top-notification-chips'}>
          {nbMessagesNotReaded}
        </span>
        {blockJSX}
      </span>)
    : blockJSX;

  return isBlockVisible(block, authenticated) && (
    <React.Fragment key={blockName}>
      {blockContent}
    </React.Fragment>
  );
};

const curriedBlockContentToJSX = R.curry(blockContentToJSX);
const blockContentToDesktopJSX = curriedBlockContentToJSX(R.__, R.__, R.__, false);
const blockContentToMobileJSX = curriedBlockContentToJSX(R.__, R.__, R.__, true);

const Top = ({
  progressBar,
  location,
  content,
  menuItems,
  doShowMenu,
  nbMessagesNotReaded,
  messageNotified
}) => {
  const [notifications, setNotifications] = useState([]);
  const {
    extranetMessageThemes,
    backOfficeMessageThemes
  } = useReferences(['backOfficeMessageThemes', 'extranetMessageThemes', 'messageAttachmentTypes']);

  const discussionTitle = (actionId) => {
    const actionIdGroups = R.match(/(?:BO)?ACTION-(\d{2})-?(\d{2})?/, actionId);
    const [themeId, subThemeId] = R.drop(1, actionIdGroups);
    const getMessageThemes = R.includes('BO', actionId) ? backOfficeMessageThemes : extranetMessageThemes;
    let themeTitle = '';
    if (!R.isNil(getMessageThemes)) {
      themeTitle = R.propOr('', 'title', R.find(R.propEq('id', themeId), getMessageThemes));
    }
    const subThemeTitle = R.ifElse(
      RA.isNotNilOrEmpty,
      R.compose(
        R.propOr('', 'title'),
        R.find(R.propEq('id', `${themeId}-${subThemeId}`)),
        R.propOr([], 'subThemes'),
        R.find(R.propEq('id', themeId))
      ),
      R.always([])
    );
    return R.join(' - ', R.reject(R.anyPass([R.isNil, R.isEmpty]), [themeTitle, subThemeTitle(getMessageThemes)]));
  };

  useEffect(() => {
    setNotifications(messageNotified || []);
  }, [messageNotified]);

  // console.log(notifications);

  const [isDropdownDeployed, setIsDropdownDeployed] = useState(false);
  const { authenticated, user, onSignOut } = useKeycloakSession();
  const mobileDropdownRef = useRef(null);
  const toggleDropdown = () => setIsDropdownDeployed(!isDropdownDeployed);
  const closeDropdown = () => setIsDropdownDeployed(false);
  useOutsideClick(mobileDropdownRef, closeDropdown);
  useRouteChanged(closeDropdown);

  const changeBgIfContactsPath = R.cond([
    [R.equals('/contacts'), R.always({
      img: mobileBgWhite
    })]
  ]);

  const blocksContent = [
    {
      link: '/mesinformations',
      img: adherentLogo,
      blockName: 'MON PROFIL',
      needAuthenticated: true,
      ga: configGA.HEADER_PROFILE
    },
    {
      link: '/messagerie',
      img: messagingLogo,
      blockName: 'MESSAGERIE',
      needAuthenticated: true,
      nbMessagesNotReaded,
      ga: configGA.HEADER_MESSAGING
    },
    {
      link: '/contacts',
      img: mobileBgTransp,
      blockName: 'APPELEZ-NOUS',
      blockInfo: R.pathOr('', ['contact', 'appaPhoneNumber'], content),
      blockShortName: 'CONTACT',
      needAuthenticated: false,
      ga: configGA.HEADER_CALL_US,
      ...changeBgIfContactsPath(location.pathname)
    },
    {
      img: powerLogo,
      onClick: onSignOut,
      blockName: 'DÉCONNEXION',
      needAuthenticated: true,
      isInABox: false,
      ga: configGA.HEADER_LOGOUT
    }
  ];

  const logos = (
    <div className={'f-top-logos'}>
      <img className={'f-top-logo-besse'} src={logoBesse} alt={'logo-Bessé'}/>
      <div className={'f-top-logo-spacer'}/>
      <img className={'f-top-logo-appa'} src={logoAppa} alt={'logo-Appa'}/>
    </div>
  );

  const optionalProgressBar = !R.isNil(progressBar) && (
    <div className={'f-top-progress-bar'}>
      {progressBar}
    </div>
  );

  const userDetails = authenticated && (
    <div className={'f-top-user-details'}>
      <div className={'f-top-user-details-header'}>
        {`${user.firstname} ${user.lastname}`}
      </div>
      <div className={'f-top-user-details-body'}>
        {`N° adhérent ${R.propOr('', 'customerId', user)}`}
      </div>
    </div>
  );

  const menu = doShowMenu && <Menu items={menuItems}/>;

  // Number of blocks on display <= 1  ===> hide dropdown button
  const isBlockCountLow = R.lte(
    countVisibleBlocks(blocksContent, authenticated), 1);

  const blocksDesktop = (
    <div className={'f-blocks-desktop'}>
      {blocksContent.map(block =>
        blockContentToDesktopJSX(block, authenticated, isBlockCountLow))}
    </div>
  );

  const blocksMobile = (
    <div className={'f-blocks-mobile'}>
      {blocksContent.map(block =>
        blockContentToMobileJSX(block, authenticated, isBlockCountLow))}
    </div>
  );

  const dropdownButton = (
    <img className={'f-top-dropdown-btn'}
      src={isDropdownDeployed ? closeDropdownIcon : openDropdownIcon}
      alt={'dropdown-button'}
      onClick={toggleDropdown}
    />
  );

  const viewDesktop = (
    <div className={'f-top-desktop'}>
      <div className={'f-top-container-desktop'}>
        <div className={'f-top-head-desktop'}>
          {logos}
          <div className={'f-top-filler-dsk'}/>
          {optionalProgressBar}
          <div className={'f-top-filler-dsk'}/>
          {userDetails}
          {blocksDesktop}
        </div>
      </div>
      {menu}
    </div>
  );

  const viewMobile = (
    <div className={'f-top-mobile'} ref={mobileDropdownRef}>
      <div className={'f-top-head-mobile'}>
        {isDropdownDeployed ? userDetails : logos}
        {isBlockCountLow ? blocksMobile : dropdownButton}
      </div>
      {isDropdownDeployed && (
        <>
          {menu}
          {blocksMobile}
        </>
      )}
    </div>
  );

  // assume that the parent element is "flex flex-col"
  return (
    <>
      {/* {notifications.map(({ _id, discussionId, title, createdAt }) => */}
      {/*  <div key={_id} */}
      {/*    className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" */}
      {/*    role="alert"> */}
      {/*    <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Nouveau message</span> */}
      {/*    <span */}
      {/*      className="font-semibold mr-2 text-left flex-auto">{discussionTitle(title)}, Reçu le {dateToHumanFull(createdAt)}</span> */}

      {/*    <Link to={`/messagerie/${discussionId}`}> */}
      {/*      <svg className="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"> */}
      {/*        <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/> */}
      {/*      </svg> */}
      {/*    </Link> */}
      {/*  </div> */}
      {/* )} */}

      {viewMobile}
      {viewDesktop}
    </>
  );
};

Top.defaultProps = {
  connected: false,
  progressBar: undefined,
  menuItems: MENU_ITEMS,
  doShowMenu: true
};

Top.propTypes = {
  progressBar: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  content: PropTypes.shape({
    contact: PropTypes.shape({
      appaPhoneNumber: PropTypes.string.isRequired
    })
  }),
  doShowMenu: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired
};

export default withRouter(Top);
