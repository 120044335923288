import React from 'react';
import { hot } from 'react-hot-loader/root';
import FirstConnection from '@@pages/firstConnection';
import { withSize } from 'react-sizeme';
import { WithSizeContext } from './withSize';
import ProtectedRoutes from './ProtectedRoutes';
import { QueryParamProvider } from 'use-query-params';
import { Cgu } from '@@pages/misc';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from '@@root/utils/ScrollToTop';

const Routes = ({
  size,
  references
}) => {
  return (
    <Router>
      <ScrollToTop/>
      <QueryParamProvider ReactRouterRoute={Route}>
        <WithSizeContext.Provider value={size}>
          <Switch>
            <Route exact path="/premiereconnexion" component={FirstConnection} />
            <Route exact path="/cgu" component={Cgu} />
            <Route path="/" component={ProtectedRoutes} />
          </Switch>
        </WithSizeContext.Provider>
      </QueryParamProvider>
    </Router>

  );
};

export default hot(withSize()(Routes));
