import React, { useEffect } from 'react';
import { Page, WelcomeBlock } from '@@components';
import Services from './Services';
import { documentLogo, messageLogo, staffofAsclepiusLogo } from '@@images';
import { useAdherent, useCms, useDocumentNotifier, useKeycloakSession, useLastDocument } from '@@hooks';
import { DOCUMENT_TYPE_ADHESION, DOCUMENT_TYPE_ATTESTATION, DOCUMENT_TYPE_ECHEANCIER } from '../documents/utils';
import { hasQueryParamPopupCookies } from '@@pages/home/utils';
import { configGA } from '@@lib/ga';
import { filterActiveContracts } from '@@lib/contracts';

const R = require('ramda');
const RA = require('ramda-adjunct');
const moment = require('moment');
moment.locale('fr');

/**
 * Converts a block object into JSX based on currentAdherentStatus
 */
const blockContentToJSX = (block) => {
  const {
    buttonText,
    buttonLink,
    buttonGa,
    display,
    img,
    link,
    linkText,
    linkGa,
    notificationText,
    notificationNumber,
    text,
    title,
    openButtonLinkInNewTab
  } = block;
  return (
    display && (
      <WelcomeBlock
        key={title}
        title={title}
        img={img}
        notificationText={notificationText}
        text={text}
        buttonText={buttonText}
        buttonLink={buttonLink}
        buttonGa={buttonGa}
        linkText={linkText}
        link={link}
        linkGa={linkGa}
        notificationNumber={notificationNumber}
        openButtonLinkInNewTab={openButtonLinkInNewTab}
      />
    )
  );
};

const Welcome = ({ location, setForceCookiesWelcome, nbMessagesNotReaded }) => {
  const { contact, mercer } = useCms(['contact', 'mercer']);
  const { user } = useKeycloakSession();
  const { lastDocument } = useLastDocument(user.customerId);
  const { nbDocumentssNotReaded } = useDocumentNotifier();
  const { adherent } = useAdherent(user.customerId, ['contact', 'contracts']);

  // This page can force display the popup for cookies
  useEffect(() => {
    setForceCookiesWelcome(hasQueryParamPopupCookies(location));
    return () => {
      setForceCookiesWelcome(false);
    };
  }, []);

  const contracts = R.compose(
    filterActiveContracts,
    R.propOr([], 'contracts')
  )(adherent);

  //
  // Méthodes pour gérer le contenu du bloc "dernier document"
  //
  const mapDocumentType = R.cond([
    [R.equals(DOCUMENT_TYPE_ADHESION), R.always('Adhésion')],
    [R.equals(DOCUMENT_TYPE_ATTESTATION), R.always('Attestation')],
    [R.equals(DOCUMENT_TYPE_ECHEANCIER), R.always('Echéancier')]
  ]);
  const documentNotificationNb = R.gt(nbDocumentssNotReaded, 0) ? nbDocumentssNotReaded : undefined;
  const getDocumentUrl = (document) => `documents/get/${R.propOr('', 'fileId', document)}?source=${R.propOr('', 'fileSource', document)}&fileName=${R.propOr('', 'title', document)}&documentId=${R.propOr('', '_id', document)}&subscriberId=${R.propOr('', 'customerId', user)}&download=false`;
  const getDocumentNotificationText = (document) => `${mapDocumentType(R.propOr('', 'type', document))} : ${R.propOr('', 'title', document)}`;

  const mercerUrl = R.propOr('', 'mercerUrl', mercer);
  const blocks = [{
    title: 'Remboursement santé',
    img: staffofAsclepiusLogo,
    buttonText: 'Consulter mes remboursements',
    buttonLink: mercerUrl,
    buttonGa: configGA.HOME_REFUNDS,
    link: 'complementaire',
    display: true,
    openButtonLinkInNewTab: true
  }, {
    title: 'Messagerie',
    img: messageLogo,
    // notificationText: 'Vous avez reçu une réponse à votre message',
    // text: `Mise à jour de votre situation professionnelle du ${dates.lastMessage}`,
    buttonText: 'Voir tous mes messages',
    buttonGa: configGA.HOME_ACCESS_MESSAGING,
    // buttonText: 'Lire la réponse', // TODO rencap remettre ce libellé en place
    buttonLink: 'messagerie', // TODO rencap rediriger vers la discussion
    // linkText: 'Voir tous mes messages',
    // link: 'messagerie',
    notificationNumber: nbMessagesNotReaded,
    display: true
  }, {
    title: 'Contrats',
    img: documentLogo,
    buttonText: 'Tous mes contrats',
    buttonLink: 'contrats',
    display: R.isEmpty(lastDocument)
  }, {
    title: 'Dernier document disponible',
    img: documentLogo,
    notificationText: getDocumentNotificationText(lastDocument),
    text: moment(R.propOr('', 'createdAt', lastDocument)).format('LL'),
    buttonText: 'Visualiser le document',
    buttonLink: getDocumentUrl(lastDocument),
    buttonGa: configGA.HOME_LAST_DOCUMENT,
    linkText: 'Voir tous les documents',
    link: 'mesdocuments',
    linkGa: configGA.HOME_DOCUMENTS,
    notificationNumber: documentNotificationNb,
    display: RA.isNotEmpty(lastDocument),
    openButtonLinkInNewTab: true
  }];

  return (
    <>
      <Page pageTitle={'Bienvenue'}>
        <div className={'f-welcome-body'}>
          {R.map(b => blockContentToJSX(b), blocks)}
        </div>
      </Page>
      <Services
        adherent={adherent}
        mercer={mercer}
        contact={contact}
        contracts={contracts}
      />
    </>
  );
};

export default Welcome;
