import React from 'react';
import { Controller } from 'react-hook-form';
import Switch from 'rc-switch';
import { iconCheck } from '@@images';

const Input = ({
  control,
  register,
  field,
  errors
}) => (
  <Controller
    name={field.name}
    control={control}
    rules={{ required: field.required }}
    render={({ onChange, value }) => {
      return (
        <div className='flex-shrink-0 mr-4'>
          <Switch
            checked={value}
            onChange={onChange}
            checkedChildren={
              <img className={'mt-1.5'} src={iconCheck} alt='icon-check'/>
            }
          />
        </div>
      );
    }}
  />
);

export default Input;
