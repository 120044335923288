import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { sendGAEventIfDefined } from '@@lib/ga';

const WelcomeBlock = ({
  title,
  img,
  notificationText,
  text,
  buttonText,
  buttonLink,
  linkText,
  link,
  notificationNumber,
  openButtonLinkInNewTab,
  buttonGa,
  linkGa
}) => {
  const handleGAClick = ga => () => {
    sendGAEventIfDefined(ga);
  };
  const hasText = R.any(RA.isNotNilOrEmpty, [text, notificationText]);
  const blockText = hasText && (
    <div>
      {
        RA.isNotNilOrEmpty(notificationText) &&
        <div className="f-welcome-block-notification-text">
          {notificationText}
        </div>
      }
      {
        RA.isNotNilOrEmpty(text) &&
        <div>{text}</div>
      }
    </div>
  );

  return (
    <div className={'f-welcome-block'}>
      <h2 className="f-welcome-block-title">
        {title}
      </h2>
      {img && (
        <div className="relative">
          <img
            className="f-welcome-block-title-img h-28"
            alt={'welcomeBlockLogo'}
            src={img}/>
          {
            R.gt(notificationNumber, 0) &&
            <div className="f-chip f-chip-absolute">{notificationNumber}</div>
          }
        </div>
      )}
      {blockText}
      <div className={'w-full'}>
        {
          openButtonLinkInNewTab
            ? <a
              className="block f-button f-button-coral f-button-xl"
              target="_blank"
              rel="noreferrer"
              href={buttonLink}
              onClick={handleGAClick(buttonGa)}
            >
              {buttonText}
            </a>
            : <Link
              className="block f-button f-button-coral f-button-xl"
              to={buttonLink}
              onClick={handleGAClick(buttonGa)}
            >
              {buttonText}
            </Link>
        }
        {
          RA.isNotNilOrEmpty(linkText) &&
          <div className={'f-welcome-block-footer'}>
            <Link to={link} className="uppercase f-link-turquoise" onClick={handleGAClick(linkGa)}>
              {linkText}
            </Link>
          </div>
        }
        {
          RA.isNilOrEmpty(linkText) &&
          <div className={'f-welcome-block-fake-footer'}/>
        }
      </div>
    </div>
  );
};

WelcomeBlock.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  img: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  notificationNumber: PropTypes.number,
  notificationText: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  title: PropTypes.string.isRequired,
  openButtonLinkInNewTab: PropTypes.bool,
  buttonGa: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }),
  linkGa: PropTypes.shape({
    category: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  })
};

export default WelcomeBlock;
