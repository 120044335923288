import { iconPdf } from '@@images';
import { Block, Loading } from '@@components';
import React from 'react';
import { getGenericDocumentUrl } from '@@lib/contracts';
import PropTypes from 'prop-types';
import * as RA from 'ramda-adjunct';
import { configMA, sendMAEventIfDefined } from '@@lib/ma';
const INTRO_TEXT = 'Effectuer une modification de mon/mes' +
  ' bénéficiaire(s) en cas de décès.';

const PensionPrimaryBlock = ({ loading, benefitsGuide, onlineDeclarationHelpGuide, benefitsDocument }) => {
  const onlineDeclarationHelpGuideUrl = getGenericDocumentUrl({
    document: onlineDeclarationHelpGuide, download: false
  });
  const benefitsGuideUrl = getGenericDocumentUrl({
    document: benefitsGuide, download: false
  });

  const benefitsDocumentButton =
      <a className="f-button f-button-coral" href="https://designation-beneficiaires.generali.fr"
        target="_blank" rel="noreferrer">
        {'Modifier en ligne votre clause bénéficiaires en cas de décès'}
      </a>;
  const benefitsHelpButton =
      <a className="f-button f-button-coral" href="/messagerie/ouvrir?action=03-06"
        target="_self" rel="noreferrer">
        {'Je ne parviens pas à modifier ma clause bénéficiaires en ligne'}
      </a>;

  const onlineDeclarationHelp = loading ? <Loading/>
    : RA.isNilOrEmpty(onlineDeclarationHelpGuideUrl) ? null : (
      <div className="f-contract-pdf-link">
        <img src={iconPdf} alt="pdf-logo"/>
        <a className="f-contract-pdf-link-text" href={ onlineDeclarationHelpGuideUrl }
          target="_blank" rel="noreferrer">
          {'Consulter le guide d\'utilisation'}
        </a>
      </div>
    );
  const benefitsGuideLink = loading ? <Loading/>
    : RA.isNilOrEmpty(benefitsGuide) ? null : (
      <div className="f-contract-pdf-link">
        <img src={iconPdf} alt="pdf-logo"/>
        <a className="f-contract-pdf-link-text" href={ benefitsGuideUrl }
          target="_blank" rel="noreferrer" onClick={() => sendMAEventIfDefined(configMA.CONTRACT_PENSION_UPDATE_DEATH_CLAUSE)}>
          {'En savoir plus sur la déclaration en ligne'}
        </a>
      </div>
    );
  return (
    <Block>
      <div className="f-contract-primary-block">
        <div className="f-contract-primary-title">
          {'Modifier ma clause décès'}
        </div>
        <div className="f-contract-text">
          {INTRO_TEXT}
          <p>Ci-après le numéro de contrat à renseigner sur votre démarche en ligne :</p>
          <ul className={'f-contract-primary-list-item'}>
            <li>
                Si vous possédez un contrat « APPA Formule 3 » : <span className={'f-contract-secondary-text'}>108537</span>
            </li>
            <li>
                Si vous possédez un contrat « APPA Formule A » : <span className={'f-contract-secondary-text'}>201767</span>
            </li>
            <li>Si vous possédez un contrat « APPA Formule 1 » : <span className={'f-contract-secondary-text'}>028303</span>
            </li>
            <li>Si vous possédez un contrat « APPA Internes prévoyance seule », « APPA Internes Option 1 » ou «
                  APPA Internes Option 2 » : <span className={'f-contract-secondary-text'}>11004582</span>
            </li>
            <li>Si vous possédez un contrat « Exercice Libéral Formule 4 » : <span className={'f-contract-secondary-text'}>201769</span>
            </li>
            <li>Si vous possédez un contrat « Exercice Libéral Formule 5 » : <span className={'f-contract-secondary-text'}>201770</span>
            </li>
            <li>Si vous possédez un contrat « Exercice Libéral Complémentaire F6 » ou « Exercice Libéral
                Exclusif F6 » : <span className={'f-contract-secondary-text'}>11012390</span>
            </li>
            <li>Si vous possédez un contrat « Contrat HU F1 », « Contrat HU Madelin F1 », « Contrat
                HU_F1_PrevSeule », « Contrat HU F2 », « Contrat HU Madelin F2 », « Contrat HU_F2_PrevSeule » ; «
                Contrat HU F3 », « Contrat HU Madelin F3 » ou « Contrat « HU_F3_PrevSeule » : <span className={'f-contract-secondary-text'}>11007345</span>
            </li>
            <li>Si vous possédez un contrat « Contrat PH F1 », « Contrat PH Madelin F1 », « Contrat
                PH_F1_PrevSeule », « Contrat PH F2 », « Contrat PH Madelin F2 » ; « Contrat PH_F2_PrevSeule », «
                Contrat PH F3 », « Contrat PH Madelin F3 » ou « Contrat PH_F3_PrevSeule » : <span className={'f-contract-secondary-text'}>11007344</span>
            </li>
            <li>Si vous possédez un contrat « Contrat Début de carrière Prévoyance PH » : <span className={'f-contract-secondary-text'}>11026688</span>
            </li>
            <li>Si vous possédez un contrat « Contrat Début de carrière Prévoyance HU » : <span className={'f-contract-secondary-text'}>11026689</span>
            </li>
          </ul>
        </div>
        <div className="f-contract-primary-buttons">
          {benefitsDocumentButton}
        </div>
        {benefitsGuideLink}
        {onlineDeclarationHelp}
        <div className="f-contract-primary-buttons">
          {benefitsHelpButton}
        </div>
      </div>
    </Block>);
};

PensionPrimaryBlock.propTypes = {
  loading: PropTypes.bool,
  onlineDeclarationHelpGuide: PropTypes.object,
  benefitsGuide: PropTypes.object,
  benefitsDocument: PropTypes.object
};

export default PensionPrimaryBlock;
