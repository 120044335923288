import moment from 'moment';
import Cookies from 'universal-cookie';
import { isNotNil } from 'ramda-adjunct';

const COOKIES = {
  necessary: {
    name: 'cookie_necessary',
    lifespan: { months: 6 }
  },
  performance: {
    name: 'cookie_performance',
    lifespan: { months: 6 }
  }
};

const cookieManager = () => {
  const cookies = new Cookies();

  const setCookie = ({ name, lifespan }) => {
    cookies.set(name, true, {
      path: '/',
      expires: moment().add(lifespan).toDate()
    });
  };

  const hasCookie = ({ name }) => isNotNil(cookies.get(name));

  const removeCookie = ({ name }) => cookies.remove(name);

  const setNecessaryCookie = () => setCookie(COOKIES.necessary);
  const hasNecessaryCookie = () => hasCookie(COOKIES.necessary);
  const removeNecessaryCookie = () => removeCookie(COOKIES.necessary);

  const setPerformanceCookie = () => setCookie(COOKIES.performance);
  const hasPerformanceCookie = () => hasCookie(COOKIES.performance);
  const removePerformanceCookie = () => removeCookie(COOKIES.performance);

  return {
    setNecessaryCookie,
    hasNecessaryCookie,
    removeNecessaryCookie,
    setPerformanceCookie,
    hasPerformanceCookie,
    removePerformanceCookie
  };
};

export default cookieManager;
