import React, {useEffect, useState} from 'react';
import withSize from '@@root/withSize';
import Layout, { Footer, Top } from '@@layout';
import Welcome from './Welcome';
import About from '@@pages/about';
import Account from '@@pages/account';
import Contracts from '@@pages/contracts';
import Contributions from '@@pages/contributions';
import Documents from '@@pages/documents';
import Message from '@@pages/message';
import { Contacts, DonneesPersonnelles, MentionsLegales } from '@@pages/misc';
import { useCms, useKeycloakSession, useAdherentStatus } from '@@hooks';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import TutorialModal from '../firstConnection/TutorialModal';
import useMessageNotifier from '../../hooks/useMessageNotifier';
import CookiesModal from '@@components/modale/CookiesModal';
import cookieManager from '@@root/utils/cookieManager';
import {
  PAGE_ABOUT,
  PAGE_CONTACTS,
  PAGE_CONTRACTS,
  PAGE_DONNEES_PERSONNELLES,
  PAGE_ACCOUNT,
  PAGE_MESSAGE,
  PAGE_CONTRIBUTIONS,
  PAGE_DOCUMENTS, PAGE_MENTIONS_LEGALES,
  PAGE_WELCOME
} from '@@pages/home/utils';
import {getMAnalyticsKey} from "@@lib/ma";
import {isNotNilOrEmpty} from "ramda-adjunct";
import {environment} from "@@root";
import GQLEnvironment from "@@graphql/Environment";

const R = require('ramda');

const Home = () => {
  const { user } = useKeycloakSession();
  const { adherentStatus } = useAdherentStatus(user.customerId);
  const { contact } = useCms(['contact']);
  const { nbMessagesNotReaded, messageNotified } = useMessageNotifier();
  const [missingCookies, setMissingCookies] = useState(
    !cookieManager().hasNecessaryCookie());
  const [forceCookiesWelcome, setForceCookiesWelcome] = useState(false);

  // When the popup prompts a save, check the cookies again
  // todo: is this behavior ok? Do we need to block user access as soon as
  //  the necessary cookie expires (in real time)?
  const onCookieSave = () => {
    setMissingCookies(!cookieManager().hasNecessaryCookie());
    setForceCookiesWelcome(false);
  };

  const showCookies = forceCookiesWelcome || missingCookies;
  const tutorialModal = adherentStatus && (
    <TutorialModal subscriberId={user.customerId} />
  );
  const cookiesModal = <CookiesModal onSave={onCookieSave}/>;

  if (R.isNil(contact)) return null;

  return (
    <Layout
      footer={ <Footer content={{ contact }} /> }
      top={ <Top content={{ contact }}
        nbMessagesNotReaded={nbMessagesNotReaded}
        messageNotified={messageNotified} /> }
      doShowMenu={ true }
    >
      <Switch>
        <Route exact path={PAGE_WELCOME} render={ (props) =>
          <Welcome {...props}
            nbMessagesNotReaded={nbMessagesNotReaded}
            onCookieSave={onCookieSave}
            setForceCookiesWelcome={setForceCookiesWelcome}
          /> }
        />
        <Route path={PAGE_ABOUT} component={About} />
        <Route path={PAGE_CONTRACTS} component={Contracts} />
        <Route path={PAGE_CONTRIBUTIONS} component={Contributions}/>
        <Route path={PAGE_DOCUMENTS} component={Documents}/>
        <Route path={PAGE_MENTIONS_LEGALES} component={MentionsLegales}/>
        <Route path={PAGE_DONNEES_PERSONNELLES} component={DonneesPersonnelles}/>
        <Route path={PAGE_ACCOUNT} component={Account} />
        <Route path={PAGE_MESSAGE} component={Message} />
        <Route path={PAGE_CONTACTS} component={Contacts} />
        <Redirect to={PAGE_WELCOME} />
      </Switch>

      {/* Conditions d'affichage de la popup de cookies:
      - jamais sur la page "Données Personnelles"
      - sur les pages autres que la page d'accueil, affichage ssi les cookies
        nécessaires ne sont pas activés
      - sur la page d'accueil, affichage ssi les cookies nécessaires ne sont
       pas activés ou que le query param forcePopupCookies vaut 'true'. */}
      <Switch>
        <Route path={PAGE_DONNEES_PERSONNELLES}>
          { tutorialModal }
        </Route>
        <Route>
          { showCookies ? cookiesModal : tutorialModal }
        </Route>
      </Switch>

    </Layout>
  );
};

Home.propTypes = {
  location: PropTypes.object
};

export default withSize(Home);
