import React from 'react';
import { Controller } from 'react-hook-form';
import Switch from 'rc-switch';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { getMobilePhoneTypes, phoneTypes } from '@@enum';
import classNames from 'classnames';

const Input = ({
  control,
  register,
  field,
  errors,
  options
}) => {
  const MAX_PHONE_NUMBERS = 3;
  const phoneNumberErrors = R.propOr([], 'phoneNumbersErrors', options);

  const isMobilePhone = R.either(R.startsWith('06'), R.startsWith('07'));

  const getErrors = v => {
    return R.pipe(
      R.find(R.allPass([R.propEq('value', v.value), R.propEq('type', v.type)])),
      R.propOr([], 'errors')
    )(phoneNumberErrors);
  };

  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({
        onChange,
        value
      }) => {
        const onAdd = (e) => {
          e.preventDefault();
          onChange(R.append({
            value: '',
            type: null,
            certified: false
          }, value));
        };
        const onRemove = (index) => (e) => {
          e.preventDefault();
          onChange(R.remove(index, 1, value));
        };
        const onUpdateValue = (index) => (e) => {
          // Get phone number and update value
          const newValue = e.target.value;
          const phoneNumber = R.compose(
            R.assoc('value', newValue),
            R.prop(index)
          )(value);

          // ///////////////////////////////////////////////////////
          // Update type and certified according to the value
          const valueIsMobilePhone = R.propSatisfies(isMobilePhone, 'value');
          const typeIsNilOrEmpty = R.propSatisfies(RA.isNilOrEmpty, 'type');
          const typeIsMobilePhone = R.propSatisfies(R.includes(R.__, getMobilePhoneTypes), 'type');
          const typeIsFixePhone = R.complement(typeIsMobilePhone);

          const manageMobilePhone = R.when( // Fix the type if needed
            R.either(typeIsNilOrEmpty, typeIsFixePhone),
            R.assoc('type', phoneTypes.MOBILE_PERSO)
          );
          const manageFixePhone = R.pipe(
            R.assoc('certified', false), // Set certified to false
            R.when( // Fix the type if needed
              R.either(typeIsNilOrEmpty, typeIsMobilePhone),
              R.assoc('type', phoneTypes.FIXE_PERSO)
            )
          );
          const managePhoneUpdates = R.ifElse(
            valueIsMobilePhone,
            manageMobilePhone,
            manageFixePhone
          );

          // Propagate changes
          onChange(R.set(R.lensIndex(index), managePhoneUpdates(phoneNumber), value));
        };
        const onUpdateCertified = (index) => (option) => {
          const setCertified = R.pipe(
            R.map(R.assoc('certified', false)),
            R.over(R.lensIndex(index), R.assoc('certified', option))
          );
          onChange(setCertified(value));
        };

        return (
          <div className='f-phone-type-container'>
            {value.map((v, i) => {
              const isCertified = R.propOr(false, 'certified', v);
              return (
                <div className='f-phone-type-item' key={i}>
                  <div className='f-block-field-row-header'>
                    <div className='f-block-field-row-header-label'>
                      <span className='whitespace-nowrap'>
                        {'Téléphone *'}
                      </span>
                    </div>
                  </div>
                  <div className='f-phone-type-input-row'>
                    <div className='f-phone-type-input-group-grow'>
                      <input
                        className={'f-phone-type-input-field'}
                        onChange={onUpdateValue(i)}
                        value={v.value}
                        placeholder="Votre numéro de téléphone"
                      />
                      <button className={'f-button-delete'} onClick={onRemove(i)}>
                        <img src={'/icons/trash.svg'}
                          alt={'Supprimer le numéro de téléphone'}/>
                      </button>
                    </div>
                    {
                      R.includes(v.type, getMobilePhoneTypes) &&
                      <div className='f-phone-type-input-group'>
                        <div className='f-phone-type-input-group-switch'>
                          <Switch
                            checked={isCertified}
                            onChange={onUpdateCertified(i)}
                            checkedChildren={
                              <img className={'mt-1.5'}
                                src={'/icons/check.svg'} alt='icon-check'/>
                            }
                          />
                        </div>
                        <div className={classNames(
                          'f-phone-type-input-group-text', {
                            'f-number-certified': isCertified
                          })}>
                          {'Utiliser comme identifiant'}
                        </div>
                      </div>
                    }
                  </div>
                  <div className={'f-phone-type-error-container'}>
                    <ul>
                      {
                        getErrors(v).map((err, idx) => <li key={idx}>{err}</li>)
                      }
                    </ul>
                  </div>
                </div>
              );
            })}
            {
              R.lt(R.length(value), MAX_PHONE_NUMBERS) &&
              <div className='f-edit-family-status-buttons'>
                <button
                  className='f-button f-button-coral-reverse f-responsive-btn'
                  onClick={onAdd}>
                  {'Ajouter un numéro de téléphone'}
                </button>
              </div>
            }
          </div>
        );
      }}
    />
  );
};

export default Input;
