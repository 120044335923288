import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';
import { Controller } from 'react-hook-form';
import { SelectOneOption } from '@@components/input';

const R = require('ramda');
const Input = ({ control, register, field, errors, options }) => {
  const classes = R.keys(errors).includes(field.name) ? 'f-input-red' : 'f-input';
  const _options = R.propOr([], field.source, options);
  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => {
        const onAdd = (e) => {
          e.preventDefault();
          onChange(R.append({ address: '', type: null }, value));
        };
        const onRemove = (index) => (e) => {
          e.preventDefault();
          onChange(R.remove(index, 1, value));
        };
        const onUpdateAddress = (index) => (e) => {
          onChange(R.over(R.lensIndex(index), R.assoc('value', e.target.value), value));
        };
        const onUpdateType = (index) => (option) => {
          onChange(R.over(R.lensIndex(index), R.assoc('type', option.value), value));
        };

        return (
          <div>
            {value.map((v, i) => {
              return (
                <div key={i} className="flex">
                  <input
                    onChange={onUpdateAddress(i)}
                    value={v.value}
                  />
                  <SelectOneOption
                    options={_options}
                    placeholder="type"
                    value={R.find(R.propEq('value', v.type), _options)}
                    onChange={onUpdateType(i)}
                  />
                  <button onClick={onRemove(i)}>{'supprimer'}</button>
                </div>
              );
            })}
            <button onClick={onAdd}>{'ajouter une adresse'}</button>
          </div>
        );
      }}
    />
  );
};

export default Input;
