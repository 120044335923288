import React from 'react';
import Modal from '@@components/modale';
import { confirmMessagingLogo } from '@@images';
import PropTypes from 'prop-types';

const DiscussionConfirmationModal = ({ show, onDisabled, requestConfirmationText }) => {
  const confirmationModalConfig = requestConfirmationText.error ? {
    className: 'f-messaging-confirm-modal-text-error',
    title: 'Votre message n\'a pas pu être envoyé.',
    info: 'Merci de remplir les champs demandés et de valider votre demande.'
  } : {
    className: 'f-messaging-confirm-modal-text-success',
    title: 'Nous avons bien pris en compte votre demande.',
    info: 'Un conseiller vous contactera dans les meilleurs délais.'
  };

  return (
    <Modal show={show} onDisable={onDisabled}
      className={'f-window-messaging-confirm'}
    >
      <div className={'f-messaging-confirm-modal-container'}>
        <img className='f-messaging-modal-img' src={confirmMessagingLogo} alt="img"/>
        <div className={'f-messaging-modal-text'}>
          <p className={confirmationModalConfig.className}>
            {confirmationModalConfig.title}
          </p>
          <p>
            {confirmationModalConfig.info}
          </p>
        </div>
        <div className={'f-messaging-modal-buttons'}>
          <button
            className={'f-button f-button-coral f-edit-family-status-btn'}
            onClick={onDisabled}
          >
            {'Valider'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DiscussionConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onDisabled: PropTypes.func.isRequired,
  requestConfirmationText: PropTypes.shape({
    ok: PropTypes.bool,
    error: PropTypes.bool
  })
};

export default DiscussionConfirmationModal;
