import { equals } from 'ramda';

export const PAGE_WELCOME = '/';
export const PAGE_ABOUT = '/association';
export const PAGE_CONTRACTS = '/contrats';
export const PAGE_CONTRIBUTIONS = '/cotisations';
export const PAGE_DOCUMENTS = '/mesdocuments';
export const PAGE_MENTIONS_LEGALES = '/mentionslegales';
export const PAGE_DONNEES_PERSONNELLES = '/donneespersonnelles';
export const PAGE_ACCOUNT = '/mesinformations';
export const PAGE_MESSAGE = '/messagerie';
export const PAGE_CONTACTS = '/contacts';

export const QUERY_PARAM_POPUP_COOKIES = 'forcePopupCookies';
export const hasQueryParamPopupCookies = (location) => {
  const query = new URLSearchParams(location.search);
  return equals('true', query.get(QUERY_PARAM_POPUP_COOKIES));
};

// Test non exhaustif mais devrait suffire à gérer le cas d'usage
export const isDomTom = zipCode => /^9[78]/.test(zipCode);
export const isNotDomTom = zipCode => !isDomTom(zipCode);
