import React from 'react';
import { Block } from '@@components';

const LoadingBlock = () => (
  <Block>
    {'Chargement en cours'}
  </Block>
);

export default LoadingBlock;
