import React from 'react';
import PropTypes from 'prop-types';
const R = require('ramda');
const RA = require('ramda-adjunct');

const ProgressBar = ({
  label,
  maxStep,
  step,
  forgotPasswordTunnel
}) => {
  // réaffection des chiffres des étapes dans le cas où l'étape 2 est sauté
  const forgotPasswordTunnelStep = R.cond([
    // le composant step 5 est est considéré en étape 6
    [R.equals('5'), R.always('6')],
    // le composant step 6 est est considéré en étape 4
    [R.equals('6'), R.always('4')],
    // le composant step 7 est est considéré en étape 5
    [R.equals('7'), R.always('5')],
    // le composant step 4 est est considéré en étape 3
    [R.equals('4'), R.always('3')],
    // le composant step 2Bis est est considéré en étape 2
    [R.equals('2'), R.always('2')]
  ]);

  const stepToDisplay = R.when(R.always(RA.isTrue(forgotPasswordTunnel)), forgotPasswordTunnelStep)(step);

  return (
    <>
      {!R.isNil(label) && <div className="f-progress-bar-title">{label}</div>}
      <div className="f-progress-bar-wrapper">
        <div className="f-progress-bar" style={{ width: `${100 * stepToDisplay / maxStep}%` }} />
      </div>
    </>
  );
};

ProgressBar.defaultProps = {
  label: undefined
};

ProgressBar.propTypes = {
  label: PropTypes.string,
  maxStep: PropTypes.number,
  step: PropTypes.string,
  forgotPasswordTunnel: PropTypes.bool
};

export default ProgressBar;
