const options = [{
  value: 'Email_Pro',
  label: 'Professionnel'
}, {
  value: 'Email_Perso',
  label: 'Personnel'
}, {
  value: 'Email_Cerifié',
  label: 'Certifié'
}];

export default options;
