/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CmsDocumentsFilterInput = {|
  type?: ?string,
  year?: ?number,
|};
export type QCmsDocumentsQueryVariables = {|
  documentsFilter?: ?CmsDocumentsFilterInput
|};
export type QCmsDocumentsQueryResponse = {|
  +cms: ?{|
    +documents: ?$ReadOnlyArray<?{|
      +title: ?string,
      +url: ?string,
      +date: ?string,
    |}>
  |}
|};
export type QCmsDocumentsQuery = {|
  variables: QCmsDocumentsQueryVariables,
  response: QCmsDocumentsQueryResponse,
|};
*/


/*
query QCmsDocumentsQuery(
  $documentsFilter: CmsDocumentsFilterInput
) {
  cms {
    documents(documentsFilter: $documentsFilter) {
      title
      url
      date
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentsFilter"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "documentsFilter",
            "variableName": "documentsFilter"
          }
        ],
        "concreteType": "CmsDocument",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsDocumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCmsDocumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9240191bc4cae4d80f683497ebd74815",
    "id": null,
    "metadata": {},
    "name": "QCmsDocumentsQuery",
    "operationKind": "query",
    "text": "query QCmsDocumentsQuery(\n  $documentsFilter: CmsDocumentsFilterInput\n) {\n  cms {\n    documents(documentsFilter: $documentsFilter) {\n      title\n      url\n      date\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ffe402c8a2ad0a34d66499f801c5ce1';

module.exports = node;
