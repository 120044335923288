import React from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { Redirect } from 'react-router-dom';
import Modal from '@@components/modale';
import * as RA from 'ramda-adjunct';

const TEXT_MODAL_1 = 'Validez votre email';
const TEXT_MODAL_2 = 'Un code vous a été envoyé par email.';

const EditEmailsOtp = ({ show, setDisabled, uuid, subscriberId, email }) => {
  const {
    fetching: fetchingOtp,
    onFormsSubmit: onFormsSubmitOtp,
    formsData: formsDataOtp
  } = useForms('otpUpdateEmail');

  const isDisabled = fetchingOtp.isFetching();

  const [verifyOtpSuccess, setVerifyOtpSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  React.useEffect(() => {
    if (fetchingOtp.isDone()) {
      setVerifyOtpSuccess(true);
      setDisabled(false);
    }
    if (fetchingOtp.getError()) {
      setErrorMessage(fetchingOtp.getError());
    }
  }, [fetchingOtp.isDone(), fetchingOtp.getPayload(), fetchingOtp.getError()]);

  const handleCancelButton = () => {
    setDisabled(false);
  };

  return (
    <>
      {
        verifyOtpSuccess && <Redirect to="/mesinformations" />
      }
      <Modal show={show} onDisable={handleCancelButton}
        className={'f-modal-window-edit-email-or-phone'}
      >
        <div className='f-edit-phone-modal-container'>
          <div className='f-edit-phone-modal-title'>
            { TEXT_MODAL_1 }
          </div>
          <div className='f-edit-phone-modal-intro'>
            { TEXT_MODAL_2 }
          </div>
          {
            RA.isNotNil(uuid) &&
            <div className='f-edit-phone-modal-forms'>
              <Forms
                formsData={formsDataOtp}
                columns={1}
                defaultValues={{
                  id: uuid,
                  subscriberId,
                  email
                }}
                onChange={() => fetchingOtp.reset()}
                onSubmit={onFormsSubmitOtp}
                submitSection={() => (
                  <div>
                    {errorMessage && (
                      <div className='f-phone-type-error-container'>
                        {errorMessage}
                      </div>)
                    }
                    <div className='f-edit-phone-modal-buttons'>
                      <button
                        className='f-button f-button-grey f-responsive-btn'
                        onClick={handleCancelButton}
                      >
                        {'Annuler'}
                      </button>
                      <button
                        className='f-button f-button-coral f-responsive-btn'
                        disabled={isDisabled}
                        type='submit'
                      >
                        {'Confirmer mon email'}
                      </button>
                    </div>
                  </div>
                )}
              />
            </div>
          }
        </div>
      </Modal>
    </>
  );
};

EditEmailsOtp.propTypes = {
  setDisabled: PropTypes.func.isRequired,
  uuid: PropTypes.string,
  subscriberId: PropTypes.string.isRequired,
  email: PropTypes.string,
  show: PropTypes.bool.isRequired
};

export default EditEmailsOtp;
