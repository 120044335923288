/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ForgotOldPasswordInput = {|
  email: string,
  subscriberId: string,
|};
export type ForgotOldPasswordMutationVariables = {|
  input: ForgotOldPasswordInput
|};
export type ForgotOldPasswordMutationResponse = {|
  +forgotOldPassword: ?{|
    +ok: boolean,
    +error: ?string,
    +otp: ?string,
    +anonEmail: ?string,
  |}
|};
export type ForgotOldPasswordMutation = {|
  variables: ForgotOldPasswordMutationVariables,
  response: ForgotOldPasswordMutationResponse,
|};
*/


/*
mutation ForgotOldPasswordMutation(
  $input: ForgotOldPasswordInput!
) {
  forgotOldPassword(input: $input) {
    ok
    error
    otp
    anonEmail
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ForgotOldPasswordMutationResponse",
    "kind": "LinkedField",
    "name": "forgotOldPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "otp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "anonEmail",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotOldPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotOldPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9bb28419d0446d3347accd90259bfce7",
    "id": null,
    "metadata": {},
    "name": "ForgotOldPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotOldPasswordMutation(\n  $input: ForgotOldPasswordInput!\n) {\n  forgotOldPassword(input: $input) {\n    ok\n    error\n    otp\n    anonEmail\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d8e5824cfd387a8d1860ef4222b6e08';

module.exports = node;
