import { graphql } from 'react-relay';

const QAdherentContractDetailsQuery = graphql`
  query QAdherentContractDetailsQuery($id: ID!, $filter: ContractFilterInput) {
    adherent(id: $id) {
      id
      contract(filter: $filter) {
        id
        startDate
        endDate
        contract
        category
        directDebit
        periodicity
        activityRate
        closurePattern
        SEPAMandate {
          RUM
          signatureDate
          revocationDate
          account {
            startDate
            endDate
            IBAN
            BIC
            holder
          }
        }
        pricingOptions {
          startDate
          endDate
          type
          index
          valueType
          value
        },
        detail {
          label
          typology
          function
          activity
          health {
            option
            complementaryText
            guarantee {
              family
              label
              value
            }
          }
          pension {
            complementaryText
            guarantee {
              family
              label
              value
            }
          }
          assistance {
            complementaryText
            guarantee {
              family
              label
              value
            }
          }
        }
      }
    }
  }
`;

export {
  QAdherentContractDetailsQuery
};
