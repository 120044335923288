/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [
  
  {
    name: 'civility',
    required: true,
    input: 'select',
    source: 'civility',
    label: "__UNDEFINED__",
    placeholder: "civilité",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'firstName',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Prénom",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'lastName',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Nom",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'maidenName',
    required: false,
    input: 'text',
    source: '__UNDEFINED__',
    label: "Nom de naissance",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'birthDate',
    required: true,
    input: 'date',
    source: '__UNDEFINED__',
    label: "Date de naissance",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
  {
    name: 'nic',
    required: true,
    input: 'text',
    source: '__UNDEFINED__',
    label: "N° de sécurité sociale",
    placeholder: "__UNDEFINED__",
    
    handlers: {
      visible: undefined
    }
  },
  
];

export default formData;