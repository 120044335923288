import React from 'react';
import * as RA from 'ramda-adjunct';
import * as R from 'ramda';

const Input = ({ register, field, errors, options }) => {
  const classes = R.keys(errors).includes(field.name)
    ? 'f-input-red' : 'f-input';

  const emailErrors = R.propOr([], 'emailErrors', options);
  const hasErrors = RA.isNotNilOrEmpty(emailErrors);
  const errorToJSX = (err, idx) => (<li key={idx}>{err}</li>);

  return (
    <div className={'w-full flex flex-col gap-2'}>
      <input
        className={classes}
        ref={register({ required: field.required })}
        type='email'
        name={field.name}
        placeholder={field.placeholder}
      />
      {hasErrors && (
        <div className={'f-phone-type-error-container'}>
          <ul>
            { RA.mapIndexed(errorToJSX, emailErrors) }
          </ul>
        </div>
      )}
    </div>
  );
};

export default Input;
