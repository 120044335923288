import React from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { MessageRecord } from '@@graphql/records/MessagesRecord';
import { useReferences } from '@@hooks';
import { ErrorMessage } from '@@components';

const ReplyTo = ({
  title,
  discussionId,
  recipients,
  lastMessageSenderId,
  collaboratorName
}) => {
  const [errorMessage, setErrorMessage] = React.useState(null);
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('sendMessage', {
    updater: (input, store, response) => {
      if (RA.isNotNil(R.path(['sendMessage', 'error'], response))) return;
      if (R.isNil(R.prop('sendMessage', response))) return;

      const rec = MessageRecord(store);

      const message = {
        ...R.path(['sendMessage', 'message'], response),
        dates: R.path(['sendMessage', 'dates'], response),
        attachments: R.path(['sendMessage', 'attachments'], response)
      };

      rec.addMessage(
        R.path(['sendMessage', 'messageId'], response),
        discussionId,
        message
      );
    }
  });

  const { messageAttachmentTypes } = useReferences(['messageThemes', 'messageAttachmentTypes']);

  const errorBox = RA.isNotNilOrEmpty(errorMessage) && (
    <ErrorMessage value={errorMessage}/>
  );

  React.useEffect(() => {
    setErrorMessage(fetching.getError());
  }, [fetching.getError()]);

  const getDefaultValue = React.useCallback(() => {
    const defaultValue = { discussionId };
    if (!R.isNil(discussionId)) {
      return R.compose(
        R.assoc('title', title),
        R.assoc('actionId', title),
        R.assoc('text', ''),
        R.assoc('attachments', [])
      )(defaultValue);
    }
    return defaultValue;
  }, [title, discussionId]);

  const getHiddenValue = React.useCallback(() => {
    const hiddenFields = { discussionId };
    if (!R.isNil(discussionId)) {
      return R.compose(
        R.assoc('title', title),
        R.assoc('actionId', title)
      )(hiddenFields);
    }
    return hiddenFields;
  }, [title, discussionId]);

  return (
    <div className="f-discussion-reply-container">
      <Forms
        formsData={formsData}
        columns={1}
        defaultValues={getDefaultValue()}
        hiddenFields={getHiddenValue()}
        onSubmit={onFormsSubmit}
        options={{
          documentTypeOptions: messageAttachmentTypes
        }}
        resetOnSubmit={true}
        submitSection={() => (
          <div className={'mt-4'}>
            <button
              className={'f-button f-button-coral w-min'}
              type={'submit'}
              disabled={fetching.isFetching()}
            >
              {'Envoyer'}
            </button>
            { errorBox }
          </div>)}
      />
    </div>);
};

ReplyTo.propTypes = {
  title: PropTypes.string.isRequired,
  discussionId: PropTypes.string.isRequired,
  recipients: PropTypes.arrayOf(PropTypes.string).isRequired,
  lastMessageSenderId: PropTypes.string.isRequired,
  collaboratorName: PropTypes.string
};

ReplyTo.defaultProps = {};

export default ReplyTo;
