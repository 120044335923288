import React from 'react';

const WithSizeContext = React.createContext();

export default function withSize (Component) {
  return function WithSizeComponent (props) {
    return (
      <WithSizeContext.Consumer>
        {(size) => <Component
          {...props}
          size={{
            width: size.width,
            isDesktop: size.width > 780
          }}
        />}
      </WithSizeContext.Consumer>
    );
  };
}

export {
  WithSizeContext
};
