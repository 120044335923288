import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { iconEdit } from '@@images';

const R = require('ramda');
const Block = ({
  children,
  title,
  onEdit,
  displayShadow = true,
  leftLogo,
  additionalClasses,
  additionalStyles
}) => {
  const cardClass = classNames('flex', {
    'f-card': displayShadow,
    'f-card-no-shadow': !displayShadow
  }, additionalClasses);

  const isLeftLogo = !R.isNil(leftLogo);

  const blockLogoDesktop = isLeftLogo && (
    <div className={'f-block-logo-dsk'}>
      <img src={leftLogo} alt={'Logo gauche'}/>
    </div>
  );
  const blockLogoMobile = isLeftLogo && (
    <div className={'f-block-logo-mob'}>
      <img src={leftLogo} alt={'Logo gauche'}/>
    </div>
  );

  const blockTitleDesktop = title && (
    <div className={'f-block-title-dsk'}>{title}</div>);
  const blockTitleMobile = title && (
    <div className={'f-block-title-mob'}>{title}</div>);

  const blockEditButton = R.is(Function, onEdit) && (
    <div className={'f-block-edit-btn'} onClick={onEdit}>
      <img src={iconEdit} alt={'Edit icon'}/>
    </div>
  );

  return (
    <div style={additionalStyles} className={cardClass}>
      { blockLogoDesktop }
      <div className={'f-block w-full'}>
        <div className={'f-block-header-container'}>
          <div className={'f-block-header'}>
            { blockLogoMobile }
            { blockTitleDesktop }
            { !isLeftLogo && blockTitleMobile }
            { blockEditButton }
          </div>
          { isLeftLogo && blockTitleMobile }
        </div>
        {children}
      </div>
    </div>
  );
};

Block.defaultProps = {
  additionalClasses: '',
  additionalStyles: {}
};

Block.propTypes = {
  title: PropTypes.string,
  onEdit: PropTypes.func,
  displayShadow: PropTypes.bool,
  leftLogo: PropTypes.string,
  additionalClasses: PropTypes.string.isRequired,
  additionalStyles: PropTypes.object.isRequired
};

export default Block;
