import React from 'react';
import { Controller } from 'react-hook-form';

const R = require('ramda');

const Input = ({
  register,
  field,
  control,
  getValues,
  options,
  errors
}) => {
  const _options = R.propOr([], field.source, options);
  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => (
        <div className={'f-button-toggle-grid'}>
          {
            R.map(({ value: optValue, label }) => (
              <label key={optValue} className={(R.equals(value, optValue)
                ? 'f-button-toggle-selected' : 'f-button-toggle')}>
                <input
                  type='radio'
                  name={field.name}
                  className='appearance-none'
                  onChange={() => onChange(optValue)}
                />
                <span>{label}</span>
              </label>
            ), _options)
          }
        </div>
      )}
    />
  );
};

export default Input;
