import React from 'react';
import PropTypes from 'prop-types';
import { mobileBgTranspMed } from '@@images';
import { useRelayEnvironment } from 'react-relay';
import ReSendOtpSmsMutation from '../../_graphql/mutations/ReSendOtpSmsMutation';
import Modal from '@@components/modale';

const getModalText = () => 'Un code vient de vous être envoyé' +
  '\nà nouveau par SMS.';

const ReSendOtpSmsModal = ({ setUuid, subscriberId, phoneNumber }) => {
  const environment = useRelayEnvironment();
  const [openModal, setOpenModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);

  const onSubmit = () => {
    ReSendOtpSmsMutation(environment, { subscriberId, phoneNumber }, (ok, error, otp) => {
      if (ok) {
        setUuid(otp);
        setOpenModal(true);
      } else {
        setErrorMessage('Une erreur est survenue lors de l\'envoi du code');
      }
    });
  };

  return (
    <>
      {/* todo this button is duplicated in Step5And7.js */}
      <button
        className="f-link-turquoise"
        type="button"
        onClick={onSubmit}
      >
        {'Je n\'ai pas reçu le code'}
      </button>
      {errorMessage && (
        <div className='text-danger'>
          {errorMessage}
        </div>
      )}
      <Modal show={openModal} onDisable={() => setOpenModal(false)}
        className={'f-window-messaging-confirm'}
      >
        <div className='f-messaging-modal-container'>
          <img className='f-resend-otp-sms-modal-img' src={mobileBgTranspMed} alt='img'/>
          <div className='f-resend-otp-sms-modal-text'>
            { getModalText() }
          </div>
          <div className='f-messaging-modal-buttons'>
            <button
              className='f-button f-button-coral f-edit-family-status-btn'
              onClick={() => setOpenModal(false)}
              type='button'
            >
              {'Entrer le code'}
            </button>
          </div>
        </div>
      </Modal>
    </>);
};

ReSendOtpSmsModal.propTypes = {
  setUuid: PropTypes.func.isRequired,
  subscriberId: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
};

export default ReSendOtpSmsModal;
