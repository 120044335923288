import { graphql } from 'react-relay';

const QCmsMessagingSignatureQuery = graphql`
  query QCmsMessagingSignatureQuery {
    cms {
      messagingSignature {
        signature
      }
    }
  }
`;

export {
  QCmsMessagingSignatureQuery
};
