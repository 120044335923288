import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { useAdherent, useKeycloakSession } from '@@hooks';
import { Link, useHistory } from 'react-router-dom';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import Modal from '@@components/modale';
import { phoneTypes } from '@@enum';
import { BreadCrumb } from '@@components';

const TEXT_TITLE = 'Modification de vos coordonnées téléphoniques';
const TEXT_1 = 'Seul un numéro de téléphone mobile pourra être utilisé ' +
  'comme identifiant de connexion.';
const TEXT_MODAL_1 = 'Validez votre numéro de téléphone';
const TEXT_MODAL_2 = 'Un code vous a été envoyé par SMS pour valider votre' +
  ' numéro de téléphone.';

const EditPhoneNumbers = ({ pathConfig }) => {
  const history = useHistory();
  const { user } = useKeycloakSession();
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('adherentPhoneNumbers');
  const {
    fetching: fetchingOtp,
    onFormsSubmit: onFormsSubmitOtp,
    formsData: formsDataOtp
  } = useForms('adherentPhoneNumbersOtp');

  const { adherent } = useAdherent(user.customerId, ['contact']);
  const [phoneNumbersErrors, setPhoneNumbersErrors] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (fetching.isDone()) {
      const payload = fetching.getPayload();
      const phoneNumberErrors = R.propOr([], 'phoneNumberErrors', payload);

      if (R.any(R.propSatisfies(RA.isNotNilOrEmpty, 'errors'), phoneNumberErrors)) {
        // Show errors if at least one phone number with error
        setPhoneNumbersErrors(phoneNumberErrors);
      } else if (R.propSatisfies(RA.isNotNilOrEmpty, 'otpId', payload)) {
        // Remove errors attributes from phoneNumbers
        const cleanedPayload = R.over(R.lensProp('phoneNumberErrors'),
          R.map(R.dissoc('errors')), payload);
        setData(cleanedPayload);
        setOpenModal(true);
      } else {
        history.push(pathConfig.cancelPath);
      }
    }
  }, [fetching.isDone(), fetching.getPayload()]);

  useEffect(() => {
    if (fetchingOtp.isDone()) {
      history.push(pathConfig.cancelPath);
    }
  }, [fetchingOtp.isDone()]);

  if (R.isNil(adherent.contact)) return null;

  const updateTypeIfCertified = R.when(
    R.propEq('type', phoneTypes.MOBILE_CERTIFIED),
    R.assoc('certified', true)
  );
  const subscriberPhoneNumbers = R.pipe(
    // Remove fixe pro phones
    R.pathOr([], ['contact', 'phoneNumbers']),
    R.reject(R.propSatisfies(RA.isNilOrEmpty, 'value')),
    R.filter(RA.propNotEq('type', phoneTypes.FIXE_PRO)),
    // Set certified
    R.map(updateTypeIfCertified)
  )(adherent);

  const breadCrumb = <BreadCrumb
    rootPath={'mesinformations'}
    rootName={'Mes informations personnelles'}
    location={TEXT_TITLE}/>;

  const title = (
    <div className='subsection-title'>
      {TEXT_TITLE}
    </div>
  );

  const blockForms = (
    <Forms
      formsData={formsData}
      columns={1}
      defaultValues={{
        phoneNumbers: subscriberPhoneNumbers,
        subscriberId: user.customerId
      }}
      options={{ phoneNumbersErrors }}
      onChange={fetching.reset}
      onSubmit={onFormsSubmit}
      submitSection={() => (
        <div className='f-edit-family-status-buttons mt-8'>
          <Link
            className='f-button f-button-grey f-responsive-btn'
            to={pathConfig.cancelPath}
          >
            {'Annuler'}
          </Link>
          <button
            className='f-button f-button-coral f-responsive-btn'
            disabled={fetching.isFetching()}
            type='submit'
          >
            {'Enregistrer'}
          </button>
        </div>
      )}
    />
  );

  const modal = (
    <Modal show={openModal} onDisable={() => setOpenModal(false)}
      className={'f-modal-window-edit-email-or-phone'}
    >
      <div className='f-edit-phone-modal-container'>
        <div className='f-edit-phone-modal-title'>
          { TEXT_MODAL_1 }
        </div>
        <div className='f-edit-phone-modal-intro'>
          { TEXT_MODAL_2 }
        </div>
        {
          R.propSatisfies(RA.isNotNil, 'otpId', data) &&
          <div className='f-edit-phone-modal-forms'>
            <Forms
              formsData={formsDataOtp}
              columns={1}
              defaultValues={{
                code: '',
                otpId: R.prop('otpId', data),
                subscriberId: user.customerId
              }}
              onChange={() => fetchingOtp.reset()}
              onSubmit={value => {
                // Setting manually phoneNumbers to form data
                const phoneNums = R.prop('phoneNumberErrors', data);
                return onFormsSubmitOtp(R.assoc('phoneNumbers', phoneNums, value));
              }}
              submitSection={() => (
                <div>
                  {fetchingOtp.isDoneWithError() && (
                    <div className="f-phone-type-error-container">
                      {'Code invalide'}
                    </div>
                  )}
                  <div className='f-edit-phone-modal-buttons'>
                    <button
                      className='f-button f-button-grey f-responsive-btn'
                      onClick={() => setOpenModal(false)}
                    >
                      {'Annuler'}
                    </button>
                    <button
                      className='f-button f-button-coral f-responsive-btn'
                      disabled={fetchingOtp.isFetching()}
                      type="submit"
                    >
                      {'Confirmer mon téléphone'}
                    </button>
                  </div>
                </div>
              )}
            />
          </div>
        }
      </div>
    </Modal>
  );

  return (
    <div className='f-base-page-container'>
      <div className='f-page'>
        <div className='f-page-body'>
          { breadCrumb }
          <div className='f-page-content-header'>
            { title }
          </div>
          <div className='f-edit-phone-numbers-intro'>
            {TEXT_1}
          </div>
          {blockForms}
          {modal}
        </div>
      </div>
    </div>
  );
};

EditPhoneNumbers.propTypes = {
  pathConfig: PropTypes.shape({
    cancelPath: PropTypes.string
  })
};

export default EditPhoneNumbers;
