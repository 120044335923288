import { graphql } from 'react-relay';

const QExtranetMessageThemesQuery = graphql`
  query QExtranetMessageThemesQuery {
      references {
        messageThemesExtranet {
            id
            title
            actionId
            label
            subThemes {
              id
              actionId
              title
              attachments {
                mandatory
                optional {
                  context
                  attachement
                }
              }
            }
        }
      }
    }
`;

export {
  QExtranetMessageThemesQuery
};
