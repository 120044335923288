/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractTypologyEnum = "PHHU" | "interne" | "liberal" | "retraite" | "%future added value";
export type HealthOptionEnum = "famille" | "isole" | "%future added value";
export type PeriodicityEnum = "Annuel" | "Hebdomadaire" | "Mensuel" | "Quotidien" | "Semestriel" | "Trimestriel" | "%future added value";
export type QAdherentContractsQueryVariables = {|
  id: string
|};
export type QAdherentContractsQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +contracts: ?$ReadOnlyArray<?{|
      +id: string,
      +startDate: ?string,
      +endDate: ?string,
      +contract: ?string,
      +category: ?string,
      +activityRate: ?number,
      +pricingOptions: ?$ReadOnlyArray<?{|
        +type: ?string,
        +index: ?string,
      |}>,
      +SEPAMandate: ?{|
        +account: ?{|
          +IBAN: ?string,
          +holder: ?string,
        |}
      |},
      +directDebit: ?boolean,
      +periodicity: ?PeriodicityEnum,
      +detail: ?{|
        +category: ?string,
        +contract: ?string,
        +label: ?string,
        +typology: ?ContractTypologyEnum,
        +function: ?string,
        +activity: ?string,
        +health: ?{|
          +option: ?HealthOptionEnum,
          +guarantee: ?$ReadOnlyArray<?{|
            +value: ?string
          |}>,
        |},
        +pension: ?{|
          +guarantee: ?$ReadOnlyArray<?{|
            +value: ?string
          |}>
        |},
        +assistance: ?{|
          +guarantee: ?$ReadOnlyArray<?{|
            +value: ?string
          |}>
        |},
      |},
    |}>,
  |}
|};
export type QAdherentContractsQuery = {|
  variables: QAdherentContractsQueryVariables,
  response: QAdherentContractsQueryResponse,
|};
*/


/*
query QAdherentContractsQuery(
  $id: ID!
) {
  adherent(id: $id) {
    id
    contracts {
      id
      startDate
      endDate
      contract
      category
      activityRate
      pricingOptions {
        type
        index
      }
      SEPAMandate {
        account {
          IBAN
          holder
        }
      }
      directDebit
      periodicity
      detail {
        category
        contract
        label
        typology
        function
        activity
        health {
          option
          guarantee {
            value
          }
        }
        pension {
          guarantee {
            value
          }
        }
        assistance {
          guarantee {
            value
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contract",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "category",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activityRate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PricingOption",
  "kind": "LinkedField",
  "name": "pricingOptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "index",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SEPAMandate",
  "kind": "LinkedField",
  "name": "SEPAMandate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SEPAMandateAccount",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "IBAN",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "holder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "directDebit",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "periodicity",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typology",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "function",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activity",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractGuarantee",
  "kind": "LinkedField",
  "name": "guarantee",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractHealth",
  "kind": "LinkedField",
  "name": "health",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "option",
      "storageKey": null
    },
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = [
  (v16/*: any*/)
],
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractPension",
  "kind": "LinkedField",
  "name": "pension",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ContractAssistance",
  "kind": "LinkedField",
  "name": "assistance",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentContractsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Adherent",
        "kind": "LinkedField",
        "name": "adherent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdherentContrat",
            "kind": "LinkedField",
            "name": "contracts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contrat",
                "kind": "LinkedField",
                "name": "detail",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAdherentContractsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Adherent",
        "kind": "LinkedField",
        "name": "adherent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "AdherentContrat",
            "kind": "LinkedField",
            "name": "contracts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contrat",
                "kind": "LinkedField",
                "name": "detail",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "558553d39649d00795b2005ff56e7532",
    "id": null,
    "metadata": {},
    "name": "QAdherentContractsQuery",
    "operationKind": "query",
    "text": "query QAdherentContractsQuery(\n  $id: ID!\n) {\n  adherent(id: $id) {\n    id\n    contracts {\n      id\n      startDate\n      endDate\n      contract\n      category\n      activityRate\n      pricingOptions {\n        type\n        index\n      }\n      SEPAMandate {\n        account {\n          IBAN\n          holder\n        }\n      }\n      directDebit\n      periodicity\n      detail {\n        category\n        contract\n        label\n        typology\n        function\n        activity\n        health {\n          option\n          guarantee {\n            value\n          }\n        }\n        pension {\n          guarantee {\n            value\n          }\n        }\n        assistance {\n          guarantee {\n            value\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eb2e2901d579f159d76c33e37fba4993';

module.exports = node;
