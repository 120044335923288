import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { contractHealthOption, typologies } from '@@enum';
import { limitedDateToHuman } from '@@lib/dateToHumanFull';
import moment from 'moment';
import { configGA } from '@@lib/ga';
import {configMA} from "./ma";

export const SEPARATOR = '__c__';

export const CONTRACT_TYPE_HEALTH = 'health';
export const CONTRACT_TYPE_PENSIONH = 'pensionH';
export const CONTRACT_TYPE_PENSIONL = 'pensionL';
export const CONTRACT_TYPE_ASSISTANCE = 'assistance';
export const contractOrder = R.compose(
  R.cond([
    [R.equals(CONTRACT_TYPE_HEALTH), R.always(0)],
    [R.equals(CONTRACT_TYPE_PENSIONH), R.always(1)],
    [R.equals(CONTRACT_TYPE_PENSIONL), R.always(2)],
    [R.equals(CONTRACT_TYPE_ASSISTANCE), R.always(3)],
    [R.T, R.always(4)]
  ]),
  R.propOr('', 'contractType')
);

export const isContractOfType = type => contract => R.propEq('contractType', type, contract);

export const hasContractTypologies = typologies => contract => R.pathSatisfies(R.includes(R.__, typologies), ['detail', 'typology'], contract);

export const hasGuaranteeOfType = type => R.pathSatisfies(RA.isNotNilOrEmpty, ['detail', type, 'guarantee']);

// todo - investigate: l'adhérent 1854729 a un contrat de type "pensionL"
//  mais aucun des documents génériques remontés du cms n'a le subtype
//  all_covers_pension_liberal pour cet adhérent.
//  ==> le bouton "Consulter mes garanties" ne peut pas s'afficher
//  correctement sur la page "Couverture Prévoyance Activité Libérale".
const contractTypeLabels = {
  health: {
    title: 'Ma complémentaire santé',
    guarantee: 'Mes garanties santé',
    icon: '/images/pictos-17@3x.png',
    allCoversDocument: 'all_covers_health'
  },
  pensionH: {
    title: 'Ma couverture prévoyance hospitalière',
    guarantee: 'Mes garanties prévoyance hospitalière',
    icon: '/images/pictos-27@3x.png',
    allCoversDocument: 'all_covers_pension_hospi'
  },
  pensionL: {
    title: 'Ma couverture prévoyance activité libérale',
    guarantee: 'Mes garanties prévoyance activité libérale',
    icon: '/images/pictos-05@3x.png',
    allCoversDocument: 'all_covers_pension_liberal'
  },
  assistance: {
    title: 'Mon contrat assistance',
    guarantee: 'Mes garanties assistance',
    icon: '/images/illu@3x.png',
    allCoversDocument: 'all_covers_assistance'
  },
  default: {
    title: 'Mon contrat',
    guarantee: 'Mes garanties',
    icon: '/images/illu-adherent.svg',
    allCoversDocument: 'all_covers'
  }
};

export const splitGuarantees = contract => {
  let splitContracts = [];
  const addSubContract = contractType => R.append(
    R.assoc('contractType', contractType, contract),
    splitContracts
  );

  if (hasGuaranteeOfType('health')(contract)) {
    splitContracts = addSubContract(CONTRACT_TYPE_HEALTH);
  }
  if (hasGuaranteeOfType('pension')(contract)) {
    if (hasContractTypologies([typologies.LIBERAL])(contract)) {
      splitContracts = addSubContract(CONTRACT_TYPE_PENSIONL);
    } else if (hasContractTypologies([typologies.PHHU, typologies.INTERNE])(contract)) {
      splitContracts = addSubContract(CONTRACT_TYPE_PENSIONH);
    }
  }
  if (hasGuaranteeOfType('assistance')(contract)) {
    splitContracts = addSubContract(CONTRACT_TYPE_ASSISTANCE);
  }
  return splitContracts;
};

export const getLabel = label => R.compose(
  type => R.pathOr('', [type, label], contractTypeLabels),
  R.propOr('default', 'contractType')
);

export const isUpcomingContract = contract => {
  const startDate = R.prop('startDate', contract);
  const now = moment();
  return RA.isNotNil(startDate) && moment(startDate).isAfter(now);
};

export const getStartDate = R.compose(
  limitedDateToHuman,
  R.propOr('', 'startDate')
);

export const getEndDate = R.compose(
  limitedDateToHuman,
  R.propOr('', 'endDate')
);

const optionEnumToLabel = {
  [contractHealthOption.FAMILLE]: 'familiale',
  [contractHealthOption.ISOLE]: 'individuelle'
};

export const getHealthOptionLabel = R.compose(
  R.when(
    RA.isNotEmpty,
    R.concat('Formule ')
  ),
  R.propOr('', R.__, optionEnumToLabel),
  R.path(['detail', CONTRACT_TYPE_HEALTH, 'option'])
);

export const isBaseRefundDocument = R.propEq('subType', 'base_refund');
export const isExampleRefundDocument = R.propEq('subType', 'refund_example');
export const isAllCoversDocument = contract => {
  const label = getLabel('allCoversDocument')(contract);
  return R.propEq('subType', label);
};
export const isBenefitsGuide = R.propEq('subType', 'benefits_guide');
export const isOnlineDeclarationHelpGuide = R.propEq('subType', 'online_declaration_help_guide');
export const isBenefitsDocument = R.propEq('subType', 'benefits_document');
export const isWorkStoppageDeclarationGuide = R.propEq('subType', 'fiche_decla_sinistre');

export const getGenericDocumentUrl = ({ document, download }) => {
  const fileId = R.propOr('', 'fileId', document);
  const fileName = R.propOr('', 'fileName', document);
  const source = R.propOr('', 'fileSource', document);
  return (
    `/documents/get/${encodeURIComponent(fileId)}?` +
    `source=${encodeURIComponent(source)}` +
    `&fileName=${encodeURIComponent(fileName)}` +
    `&download=${encodeURIComponent(download)}`
  );
};

export const getContractGaConf = R.cond([
  [isContractOfType(CONTRACT_TYPE_HEALTH), R.always(configGA.CONTRACTS_HEALTH)],
  [isContractOfType(CONTRACT_TYPE_PENSIONH), R.always(configGA.CONTRACTS_PENSION_HOSPI)],
  [isContractOfType(CONTRACT_TYPE_ASSISTANCE), R.always(configGA.CONTRACTS_ASSISTANCE)]
]);
export const getContractMaConf = R.cond([
  [isContractOfType(CONTRACT_TYPE_HEALTH), R.always(configMA.CONTRACTS_HEALTH)],
  [isContractOfType(CONTRACT_TYPE_PENSIONH), R.always(configMA.CONTRACTS_PENSION_HOSPI)],
  [isContractOfType(CONTRACT_TYPE_ASSISTANCE), R.always(configMA.CONTRACTS_ASSISTANCE)]
]);

const isActive = contract => {
  const today = moment();
  return R.allPass([
    R.propSatisfies(startDate => moment(startDate).isSameOrBefore(today), 'startDate'),
    R.propSatisfies(endDate => moment(endDate).isSameOrAfter(today), 'endDate')
  ])(contract);
};
export const filterActiveContracts = R.filter(isActive);
