/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCmsPageQueryVariables = {|
  pageId?: ?string
|};
export type QCmsPageQueryResponse = {|
  +cms: ?{|
    +page: ?{|
      +title: ?string,
      +content: ?string,
    |}
  |}
|};
export type QCmsPageQuery = {|
  variables: QCmsPageQueryVariables,
  response: QCmsPageQueryResponse,
|};
*/


/*
query QCmsPageQuery(
  $pageId: ID
) {
  cms {
    page(pageId: $pageId) {
      title
      content
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Cms",
    "kind": "LinkedField",
    "name": "cms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "pageId",
            "variableName": "pageId"
          }
        ],
        "concreteType": "CmsPage",
        "kind": "LinkedField",
        "name": "page",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCmsPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCmsPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d97f7025fd48cb9043d2f101233f4cd8",
    "id": null,
    "metadata": {},
    "name": "QCmsPageQuery",
    "operationKind": "query",
    "text": "query QCmsPageQuery(\n  $pageId: ID\n) {\n  cms {\n    page(pageId: $pageId) {\n      title\n      content\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f44cdf22b287848654b11f84f91e18a';

module.exports = node;
