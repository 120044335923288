/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenderEnum = "Dr" | "F" | "FF" | "M" | "%future added value";
export type MaritalStatusEnum = "celibataire" | "concubin" | "divorce" | "marie" | "pacse" | "veuf" | "%future added value";
export type QAdherentCivilStatusQueryVariables = {|
  id: string
|};
export type QAdherentCivilStatusQueryResponse = {|
  +adherent: ?{|
    +id: ?string,
    +civilStatus: ?{|
      +civility: ?GenderEnum,
      +lastName: ?string,
      +maidenName: ?string,
      +firstName: ?string,
      +maritalStatus: ?MaritalStatusEnum,
      +birthDate: ?string,
      +nic: ?string,
      +nbChild: ?number,
      +managementStartDate: ?string,
    |},
  |}
|};
export type QAdherentCivilStatusQuery = {|
  variables: QAdherentCivilStatusQueryVariables,
  response: QAdherentCivilStatusQueryResponse,
|};
*/


/*
query QAdherentCivilStatusQuery(
  $id: ID!
) {
  adherent(id: $id) {
    id
    civilStatus {
      civility
      lastName
      maidenName
      firstName
      maritalStatus
      birthDate
      nic
      nbChild
      managementStartDate
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Adherent",
    "kind": "LinkedField",
    "name": "adherent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AdherentCivilStatus",
        "kind": "LinkedField",
        "name": "civilStatus",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "civility",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maidenName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maritalStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nbChild",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "managementStartDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QAdherentCivilStatusQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QAdherentCivilStatusQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a8748ce9f5682173317e322dcfce16e1",
    "id": null,
    "metadata": {},
    "name": "QAdherentCivilStatusQuery",
    "operationKind": "query",
    "text": "query QAdherentCivilStatusQuery(\n  $id: ID!\n) {\n  adherent(id: $id) {\n    id\n    civilStatus {\n      civility\n      lastName\n      maidenName\n      firstName\n      maritalStatus\n      birthDate\n      nic\n      nbChild\n      managementStartDate\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f03e17e6bb65891c32cf172949d68a1';

module.exports = node;
